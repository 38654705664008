// Action types
const SPECIAL_DAYS_LISTING_REQUEST = 'specialDays/listing request'
const SPECIAL_DAYS_LISTING_RESPONSE = 'specialDays/listing response'
const SPECIAL_DAYS_NEW_REQUEST = 'specialDays/new request'
const SPECIAL_DAYS_NEW_RESPONSE = 'specialDays/new response'
const SPECIAL_DAYS_REMOVE_REQUEST = 'specialDays/remove request'
const SPECIAL_DAYS_REMOVE_RESPONSE = 'specialDays/remove response'
const SPECIAL_DAYS_EDIT_REQUEST = 'specialDays/edit request'
const SPECIAL_DAYS_EDIT_RESPONSE = 'specialDays/edit response'
const SPECIAL_DAYS_REMOVE_RESOURCE_REQUEST = 'specialDays/remove_resource request'
const SPECIAL_DAYS_REMOVE_RESOURCE_RESPONSE = 'specialDays/remove_resource response'

// Action creators
function specialDaysListingRequest() {
  return { type: SPECIAL_DAYS_LISTING_REQUEST }
}

function specialDaysListingResponse(err, payload) {
  if (err) {
    return {
      type: SPECIAL_DAYS_LISTING_RESPONSE,
      payload: err,
      error: true,
    }
  }

  return {
    type: SPECIAL_DAYS_LISTING_RESPONSE,
    payload,
  }
}

function specialDaysNewRequest() {
  return { type: SPECIAL_DAYS_NEW_REQUEST }
}

function specialDaysNewResponse(err, payload) {
  if (err) {
    return {
      type: SPECIAL_DAYS_NEW_RESPONSE,
      payload: err,
      error: true,
    }
  }

  return {
    type: SPECIAL_DAYS_NEW_RESPONSE,
    payload,
  }
}

function specialDaysRemoveRequest() {
  return { type: SPECIAL_DAYS_REMOVE_REQUEST }
}

function specialDaysRemoveResponse(err, payload) {
  if (err) {
    return {
      type: SPECIAL_DAYS_REMOVE_RESPONSE,
      payload: err,
      error: true,
    }
  }

  return {
    type: SPECIAL_DAYS_REMOVE_RESPONSE,
    payload,
  }
}

function specialDaysRemoveResourceRequest() {
  return { type: SPECIAL_DAYS_REMOVE_RESOURCE_REQUEST }
}

function specialDaysRemoveResourceResponse(err, payload) {
  if (err) {
    return {
      type: SPECIAL_DAYS_REMOVE_RESOURCE_RESPONSE,
      payload: err,
      error: true,
    }
  }

  return {
    type: SPECIAL_DAYS_REMOVE_RESOURCE_RESPONSE,
    payload,
  }
}

const specialDaysEdit = asyncActionCreator(
  SPECIAL_DAYS_EDIT_REQUEST,
  SPECIAL_DAYS_EDIT_RESPONSE
)

function asyncActionCreator(req, res) {
  return {
    request() {
      return { type: req }
    },
    response(err, payload) {
      if (err) return { type: res, payload: err, error: true }
      return { type: res, payload }
    },
  }
}

function arrayEquals(a1, a2) {
  return a1.length === a2.length && a1.findIndex((x, i) => x !== a2[i]) === -1
}

// Thunks
function fetchSpecialDays(date) {
  return function(dispatch, getState, api) {
    dispatch(specialDaysListingRequest())
    api
      .specialDays(date)
      .then(response => {
        if (response.ok) {
          const r = response.data
          const prevSpecialDays = getState().entities.specialDays[date]
          const oldOrder = prevSpecialDays == null ? [] : prevSpecialDays.order
          const order = arrayEquals(oldOrder, r.areas.map(x => x.id))
            ? oldOrder
            : r.areas.map(x => x.id)
          const payload = {
            result: order,
            entities: {
              areas: r.areas.reduce((acc, x) => {
                acc[x.id] = {
                  id: x.id,
                  name: x.name,
                  sectionId: x.section.id,
                }
                return acc
              }, {}),
              sections: r.areas.reduce((acc, x) => {
                if (acc[x.section.id] == null) {
                  acc[x.section.id] = x.section
                } else {
                  acc[x.section.id] = {
                    ...acc[x.section.id],
                    ...x.section,
                  }
                }

                return acc
              }, {}),
              specialDays: {
                order,
                [date]: {
                  order,
                  areas: r.areas.reduce((acc, x) => {
                    acc[x.id] = {
                      id: x.id,
                      sectionId: x.section.id,
                      specialDays: x.specialDays,
                    }
                    return acc
                  }, {}),
                },
              },
            },
          }
          dispatch(specialDaysListingResponse(null, payload))
        } else {
          if (response.data && response.data.message) {
            throw new Error(response.data.message)
          } else {
            throw new Error('Problem fetching special days listing')
          }
        }
      })
      .catch(err => dispatch(specialDaysListingResponse(err)))
  }
}

// Thunks
function fetchBasicSpecialDays(date) {
  return function(dispatch, getState, api) {
    dispatch(specialDaysListingRequest())
    api
      .basicSpecialDays(date)
      .then(response => {
        if (response.ok) {
          const r = response.data
          const prevSpecialDays = getState().entities.specialDays[date]
          const oldOrder = prevSpecialDays == null ? [] : prevSpecialDays.order
          const order = arrayEquals(oldOrder, r.areas.map(x => x.id))
            ? oldOrder
            : r.areas.map(x => x.id)
          const payload = {
            result: order,
            entities: {
              areas: r.areas.reduce((acc, x) => {
                acc[x.id] = {
                  id: x.id,
                  name: x.name,
                  sectionId: x.section.id,
                }
                return acc
              }, {}),
              sections: r.areas.reduce((acc, x) => {
                if (acc[x.section.id] == null) {
                  acc[x.section.id] = x.section
                } else {
                  acc[x.section.id] = {
                    ...acc[x.section.id],
                    ...x.section,
                  }
                }

                return acc
              }, {}),
              specialDays: {
                order,
                [date]: {
                  order,
                  areas: r.areas.reduce((acc, x) => {
                    acc[x.id] = {
                      id: x.id,
                      sectionId: x.section.id,
                      specialDays: x.specialDays,
                    }
                    return acc
                  }, {}),
                },
              },
            },
          }
          dispatch(specialDaysListingResponse(null, payload))
        } else {
          if (response.data && response.data.message) {
            throw new Error(response.data.message)
          } else {
            throw new Error('Problem fetching special days listing')
          }
        }
      })
      .catch(err => dispatch(specialDaysListingResponse(err)))
  }
}

function newSpecialDays(areaId, date, shift, techsRequired, type, overtimes) {
  return function(dispatch, getState, api) {
    dispatch(specialDaysNewRequest())
    return api
      .newSpecialDays(areaId, date, shift, techsRequired, type, overtimes)
      .then(response => {
        if (response.ok) {
          dispatch(
            specialDaysNewResponse(null, {
              entities: {
                specialDays: {
                  [date.slice(0, 7)]: {
                    areas: {
                      [areaId]: {
                        specialDays: [response.data],
                      },
                    },
                  },
                },
              },
            })
          )
        } else {
          if (response.data && response.data.message) {
            throw new Error(response.data.message)
          } else {
            throw new Error('There was an error creating a special day')
          }
        }
      })
      .catch(err => {
        dispatch(specialDaysNewResponse(err))
        return err
      })
  }
}

function removeSpecialDay(shiftId, date, type) {
  return function(dispatch, getState, api) {
    dispatch(specialDaysRemoveRequest())
    return api
      .removeSpecialDays(shiftId, date, type)
      .then(response => {
        if (response.ok) {
          dispatch(specialDaysRemoveResponse(null, response.data))
        } else if (response.data && response.data.message) {
          throw new Error(response.data.message)
        } else {
          throw new Error('There was an error removing the special day')
        }
      })
      .catch(err => {
        dispatch(specialDaysRemoveResponse(err))
        return err
      })
  }
}

function editSpecialDays(shiftId, date, type, techsRequired, overtimes, startTime, endTime) {
  return function(dispatch, getState, api) {
    dispatch(specialDaysEdit.request())
    return api
      .editSpecialDays(shiftId, date, type, techsRequired, overtimes, startTime, endTime)
      .then(response => {
        if (response.ok) {
          dispatch(specialDaysEdit.response(null, response.data))
        } else if (response.data && response.data.message) {
          throw new Error(response.data.message)
        } else {
          throw new Error('There was an error editing the special day')
        }
      })
      .catch(err => {
        dispatch(specialDaysEdit.response(err))
        return err
      })
  }
}

function removeSpecialDayResource(overtimeId) {
  return function(dispatch, getState, api) {
    dispatch(specialDaysRemoveResourceRequest())
    return api
      .removeSpecialDaysResource(overtimeId)
      .then(response => {
        if (response.ok) {
          dispatch(specialDaysRemoveResourceResponse(null, response.data))
        } else if (response.data && response.data.message) {
          throw new Error(response.data.message)
        } else {
          throw new Error('There was an error removing the special days resource')
        }
      })
      .catch(err => {
        dispatch(specialDaysRemoveResourceResponse(err))
        return err
      })
  }
}

export const actions = {
  fetchSpecialDays,
  fetchBasicSpecialDays,
  newSpecialDays,
  removeSpecialDay,
  editSpecialDays,
  removeSpecialDayResource
}
