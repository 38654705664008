import qs from 'query-string'
import { pad } from '../Utils'

const defaultBaseURL =
  process.env.NODE_ENV === 'development'
    ? 'https://app.dev.cadbury.3vilgenius.co/api'
    // ? 'http://localhost:3001/api'
    : '/api'

class API {
  constructor(baseURL) {
    this.baseURL = baseURL
    this.headers = new Headers([
      ['Content-Type', 'application/json'],
      ['Accept', 'application/json'],
    ])
    this.mode = 'cors'
    this.credentials = 'omit'
    this.cache = 'no-store'
    this.post = this.baseMethod('POST')
    this.patch = this.baseMethod('PATCH')
    this.delete = this.baseMethod('DELETE')
  }

  prepare(method, opts) {
    const headers = new Headers(this.headers)
    if (opts && opts.authorization === false) {
      headers.delete('authorization')
    }
    return {
      method: method,
      headers: headers,
      mode: this.mode,
      credentials: this.credentials,
      cache: this.cache,
    }
  }

  get(endpoint, data, opts) {
    const search = qs.stringify(data)
    const url =
      this.baseURL + endpoint + (search.length > 0 ? '?' + search : '')
    return fetch(url, this.prepare('GET', opts)).then(getJSON)
  }

  baseMethod(method) {
    return (endpoint, data, opts) => {
      const url = this.baseURL + endpoint
      const init = this.prepare(method, opts)
      if (data != null) init.body = JSON.stringify(data)
      return fetch(url, init).then(getJSON)
    }
  }
}

const create = (baseURL = defaultBaseURL) => {
  const api = new API(baseURL)

  const setToken = token => {
    return api.headers.set('Authorization', 'Bearer ' + token)
  }

  const unsetToken = () => {
    return api.headers.delete('Authorization')
  }

  const login = (username, password) => {
    return api.post('/auth', { username, password }, { authorization: false })
  }

  const logout = () => {
    return api.get('/auth/logout')
  }

  const refresh = () => {
    return api.get('/auth/refresh')
  }

  const profile = (year, monthIndex) => {
    const month = ('0' + (monthIndex + 1)).slice(-2)
    return api.get(`/profile/sections/${year}/${month}`)
  }

  const resetPassword = email => {
    return api.post('/auth/reset-password', { email })
  }

  const dashboard = date => {
    return api.get(`/dashboard`, { date })
  }

  const roster = (section, year, month) => {
    return api.get(`/sections/${section}/${year}/${pad(month, 2)}`)
  }

  const sections = () => {
    return api.get('/sections')
  }

  const newOffDay = (date, type, coverRequired, shiftId, holidayTypeId, startTime, endTime, overtime) => {
    return api.post('/admin/offDays/new', {
      date,
      type,
      coverRequired,
      shiftId,
      holidayTypeId,
      startTime,
      endTime,
      overtime
    })
  }

  const updateOffDay = (
    id,
    date,
    type,
    coverRequired,
    shiftId,
    holidayTypeId,
    startTime,
    endTime,
    overtime
  ) => {
    return api.patch('/admin/offDays/' + id, {
      date,
      type,
      coverRequired,
      shiftId,
      holidayTypeId,
      startTime,
      endTime,
      overtime
    })
  }

  const updateOvertimeType = (
    id,
    overtimeType,
    cb,
  ) => {
    return api.patch('/admin/overtimes/' + id, {
      overtimeType,
    })
  }

  const specialDays = date => {
    return api.get('/admin/specialDays', { date })
  }

  const basicSpecialDays = date => {
    return api.get('/profile/specialDays', { date })
  }

  const newSpecialDays = (areaId, date, shift, techsRequired, type, overtimes) => {
    return api.post('/admin/specialDays/new', {
      areaId,
      date,
      shift,
      techsRequired,
      type,
      overtimes
    })
  }

  const removeSpecialDays = (shiftId, date, type) => {
    return api.delete('/admin/specialDays', { shiftId, date, type })
  }

  const editSpecialDays = (shiftId, date, type, techsRequired, overtimes, startTime, endTime) => {
    return api.patch('/admin/specialDays', {
      shiftId,
      date,
      type,
      techsRequired,
      overtimes,
      startTime,
      endTime
    })
  }

  const removeSpecialDaysResource = (id) => {
    return api.delete(`/admin/specialDays/overtimes/${id}`)
  }

  const removeCover = (offDayId, reason) => {
    return api.delete(`/admin/overtimes/${offDayId}`, { reason })
  }

  const getBidList = offDayId => {
    return api.get(`/admin/offDays/${offDayId}/bid-list`)
  }

  const getResourceNames = (data) => {
    return api.get('/admin/profiles', data)
  }

  return {
    setToken,
    unsetToken,
    login,
    logout,
    refresh,
    resetPassword,
    dashboard,
    roster,
    profile,
    sections,
    newOffDay,
    updateOffDay,
    updateOvertimeType,
    specialDays,
    basicSpecialDays,
    newSpecialDays,
    removeSpecialDays,
    editSpecialDays,
    removeSpecialDaysResource,
    removeCover,
    getBidList,
    getResourceNames
  }
}

export default {
  create,
}

/**
 * Previously, the API used apisauce. It would return a 'data' key back which
 * our store is making use of. Rather than patch all of those things just add
 * back in the required 'data' key here.
 */
function getJSON(res) {
  return new Promise(function(resolve) {
    res
      .json()
      .then(function(data) {
        res.data = data
        resolve(res)
      })
      .catch(function() {
        // Sometimes responses do not have JSON-like data
        resolve(res)
      })
  })
}
