import * as React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment-timezone'

import { Colors, Metrics, Palette } from '../../Themes'
import {
  abbreviate,
  center,
  getSectionColor,
  numberOfMonth,
  dayOfWeek,
  daysInMonth,
  dayNeedsCoverAttention,
  dayDuration,
  isToday,
} from '../../Utils'
import Icon from '../Icon'
import Section from '../Section'

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => vertical && 'column'};
  margin-bottom: 10px;
  min-width: 280px;
  overflow: auto;
`

const Sidebar = styled.div`
  align-items: center;
  box-sizing: border-box;
  padding-right: 6px;
  display: flex;
  min-height: 24px;
  min-width: ${Metrics.sidebar};
`

const widthStyles = containerWidth => css`
  max-width: none;
  min-width: ${containerWidth}px;
`

const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${Colors.lightGrey};
  border: ${({ days }) => days && 0};
  max-width: 100%;
  min-height: 24px;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  ${({ shadow }) => shadow && 'box-shadow: 0 0 20px rgba(0, 0, 0, 0.05)'};
  ${({ containerWidth }) => containerWidth && widthStyles(containerWidth)};
`

const BorderWrap = styled.div`
  border-bottom: 1px solid ${Colors.lightGrey};
  border: ${({ border }) => !border && 0};
`

const Row = styled.div`
  ${center};
  justify-content: flex-start;
  border-bottom: 1px solid ${Colors.lightGrey};
  box-sizing: border-box;
  height: 40px;
  height: ${({ days }) => days && 'auto'};

  &:last-child {
    border: 0;
  }

  ${({ spacer }) => spacer && 'border-top: 1px;'};
`

const dayHeaderStyles = css`
  color: ${Colors.dark};
  font-size: 12px;
  font-weight: 300;
`

const dayDateStyles = css`
  background: ${Colors.silver};
  color: ${Colors.white};
  font-size: 10px;
`

const daysStyles = css`
  color: ${Colors.silver};
  font-size: 9px;
`

const dayShiftStyle = shift => {
  switch (shift) {
    case 'Day':
      return css`
        background: ${Palette.day};
      `
    case 'Night':
      return css`
        background: ${Palette.night};
      `
    default:
      return ''
  }
}

const dayTypeStyle = props => {
  const { type, shift, needsAttention, dayDuration } = props
  switch (type) {
    case 'Regular': {
      return css`
        ${(shift === 'Day' || shift === 'Night') && 'cursor: pointer;'}
        flex-direction: column;
      `
    }
    case 'Training':
    case 'Union':
    case 'Sick':
    case 'Bereavement':
    case 'Emergency':
    case 'Paternity':
    case 'Maternity':
    case 'Phase Return':
    case 'Medical Appointment':
    case 'Jury Service':
    case 'Parental Leave':
    case 'Projects':
    case 'Community Hours':
    case 'Other':
    case 'Holiday':
      return css`
        background: ${needsAttention ? Palette.error : dayDuration >= 6 ? Palette.success : Palette.shortDay};
        cursor: pointer;
        flex-direction: column;
        justify-content: space-between;
      `
    case 'Overtime':
      return css`
        background: ${Colors.white};
        color: ${Colors.black};
        cursor: pointer;
        flex-direction: column;
        justify-content: space-between;
      `
    default:
      return css`
        flex-direction: column;
      `
  }
}

const dayTodayStyle = css`
  border-top: 2px solid ${Colors.silver};
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
`

const dayEmptyStyle = css`
  background: ${Colors.white};
`

const Day = styled.div`
  ${center};
  height: 100%;
  margin-right: 2px;
  min-width: 38px;
  flex: 1;
  &:last-child {
    margin-right: 0;
  }
  ${({ header }) => header && dayHeaderStyles}
  ${({ date }) => date && dayDateStyles}
  ${({ days }) => days && daysStyles}
  ${({ shift }) => shift && dayShiftStyle(shift)}
  ${props => props.type && dayTypeStyle(props)}
  ${({ today }) => today && dayTodayStyle}
  ${({ empty }) => empty && dayEmptyStyle}
`

const SidebarColumn = styled.div`
  ${center};
  justify-content: flex-start;
  border-right: 1px solid ${Colors.lightGrey};
  box-sizing: border-box;
  height: 100%;
  min-width: ${({ minWidth }) => minWidth}px;
  flex: ${({ flex }) => flex && 1};
  &:last-child {
    border: 0;
  }
  ${({ padding }) => padding && 'padding: 8px;'}
  ${({ noBorder }) => noBorder && 'border: 0;'};
`

const HeaderText = styled.span`
  color: ${Colors.dark};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

const sidebarTextStyles = colour => css`
  border-right: 2px solid transparent;
  border-color: ${colour};
`

const SidebarText = styled.span`
  color: ${Colors.dark};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  min-width: 24px;
  padding: 5px 8px;
  text-align: center;
  ${({ active, colour }) => active && colour && sidebarTextStyles(colour)};
`

const Spacer = styled.div`
  height: 26px;
`

const renderDay = day => {
  if (day == null) return

  switch (day.type) {
    case 'Overtime':
      return (
        <>
          <Icon icon="menu-black" />
          {day.shift === 'Day' ? 'CD' : 'CN'}
          <Spacer />
        </>
      )
    case 'Regular':
      if (day.shift === 'Rest') return 'RD'
      return day.team
    case 'Holiday':
    case 'Training':
    case 'Union':
    case 'Sick':
    case 'Bereavement':
    case 'Emergency':
    case 'Paternity':
    case 'Maternity':
    case 'Phase Return':
    case 'Medical Appointment':
    case 'Jury Service':
    case 'Parental Leave':
    case 'Community Hours':
    case 'Other':
      return (
        <>
          <Icon icon="menu" />
          {abbreviate(day.type)}
          <Spacer />
        </>
      )
    case 'Projects':
      return (
        <>
          <Icon icon="menu" />
            PJ
          <Spacer />
        </>
      )
    default:
      return day.type
  }
}

function RosterTable(props) {
  const { areas, month, year, section, dateString, modalShow } = props
  return (
    <SectionWrapper>
      <SectionWrapper vertical>
        <Sidebar />
        <Sidebar>
          <Container fullWidth shadow>
            {areas &&
              areas.map((area, i) => (
                <BorderWrap key={area.id} border={areas.length !== i + 1}>
                  <Row padding>
                    <SidebarColumn padding flex noBorder>
                      <HeaderText>{area.name}</HeaderText>
                    </SidebarColumn>
                    <SidebarColumn padding>
                      <Section
                        colour={getSectionColor(section)}
                        outline
                        title={abbreviate(area.name)}
                      />
                    </SidebarColumn>
                    <SidebarColumn minWidth={42} />
                  </Row>
                  {area.profiles.map((profile, i) => (
                    <Row key={i} padding>
                      <SidebarColumn minWidth={136} flex>
                        <SidebarText>{profile.name}</SidebarText>
                      </SidebarColumn>
                      <SidebarColumn minWidth={42}>
                        <SidebarText
                          colour={getSectionColor(profile.section)}
                          active
                        >
                          {profile.speciality}
                        </SidebarText>
                      </SidebarColumn>
                    </Row>
                  ))}
                </BorderWrap>
              ))}
          </Container>
        </Sidebar>
      </SectionWrapper>
      <SectionWrapper vertical style={{ flexGrow: 1, paddingRight: 2 }}>
        <Container days fullWidth>
          <Row days>
            {[...Array(daysInMonth(year, month)).keys()].map(number => (
              <Day days key={number}>
                {dayOfWeek(
                  moment(dateString).format('YYYY'),
                  moment(dateString).format('MM'),
                  number + 1
                )}
              </Day>
            ))}
          </Row>
        </Container>
        <Container
          fullWidth
          shadow
          containerWidth={daysInMonth(year, month) * 40}
        >
          <Row>
            {[...Array(daysInMonth(year, month)).keys()].map(number => (
              <Day header today={isToday(year, month, number + 1)} key={number}>
                {numberOfMonth(
                  moment(dateString).format('YYYY'),
                  moment(dateString).format('MM'),
                  number + 1,
                  2
                )}
              </Day>
            ))}
          </Row>
          {areas &&
            areas.map((area, i) => (
              <BorderWrap key={area.id} border={areas.length !== i + 1}>
                {area.profiles.map((profile, i) => (
                  <Row key={i}>
                    {Object.keys(profile.days).map((day, i) => {
                      const profileDay = profile.days[day]
                      if (profileDay) {
                        return (
                          <Day
                            key={i}
                            date
                            shift={profileDay.shift}
                            type={profileDay.type}
                            needsAttention={dayNeedsCoverAttention(profileDay)}
                            dayDuration={dayDuration(profileDay)}
                            onClick={openModalIfType(modalShow, {
                              profileName: profile.name,
                              profileKey: `${profile.id}-${area.id}`,
                              date: day,
                              day: profileDay,
                            })}
                          >
                            {renderDay(profileDay)}
                          </Day>
                        )
                      }

                      return (
                        <Day key={i} date empty={true}>
                          {renderDay(profileDay)}
                        </Day>
                      )
                    })}
                  </Row>
                ))}
                {areas && areas.length !== i + 1 && <Row />}
              </BorderWrap>
            ))}
        </Container>
      </SectionWrapper>
    </SectionWrapper>
  )
}

function openModalIfType(modalShow, data) {
  const { type, shift } = data.day
  if (type === 'Regular' && shift === 'Rest') return
  return () => modalShow(data)
}

export default RosterTable
