import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import qs from 'query-string'

import { RosterActions, RosterSelectors } from '../Redux/RosterRedux'
import { actions as AppActions } from '../Redux/app'
import EditDayWizard from '../Components/EditDayWizard'
import { StyledReactModal as StyledModal } from '../Components/Modal'
import Roster from '../Components/Roster'
import { pad } from '../Utils'
import { dateString } from '../Utils/time'
import Helmet from 'react-helmet'

class RosterContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editOffDayId: null,
      day: null,
    }

    this.previousMonth = this.changeMonth(-1)
    this.nextMonth = this.changeMonth(1)
    this.setProfileDay = this.setProfileDay.bind(this)
    this.closeModal = this.closeModal.bind(this)

    this.props.fetchSections()
  }

  componentDidMount() {
    const { sectionID } = this.props
    this.props.getRoster(sectionID, this.props.year, this.props.month)
  }

  componentDidUpdate(prevProps) {
    const { sectionID } = this.props
    if (prevProps.sectionID !== sectionID) {
      this.props.getRoster(sectionID, this.props.year, this.props.month)
    }
  }

  changeMonth(monthDiff) {
    return () => {
      const {
        getRoster,
        month: prevMonth,
        year: prevYear,
        sectionID,
      } = this.props
      const nextSelectedMonth = parseInt(prevMonth, 10) - 1 + monthDiff
      const date = new Date(prevYear, nextSelectedMonth, 1)
      const nextYear = date.getFullYear() + ''
      const nextMonth = pad(date.getMonth() + 1 + '', 2)
      getRoster(sectionID, nextYear, nextMonth)
      this.props.history.push({
        pathname: '/section/' + sectionID,
        search: '?date=' + nextYear + '-' + nextMonth,
      })
    }
  }

  setProfileDay(data) {
    const { profileName, profileKey, date } = data
    this.setState({ isOpen: true, profileName, profileKey, date })
  }

  closeModal() {
    this.setState({ isOpen: false })
  }

  render() {
    const { sectionID, year, month, areas, loading, sections } = this.props
    const { isOpen, profileName, profileKey, date } = this.state
    const nextMonth = this.nextMonth
    const previousMonth = this.previousMonth
    const dateString = moment(`${year}-${pad(month, 2)}-01`).format(
      'YYYY-MM-DD'
    )

    return (
      <>
        <Helmet title="Roster" />
        <Roster
          {...{
            dateString,
            loading,
            month,
            nextMonth,
            previousMonth,
            areas,
            year,
            section: sectionID,
            sections,
            modalShow: this.setProfileDay,
          }}
        />
        <StyledModal isOpen={isOpen} onRequestClose={this.closeModal}>
          <EditDayWizard
            profileName={profileName}
            profileKey={profileKey}
            date={date}
            dismiss={this.closeModal}
            sections={sections}
            getRoster={() => this.props.getRoster(sectionID, this.props.year, this.props.month)}
          />
        </StyledModal>
      </>
    )
  }
}

RosterContainer.propTypes = {
  getRoster: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  month: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.object),
  year: PropTypes.string,
  sectionID: PropTypes.string,
}

function getDateKey(search) {
  const query = qs.parse(search.slice(1))
  let date
  if (query.date) {
    const [year, month] = query.date.split('-')
    date = new Date(parseInt(year, 10), parseInt(month, 10) - 1)
  } else {
    date = new Date()
  }

  return dateString(date).slice(0, 7)
}

const denormalizeToOldStyle = (state, props) => {
  const dateKey = getDateKey(props.location.search)
  const { sectionID } = props.match.params
  if (sectionID == null) return []
  const {
    entities: { sections, areas, profiles },
  } = state
  const section = sections[sectionID]
  if (section == null || section.areas == null) return []
  return section.areas.map(id => {
    const area = areas[id]
    if (area == null) return null
    const p = area.profiles.map(pID => profiles[pID])
    return {
      ...area,
      profiles: p.map(profile => {
        return {
          ...profile,
          days: Object.keys(profile.days).reduce((acc, key) => {
            if (key.slice(0, 7) === dateKey) {
              acc[key] = profile.days[key]
            }
            return acc
          }, {}),
        }
      }),
    }
  })
}

export default connect(
  function mapStateToProps(state, ownProps) {
    const sections = state.app.navigation.sections
    const dateKey = getDateKey(ownProps.location.search)
    return {
      loading: RosterSelectors.fetching(state),
      failed: RosterSelectors.error(state) !== null,
      month: dateKey.slice(5, 7),
      areas: denormalizeToOldStyle(state, ownProps),
      year: dateKey.slice(0, 4),
      sectionID: ownProps.match.params.sectionID,
      sections: Object.keys(sections)
        .sort()
        .map(id => sections[id]),
    }
  },
  { 
    getRoster: RosterActions.rosterRequest,
    fetchSections: AppActions.fetchNavigation,
  }
)(RosterContainer)
