import { call, delay, put } from 'redux-saga/effects'
import { get } from 'lodash'
import { AuthActions } from '../Redux/AuthRedux'

/**
 * Login saga.
 *
 * @export
 * @param {any} api
 * @param {any} action
 */
export function* login(api, action) {
  try {
    const { username, password } = action
    const response = yield call([api, api.login], username, password)
    const accessToken = get(response, 'data.accessToken')
    const expiresIn = get(response, 'data.expiresIn')
    const area = get(response, 'data.area')
    const holidayTypes = get(response, 'data.holidayTypes')
    const id = get(response, 'data.id')
    const name = get(response, 'data.name')
    const speciality = get(response, 'data.speciality')
    const team = get(response, 'data.team')
    const section = get(response, 'data.section')
    const shiftIds = get(response, 'data.shiftIds')
    const data = get(response, 'data')
    let role = null;
    if (data.isAdmin) {
      role = 'admin'
    } else if (data.isProjectManager) {
      role = 'projectManager'
    } else if (data.isEngineeringManager) {
      role = 'engineerManager'
    } else {
      role = 'basic'
    }

    const user = {
      area,
      holidayTypes,
      id,
      name,
      role,
      speciality,
      team,
      section,
      shiftIds
    }
    yield put(AuthActions.clearData())

    if (response.ok) {
      localStorage.setItem('accessToken', accessToken)
      yield call([api, api.setToken], accessToken)
      yield put(AuthActions.loginSuccess(user, accessToken, expiresIn))
    } else {
      throw new Error('Incorrect username or password')
    }
  } catch (error) {
    yield put(AuthActions.loginFailure(error))
  }
}

/**
 * Logout saga.
 *
 * @export
 * @param {any} api
 * @param {any} action
 */
export function* logout(api, action) {
  try {
    localStorage.removeItem('accessToken')
    yield put(AuthActions.clearData())
    yield put(AuthActions.logoutSuccess())
  } catch (error) {
    yield put(AuthActions.logoutFailure(error))
  }
}

/**
 * Refresh saga.
 *
 * @export
 * @param {any} api
 * @param {any} action
 */
export function* refresh(api, action) {
  try {
    const { accessToken } = action
    yield call([api, api.setToken], accessToken)
    const response = yield call([api, api.refresh], accessToken)
    const refreshedToken = get(response, 'data.accessToken')
    const expiresIn = get(response, 'data.expiresIn')
    const area = get(response, 'data.area')
    const holidayTypes = get(response, 'data.holidayTypes')
    const id = get(response, 'data.id')
    const name = get(response, 'data.name')
    const speciality = get(response, 'data.speciality')
    const team = get(response, 'data.team')
    const section = get(response, 'data.section')
    const shiftIds = get(response, 'data.shiftIds')
    const data = get(response, 'data')
    let role = null;
    if (data.isAdmin) {
      role = 'admin'
    } else if (data.isProjectManager) {
      role = 'projectManager'
    } else if (data.isEngineeringManager) {
      role = 'engineerManager'
    } else {
      role = 'basic'
    }

    const user = {
      area,
      holidayTypes,
      id,
      name,
      role,
      speciality,
      team,
      section,
      shiftIds
    }

    if (response.ok) {
      localStorage.setItem('accessToken', refreshedToken)
      yield call([api, api.setToken], refreshedToken)
      yield put(AuthActions.refreshSuccess(user, refreshedToken, expiresIn))
    } else {
      throw new Error('Failed to refresh access token')
    }
  } catch (error) {
    yield put(AuthActions.refreshFailure(error))
  }
}

/**
 * Reset password saga.
 *
 * @export
 * @param {any} api
 * @param {any} action
 */
export function* resetPassword(api, action) {
  try {
    const { username } = action
    const response = yield call([api, api.resetPassword], username)

    if (response.ok) {
      yield put(AuthActions.resetPasswordSuccess(response))
      yield call(delay, 4000)
    } else {
      throw new Error('Failed to reset password')
    }
  } catch (error) {
    yield put(AuthActions.resetPasswordFailure(error))
  }
}
