import React from 'react'
import PropTypes from 'prop-types'

import AlertCircle from '../AlertCircle'
import Section from '../Section'
import { abbreviate, getSectionColor } from '../../Utils'
import { StyledReactModal, Content, Row, Title, SectionWrap } from '../Modal'
import SimpleTable, { Value } from '../SimpleTable'

const RosterModal = ({ data, tableRows, onClose, ...props }) => (
  <StyledReactModal onRequestClose={onClose} ariaHideApp={false} {...props}>
    {data && (
      <Content>
        <Row right>
          <AlertCircle
            red
            hidden={data.type === 'Regular' || data.overtimeType || data.cover}
          >
            !
          </AlertCircle>
        </Row>
        <Row>
          <Title>{data.type}</Title>
        </Row>
        <SectionWrap>
          <Section
            medium
            colour={getSectionColor(data.section)}
            title={`S${data.section}`}
          />
          <Section
            medium
            outline
            colour={getSectionColor(data.section)}
            title={abbreviate(data.area)}
          />
          <Section
            medium
            outline
            colour={getSectionColor(data.section)}
            title={data.team}
          />
        </SectionWrap>
        <SimpleTable rows={tableRows} valueDecorator={valueDecorator} />
      </Content>
    )}
  </StyledReactModal>
)

RosterModal.propTypes = {
  data: PropTypes.any,
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      heading: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ),
  onClose: PropTypes.func.isRequired,
}

function valueDecorator(row) {
  if (row.heading === 'Cover' && row.value === 'No cover') {
    return <Value red>NO COVER</Value>
  }
  return <Value>{row.value}</Value>
}

export default RosterModal
