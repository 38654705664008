import React, { Component } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { DashboardActions, DashboardSelectors } from '../Redux/DashboardRedux'
import { ModalActions } from '../Redux/ModalRedux'
import Dashboard from '../Components/Dashboard'

class DashboardContainer extends Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
    failed: PropTypes.bool,
    getDashboard: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  }

  componentDidMount() {
    const { getDashboard } = this.props
    const date = moment().format('YYYY-MM-DD')
    getDashboard(date)
  }

  nextWeek = () => {
    const { getDashboard, date } = this.props
    const nextWeek = moment(date)
      .add(1, 'week')
      .format('YYYY-MM-DD')
    getDashboard(nextWeek)
  }

  previousWeek = () => {
    const { getDashboard, date } = this.props
    const previousWeek = moment(date)
      .subtract(1, 'week')
      .format('YYYY-MM-DD')
    getDashboard(previousWeek)
  }

  render() {
    const { data, date, failed, loading, modalShow, getDashboard } = this.props
  
    return (
      <>
        <Helmet title="Dashboard" />
        <Dashboard
          {...{
            data,
            date,
            failed,
            loading,
            modalShow,
            getDashboard,
            nextWeek: this.nextWeek,
            previousWeek: this.previousWeek,
          }}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  date: DashboardSelectors.date(state),
  data: DashboardSelectors.data(state),
  failed: DashboardSelectors.error(state) !== null,
  loading: DashboardSelectors.fetching(state),
})

const mapDispatchToProps = dispatch => ({
  getDashboard: date => dispatch(DashboardActions.dashboardRequest(date)),
  modalShow: data => dispatch(ModalActions.modalShow(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer)
