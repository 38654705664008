import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Caret } from '../Icons'
import { Colors, Palette } from '../../Themes'

const fontSize = 14
// Calculate the gap relative to fontSize in ems so it scales
const gap = 10 / fontSize
const elementHeight = 44 / fontSize
const iconSize = 24

const Wrapper = styled.div`
  font-size: ${fontSize}px;
  position: relative;
  background-color: ${Palette.inputBackground};
  border: 1px solid ${Palette.inputBorder};
  border-radius: 2px;
`

const RawSelect = styled.select`
  border: none;
  background-color: transparent;
  appearance: none;
  font-size: ${fontSize}px;
  height: ${elementHeight}em;
  line-height: ${elementHeight};
  width: 100%;
  padding-left: ${gap}em;
  padding-right: ${gap + iconSize / fontSize}em;
`

const DropDownIcon = styled(Caret)`
  pointer-events: none;
  position: absolute;
  right: ${gap}em;
  top: 50%;
  margin-top: ${iconSize / -2}px;
  height: ${iconSize}px;
  width: ${iconSize}px;

  & path {
    fill: ${Colors.grey};
  }
`

function Select(props) {
  const { children, defaultValue, onChange, id, value, disabled, ...restProps } = props
  return (
    <Wrapper {...restProps}>
      <RawSelect {...{ defaultValue, onChange, id, value, disabled }}>
        {children}
      </RawSelect>
      <DropDownIcon />
    </Wrapper>
  )
}

Select.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  value: PropTypes.string,
}

export default Select
