import React from 'react'

function List(passedProps) {
  const props = {
    viewBox: '0 0 24 24',
    xmlns: 'http://www.w3.org/2000/svg',
    ...passedProps,
  }
  return (
    <svg {...props}>
      <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm4-10v4h6V4h-6zm-4 4h4V4h-4v4zm4 6h6v-4h-6v4zm0 6h6v-4h-6v4z" />
    </svg>
  )
}

export default List
