import React from 'react'
import styled, { css } from 'styled-components'
import TableBase from '../Table'
import Day from '../MonthTableDay'
import { Day as DayHeader } from '../MonthTable'
import { Palette } from '../../Themes'
import { abbreviate, getSectionColor } from '../../Utils'

const Table = styled(TableBase)`
  width: 100px;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 6px;
  transition: box-shadow 300ms ease-in-out;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  border-radius: 4px;

  ${props =>
    props.shadow
      ? css`
          box-shadow: 23px 19px 2px -20px rgba(0, 0, 0, 0.1);
        `
      : css`
          box-shadow: 19px 19px 2px -20px rgba(0, 0, 0, 0);
        `}
`

const Cell = styled(Day)`
  position: relative;
  text-align: left;
`

const Box = styled.div`
  vertical-align: middle;
  display: inline-block;
  height: 2.4em;
  line-height: 2.4em;
  text-align: center;
  width: 2.4em;
  margin-left: 3px;

  ${props =>
    props.invert
      ? css`
          border: 1px solid ${props => props.sectionColor};
          background-color: ${props => props.sectionColor};
          color: ${Palette.textWithSectionColor};
        `
      : css`
          border: 1px solid ${props => props.sectionColor};
          color: ${props.sectionColor};
        `}
`

Box.defaultProps = {
  sectionColor: Palette.noSectionColor,
}

const AccentColor = styled.div`
  width: 2px;
  background-color: ${props => props.sectionColor};
  position: absolute;
  top: 3px;
  right: 0;
  bottom: 3px;
`

AccentColor.defaultProps = {
  sectionColor: Palette.noSectionColor,
}

function AreaHeadings(props) {
  const { areas } = props
  return (
    <Table
      {...{
        shadow: props.shadow,
        head: (
          <>
            <tr>
              <td style={{ height: 38, padding: 0 }}>&nbsp;</td>
            </tr>
            <tr>
              <DayHeader>&nbsp;</DayHeader>
            </tr>
          </>
        ),
        body: (
          <>
            {areas.map(x => (
              <tr key={x.id}>
                <Cell>{areaName(x)}</Cell>
              </tr>
            ))}
          </>
        ),
      }}
    />
  )
}

export default AreaHeadings

function areaName(area) {
  const sectionColor = getSectionColor(area.section.name)
  return (
    <>
      <Box sectionColor={sectionColor} invert>
        {'S' + area.section.name}
      </Box>
      <Box sectionColor={sectionColor}>{abbreviate(area.name || '')}</Box>
      <AccentColor sectionColor={sectionColor} />
    </>
  )
}
