import styled from 'styled-components'
import { Colors } from '../../../Themes'
import { clearFix } from '../../../Utils'
import { headerHeight } from './measures'

const Root = styled.header`
  background-color: ${Colors.purple};
  color: ${Colors.white};
  padding: 0 40px;
  min-width: 1024px;

  & > * {
    line-height: ${headerHeight}px;
  }

  ${clearFix}
`

export default Root
