import * as React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'

import PaginateControl from '../PaginateControl'
import RosterTable from '../RosterTable'
import Section from '../Section'
import { Colors, Metrics } from '../../Themes'
import { getSectionColor } from '../../Utils'
import { getUTCYearMonth } from '../../Utils/time'

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 2rem auto;
  padding: 0px 40px;
  width: 100%;
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => vertical && 'column'};
  margin-bottom: 10px;
  min-width: 280px;
  overflow: auto;
`

const Sidebar = styled.div`
  align-items: center;
  box-sizing: border-box;
  padding-right: 6px;
  display: flex;
  min-height: 24px;
  min-width: ${Metrics.sidebar};
`

const RosterText = styled.span`
  color: ${Colors.dark};
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
`

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: ${Colors.darkGrey};
`

function BasicDashboard(props) {
  const { previousMonth, nextMonth, date, sections, modalShowHandler } = props
  const { year, month } = getUTCYearMonth(date)

  if (sections && sections.length > 0) {
    return (
      <Wrapper>
        {sections.map((section, i) => (
          <React.Fragment key={section.id}>
            <SectionWrapper>
              <Sidebar>
                {/* TODO: section colour and name is hard-coded */}
                <Section colour={getSectionColor('1')} large title={'S?'} />
                <RosterText>{section.name || ''}</RosterText>
              </Sidebar>
              {i === 0 && (
                <PaginateControl
                  minWidth={200}
                  current={moment(date).format('MMMM YYYY')}
                  onPrevious={previousMonth}
                  onNext={nextMonth}
                />
              )}
            </SectionWrapper>
            <RosterTable
              areas={section.areas}
              dateString={date}
              year={year}
              month={month}
              modalShow={modalShowHandler}
            />
          </React.Fragment>
        ))}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <SectionWrapper>
        <Sidebar>
          <Section colour={getSectionColor()} large />
        </Sidebar>
        <PaginateControl
          minWidth={200}
          current={moment(date).format('MMMM YYYY')}
          onPrevious={previousMonth}
          onNext={nextMonth}
        />
      </SectionWrapper>
      <NoData>No data for this time period</NoData>
    </Wrapper>
  )
}

export default BasicDashboard
