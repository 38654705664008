// import { pad } from '.'

// Convenience function for something that is quite common in our code
export function getUTCYearMonthIndex(date) {
  return { year: date.getUTCFullYear(), monthIndex: date.getUTCMonth() }
}

export function getUTCYearMonth(date) {
  return { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1 }
}

// Like the regular Date constructor but make sure we only use UTC
// E.g. const myDate = utcDate(2018, 12, 1)
export function utcDate() {
  return new Date(Date.UTC.apply(null, arguments))
}

export function daysInMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

export function dateString(date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return (
    year +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (day < 10 ? '0' + day : day)
  )
}

const daysOfWeekSingleLetter = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const daysOfWeekFull = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

// const monthsFull = [
//   'January', 'February', 'March', 'April', 'May', 'June',
//   'July', 'August', 'September', 'October', 'November', 'December'
// ]

// const monthsAbbreviated = [
//   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
//   'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
// ]

export function dayOfWeek(baseDate, day, abbreviated) {
  const date = new Date(baseDate.getFullYear(), baseDate.getMonth(), day)
  return (abbreviated ? daysOfWeekSingleLetter : daysOfWeekFull)[date.getDay()]
}

export function isToday(date) {
  const today = new Date()
  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  )
}
