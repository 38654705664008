import React from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'

import { Colors } from '../../Themes'
import { center } from '../../Utils'
import './style.css'

/* move these components */
export const Content = styled.div`
  padding: 20px 20px 40px;
  text-align: center;
`

export const Row = styled.div`
  display: ${({ right }) => right && 'flex'};
  justify-content: ${({ right }) => right && 'flex-end'};
  margin: 0 40px;
  margin: ${({ right }) => right && '0'};
  padding: ${({ padding }) => padding && '10px'} 0;
`

export const Title = styled.div`
  color: ${Colors.darkGrey};
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: uppercase;
`

export const SectionWrap = styled.div`
  ${center};
  padding: 10px 0;
`
/* /move these components */

export const StyledReactModal = props => (
  <ReactModal
    appElement={document.getElementById('root')}
    className="cc-modal"
    bodyOpenClassName="cc-modal__body-open"
    overlayClassName="cc-modal__overlay"
    closeTimeoutMS={250}
    {...props}
  />
)

export const StyledReactSpecialModal = props => (
  <ReactModal
    appElement={document.getElementById('root')}
    className="cc-modal-special"
    bodyOpenClassName="cc-modal__body-open"
    overlayClassName="cc-modal__overlay"
    closeTimeoutMS={250}
    {...props}
  />
)

export { default as EditHeader } from './EditHeader'
export { default as RemoveHeader } from './RemoveHeader'
export { default as OverviewHeader } from './OverviewHeader'
