import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Face } from '../Icons'

const Root = styled.span`
  display: inline-block;
  font-size: 12px;
`

const UsernameIcon = styled(Face)`
  margin-right: 0.5em;
  width: 24px;
  height: 24px;
  fill: white;
  vertical-align: middle;
`

const NameRole = styled.span`
  line-height: 24px;
  vertical-align: middle;
`

const Role = styled.span`
  text-transform: capitalize;

  &::before {
    content: '|';
    display: inline-block;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
`

function Username(props) {
  return (
    <Root className={props.className}>
      <UsernameIcon />
      <NameRole>
        {props.name}
        {props.userRole === 'admin' && <Role>Admin</Role>}
        {props.userRole === 'projectManager' && <Role>Project Manager</Role>}
        {props.userRole === 'engineerManager' && <Role>Engineer Manager</Role>}
      </NameRole>
    </Root>
  )
}

Username.propTypes = {
  name: PropTypes.string.isRequired,
  userRole: PropTypes.string,
}

export default Username
