import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment-timezone'
import Overview from '../Components/EditDayWizard/Overview'
import {
  Button,
  LabelGroup,
  LabelName,
  Select,
  Text,
} from '../Components/Forms'
import Spinner from '../Components/Spinner'
import { Colors, Palette } from '../Themes'
import { clearFix } from '../Utils'
import { actions as SpecialDaysActions } from '../Redux/specialDays'
import { actions as EditDayActions } from '../Redux/editDay'
import PaginateControl from '../Components/PaginateControl'
import { Edit } from '../Components/SpecialDaysModal'
import { RemoveHeader } from '../Components/Modal'
import Icon from '../Components/Icon'

const NewDayWrapper = styled.div`
  margin: 0 auto;
  width: 330px;
  margin-top: -2rem;
  margin-bottom: 2rem;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
`

const BackgroundWrapper = styled.div`
  background: #fff;
  transform: translate(0,0)!important;
`

const OverviewWrapper = styled.div`
  float:left;
  margin-left:20px;
  background: #fff;
  width:330px;
`
const RemoveModalWrapper = styled.div`
  float:left;
  margin:20px;
  background: #fff;
  width:auto;
`

const firstOverviewStyle = {
  marginLeft: '0',
}

const oneOverviewStyle = {
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)'
}

const TopWrapper = styled.div`
  top:-80%;
  position:relative;
`

const NextIcon = styled.div`
  background: ${Colors.purple};
  box-sizing: content-box;
  color: ${Colors.white};
  cursor: pointer;
  padding: 12px;
  width:28px;
  height:22px;
  margin-left:10px;
  position: absolute;
  right: -72px;

  &::before {
    content: '>';
    position:absolute;
    font-size:34px;
    top:2px;
    right:16px;
  }
`

const PreviousIcon = styled.div`
  background: ${Colors.purple};
  box-sizing: content-box;
  color: ${Colors.white};
  cursor: pointer;
  padding: 12px;
  width:28px;
  height:22px;
  margin-right:10px;
  position:absolute;
  left: -72px;

  &::before {
    content: '<';
    position:absolute;
    font-size:34px;
    top:2px;
    left:15px;
  }
`

const Row = styled.div`
  display: flex;
`

const Header = styled.header`
  border-bottom: 1px solid ${Colors.lightGrey};
  height: 70px;
  line-height: 70px;

  ${clearFix}
`

const ModalTitle = styled.h2`
  font-size: 20px;
  font-weight: normal;
  margin: 0 2rem;
  float: left;
  width: 210px;
`

const Form = styled.form`
  font-size: 0.9em;
  min-height: 280px;
`

const SubmitButtonWrapper = styled.div`
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const SubmitButton = styled(Button)`
  position: relative;
`

const SpinnerWrapper = styled.div`
  height: 1.2em;
`

const ErrorMessage = styled.p`
  margin-top: 2rem;
  margin-right: 2rem;
  margin-left: 2rem;
  max-width: 210px;
  color: ${Palette.error};
`

const StyledPaginateControl = styled(PaginateControl)`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: -2rem;
`

const RemoveForm = styled.form`
  font-size: 0.9em;
  padding-bottom: 2rem;
`

const CREATE = 'create'
const EDIT = 'edit'
const OVERVIEW = 'overview'
const REMOVE = 'remove'
const REMOVE_COVER = 'remove_cover'


class SpecialDaysModalContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dayCursor: 0,
      screen: props.days.length > 0 ? OVERVIEW : CREATE,
      shift: 'Day',
      techsRequired: '1',
      type: 'Maintenance',
      selectedDay: null,
      daysList: [],
      nextFlag: true,
      overtimeId: null,
      resourceNames: [],
      resourceNameList: [],
      overtimeTypeList: []
    }
    this.nextDay = this.changeDay(1)
    this.prevDay = this.changeDay(-1)

    this.handleRemoveModal = this.handleRemoveModal.bind(this)
    this.updateResourceName = this.updateResourceName.bind(this)
    this.updateOvertimeType = this.updateOvertimeType.bind(this)
  }

  changeDay(by) {
    return () => {
      const { days } = this.props
      const { dayCursor } = this.state
      let next = dayCursor + by
      next = next >= days.length ? 0 : next < 0 ? days.length - 1 : next
      this.setState({ dayCursor: next })
    }
  }

  updateState(key, value = 'value') {
    return ev => this.setState({ [key]: ev.target[value] })
  }

  handleSave() {
    const { areaId, date, onSave } = this.props
    const { shift, techsRequired, type, resourceNameList, overtimeTypeList } = this.state
    return ev => {
      this.setState({ error: null, loading: true })

      const overtimes = resourceNameList.map((res, index) => {
        return {
          shiftId: parseInt(res.shiftId),
          overtimeType: overtimeTypeList[index]
        }
      })

      onSave(areaId, date, shift, techsRequired, type, overtimes).then(error => {
        const nextState = { error, loading: false }
        if (error == null) {
          // TODO: works around properly merging state without an id
          this.props.fetchSpecialDays(date.slice(0, 7))
          nextState.screen = OVERVIEW
        }
        this.setState(nextState)
      })
      ev.preventDefault()
    }
  }

  handleRemoveModal(overtimeId) {
    this.setState({ screen: REMOVE, overtimeId });
  }

  gotoScreen(screen, selectedDay) {
    return () => this.setState({ screen, selectedDay })
  } 

  componentWillMount() {
    const { days, getResourceNames } = this.props;
    let tmpDays = [...days];
    if (days.length > 3) tmpDays[3].disabled = true;
    this.setState({daysList: days})
    getResourceNames({date: this.props.date}, this.setNewResourceNames.bind(this));
  }

  setNewResourceNames(resourceNames) {
    this.setState({ resourceNames })
  }

  updateResourceName(index, name, shiftId) {
    let resourceNameList = this.state.resourceNameList;
    resourceNameList[index] = {shiftId: parseInt(shiftId), name};
    let overtimeTypeList = this.state.overtimeTypeList;
    overtimeTypeList[index] = 'COMMS';
    this.setState({ resourceNameList, overtimeTypeList })
  }

  updateOvertimeType(index, type) {
    let overtimeTypeList = this.state.overtimeTypeList;
    overtimeTypeList[index] = type;
    this.setState({ overtimeTypeList })
  }

  componentWillReceiveProps(nextProps) {
    const { days } = this.props;

    if (nextProps.days !== days) {
      this.setState({ daysList: nextProps.days})
    }
  }

  render() {
    const { date, handleEdit, user } = this.props
    const { dayCursor, error, screen, shift, techsRequired, type, selectedDay, daysList, nextFlag, overtimeType } = this.state
    const resNameListFiltered = this.state.resourceNames.filter( res => !this.state.resourceNameList.filter( list => list.shiftId === res.shiftId).length)

    switch (screen) {
      case CREATE:
        return (
          <BackgroundWrapper>
            <Header>
              <ModalTitle>Create a new day</ModalTitle>
            </Header>
            <Form onSubmit={this.handleSave()}>
              <Row>
                <LabelGroup>
                  <LabelName>Day</LabelName>
                  <Text
                    disabled
                    defaultValue={moment(date).format('DD MMM YYYY')}
                  />
                </LabelGroup>
                <LabelGroup>
                  <LabelName>Shift</LabelName>
                  <Select value={shift} onChange={this.updateState('shift')}>
                    <option>Day</option>
                    <option>Night</option>
                  </Select>
                </LabelGroup>
              </Row>
              <Row>
                <LabelGroup>
                  <LabelName>Resources needed</LabelName>
                  <Select
                    value={techsRequired}
                    onChange={this.updateState('techsRequired')}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </Select>
                </LabelGroup>
                <LabelGroup>
                  <LabelName>Day type</LabelName>
                  <Select value={type} onChange={this.updateState('type')}>
                    <option>Maintenance</option>
                    <option>Special Cleaning</option>
                    <option>Changeover</option>
                  </Select>
                </LabelGroup>
              </Row>
              {[...Array(parseInt(this.state.techsRequired))].map((tech, index) => (
                <Row>
                  <LabelGroup>
                    <LabelName>Resource {index+1} name (optional)</LabelName>
                    <Select
                      onChange={(e) => {
                        const selectedIndex = e.target.options.selectedIndex
                        this.updateResourceName(index, e.target.value, e.target.options[selectedIndex].getAttribute('data-shiftId'))
                      }}
                      value={this.state.resourceNameList && this.state.resourceNameList[index] && this.state.resourceNameList[index].name}
                    >
                      
                      {[{id: 0, name: this.state.resourceNameList && this.state.resourceNameList[index] ? this.state.resourceNameList[index].name : 'Select cover'}, ...resNameListFiltered].map(type => (
                        <option key={type.id} data-shiftId={type.shiftId}>{type.name}</option>
                      ))}
                  </Select>
                  </LabelGroup>
                  <LabelGroup>
                    <LabelName>Resource {index+1} overtime type</LabelName>
                    <Select
                      onChange={(e) => this.updateOvertimeType(index, e.target.value)}
                      value={this.state.overtimeTypeList && this.state.overtimeTypeList[index]}
                      
                    >
                      
                      {this.props.overtimeTypes.map(type => (
                        <option key={type}>{type}</option>
                      ))}
                    </Select>
                  </LabelGroup>
                </Row>
              ))}
              <Row>
                <SubmitButtonWrapper>
                  <SubmitButton primary>
                    {this.state.loading ? (
                      <SpinnerWrapper>
                        <Spinner />
                      </SpinnerWrapper>
                    ) : (
                      'Save'
                    )}
                  </SubmitButton>
                </SubmitButtonWrapper>
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
              </Row>
            </Form>
          </BackgroundWrapper>
        )
      case EDIT:
        return (
          <BackgroundWrapper>
            <Edit
              day={selectedDay}
              onClose={this.gotoScreen(OVERVIEW)}
              onRemove={ev => {
                const { shiftId, date, type } = selectedDay
                this.props.removeDay(shiftId, date, type).then(err => {
                  if (err == null) {
                    this.props.onDismiss()
                  }
                })
                ev.preventDefault()
              }}
              onSave={(ev, day) => {
                const { shiftId, date, type } = selectedDay
                //const { techsRequired, overtimes } = day
                const { techsRequired, overtimes, resourceNameList, overtimeTypeList } = day;
                const overtimeObject = resourceNameList.map((res, index) => {
                  return {
                    id: overtimes[index] && overtimes[index].id || null,
                    shiftId: parseInt(res.shiftId),
                    overtimeType: overtimeTypeList[index]
                  }
                })
                handleEdit(shiftId, date, type, techsRequired, overtimeObject).then(
                  err => {
                    if (err == null) {
                      this.props.onDismiss()
                    }
                  }
                )
                ev.preventDefault()
              }}
              getResourceNames={this.props.getResourceNames}
            />
          </BackgroundWrapper>
        )
      case REMOVE:
        return (
          <BackgroundWrapper>
            <RemoveHeader title="Are you sure you want to remove?" onDismiss={() => this.setState({screen: OVERVIEW})} />
            <RemoveForm>
              <Row>
              <RemoveModalWrapper>
                  <LabelName>Removing this resource will remove the person assigned from the overtime.</LabelName>
              </RemoveModalWrapper>
              </Row>
              <Row>
                <LabelGroup>
                  <Button style={{backgroundColor: '#949292'}} onClick={() => this.setState({screen: OVERVIEW})}>Cancel</Button>
               </LabelGroup>
                <LabelGroup>
                  <Button onClick={(e) => {
                    e.preventDefault();
                    this.props.removeResource(this.state.overtimeId)
                    this.props.onDismiss()
                  }}>Remove</Button>
                </LabelGroup>
              </Row>
            </RemoveForm>
          </BackgroundWrapper>
        )
      case OVERVIEW:
      default:
        return (
          <TopWrapper>
            
            {user.role !== 'basic' && user.role !== 'projectManager' &&
              <NewDayWrapper>
                <SubmitButton primary onClick={this.gotoScreen(CREATE)}>
                + New Special Day
                </SubmitButton>
              </NewDayWrapper>
            }
            

            {daysList.length > 3 && nextFlag &&
              <NextIcon onClick={() => {
                let tmpDays = [...daysList];
                tmpDays[0].disabled = true;
                tmpDays[3].disabled = false;
                this.setState({daysList: tmpDays, nextFlag: false});
              }}/>
            }

            {daysList.length > 3 && !nextFlag &&
              <PreviousIcon onClick={() => {
                let tmpDays = [...daysList];
                tmpDays[0].disabled = false;
                tmpDays[3].disabled = true;
                this.setState({daysList: tmpDays, nextFlag: true});
              }}/>
            }
        
            {daysList.map((day, index) => {
              let wrapperStyle = Object.assign({},
                (index === 0) || (index === 1 && !nextFlag) ? firstOverviewStyle : {}, 
                daysList.length === 1 ? oneOverviewStyle : {}
              )
              if (!day.disabled) {
                return (
                  <OverviewWrapper style={wrapperStyle}>
                    <Overview
                      day={day}
                      user={user}
                      onEdit={this.gotoScreen(EDIT, day)}
                      handleRemoveModal={this.handleRemoveModal}
                    />
                  </OverviewWrapper>
                )
              }
            })}
          </TopWrapper>
        )
    }
  }
}

SpecialDaysModalContainer.propTypes = {
  areaId: PropTypes.number,
  date: PropTypes.string,
}

SpecialDaysModalContainer.defaultProps = {
  overtimeTypes: [
    'COMMS',
    'PAYG',
    'TOIL'
  ],
}

export default connect(
  function mapStateToProps(state, ownProps) {
    return {
      days: daysSelector(state, ownProps),
      user: state.auth.user,
    }
  },
  {
    handleEdit: SpecialDaysActions.editSpecialDays,
    onSave: SpecialDaysActions.newSpecialDays,
    removeResource: SpecialDaysActions.removeSpecialDayResource,
    removeDay: SpecialDaysActions.removeSpecialDay,
    fetchSpecialDays: SpecialDaysActions.fetchSpecialDays,
    getResourceNames: EditDayActions.getResourceNames
  }
)(SpecialDaysModalContainer)

function daysSelector(state, props) {
  const { areaId, date } = props
  const dateKey = date.slice(0, 7)
  const { specialDays } = state.entities

  const area = specialDays[dateKey].areas[areaId]

  return area.specialDays.filter(x => x.date.slice(0, 10) === date)
}
