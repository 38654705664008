import styled from 'styled-components'

const Title = styled.h1`
  float: left;
  font-size: 20px;
  margin: 0;
  font-weight: normal;
  text-transform: uppercase;
  width: 280px;
`

export default Title
