import { getUTCYearMonthIndex } from '../Utils/time'

// Action types
const FETCH = 'roster/basic-dashboard/fetch'
const RECEIVE = 'roster/basic-dashboard/receive'

// Reducer
const initialState = {
  date: getUTCYearMonthIndex(new Date()),
  isFetching: false,
  error: null,
  sections: null,
}

export default function reducer(state = initialState, action) {
  const { error, payload } = action
  switch (action.type) {
    case FETCH:
      return { ...state, isFetching: true }
    case RECEIVE: {
      if (error) {
        return {
          ...state,
          isFetching: false,
          error: { message: payload.message },
        }
      }
      return {
        isFetching: false,
        error: null,
        date: payload.date,
        sections: payload.sections
      }
    }
    default:
      return state
  }
}

function spreadAndMap(key, next) {
  return function(x) {
    return {
      ...x,
      [key]: notNullWithLength(x[key]) ? x[key].map(next) : x[key],
    }
  }
}

function notNullWithLength(x) {
  return x && x.length > 0
}

// Action creators
function startFetch() {
  return { type: FETCH }
}

function receivedError(message) {
  return {
    type: RECEIVE,
    error: true,
    payload: new Error(message),
  }
}

function receivedData(date, sections) {
  return {
    type: RECEIVE,
    payload: { date, sections },
  }
}

// Thunks
function fetchSections(year, monthIndex) {
  return function(dispatch, getState, api) {
    dispatch(startFetch())

    api
      .profile(year, monthIndex)
      .then(response => {
        if (response.ok) {
          return dispatch(receivedData({ year, monthIndex }, response.data))
        }
        return dispatch(receivedError(response.statusText))
      })
      .catch(err => {
        dispatch(receivedError(err.message))
      })
  }
}

export const actions = {
  fetchSections,
}
