import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Palette } from '../../Themes'
import { center } from '../../Utils'

const sectionLargeStyles = css`
  font-size: 20px;
  height: 46px;
  margin-right: 14px;
  width: 46px;
`

const sectionMediumStyles = css`
  font-size: 12px;
  height: 32px;
  margin-right: 4px;
  width: 32px;
`

const sectionOutlineStyles = colour => css`
  background: transparent;
  border: 1px solid transparent;
  border-color: ${colour};
  color: ${colour};
`

const Element = styled.div`
  ${center};
  background: ${({ colour }) => colour && colour};
  box-sizing: border-box;
  color: ${Palette.textWithSectionColor};
  font-weight: 300;
  font-size: 10px;
  height: 26px;
  margin-right: 1px;
  width: 26px;
  ${({ medium }) => medium && sectionMediumStyles}
  ${({ large }) => large && sectionLargeStyles}
  ${({ colour, outline }) => outline && colour && sectionOutlineStyles(colour)}
  margin: ${({ noMargin }) => noMargin && 0};
`

const Section = ({
  children,
  colour,
  large,
  medium,
  noMargin,
  outline,
  title,
}) => (
  <Element
    colour={colour}
    large={large}
    medium={medium}
    noMargin={noMargin}
    outline={outline}
  >
    {title}
    {children}
  </Element>
)

Section.propTypes = {
  children: PropTypes.node,
  colour: PropTypes.string,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  noMargin: PropTypes.bool,
  outline: PropTypes.bool,
  title: PropTypes.string,
}

export default Section
