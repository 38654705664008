import React, { Component } from 'react'
import styled from 'styled-components'
import { findDOMNode } from 'react-dom'
import { get } from 'lodash'
import { TweenMax, Sine, Elastic } from 'gsap'

import { Colors } from '../../Themes'
import Button from '../Button'
import Icon from '../Icon'

const Page = styled.div`
  background-color: ${Colors.purple};
  box-sizing: border-box;
  color: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

const Content = styled.div`
  box-sizing: border-box;
  max-width: 320px;
  width: 100%;
`

const Wrapper = styled.div`
  background-color: ${Colors.purple};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

const InputWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colors.purpleOpacity};
  display: flex;
  margin: 12px 0px;
`

const Input = styled.input`
  background: transparent;
  border: 0;
  color: ${Colors.white};
  font-size: 14px;
  letter-spacing: 1px;
  outline: 0;
  padding: 12px;
  width: 100%;

  ::placeholder {
    color: ${Colors.purpleOpacity};
  }
`

const ResetPasswordButton = styled(Button)`
  margin-top: 48px;
`

const Error = styled.div`
  bottom: -40px;
  opacity: 0;
  position: absolute;
  width: 100%;
`

const ErrorMessage = styled.div`
  color: ${Colors.purpleOpacity};
  text-align: center;
`

export default class ResetPassword extends Component {
  state = {
    username: '',
    error: '',
  }

  handleButtonRef = ref => (this.button = ref)
  handleErrorRef = ref => (this.error = ref)

  componentDidMount() {
    this.button = findDOMNode(this.button)
    this.error = findDOMNode(this.error)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetPasswordError !== this.props.resetPasswordError) {
      const error = get(nextProps, 'resetPasswordError.message')
      this.setState({ error })
      this.resetFailed()
    }

    if (nextProps.resetPasswordComplete) {
      this.resetSuccess()
    }
  }

  handleResetPassword = () => {
    const { resetPassword } = this.props
    const { username } = this.state

    resetPassword(username)
  }

  showMessage() {
    TweenMax.fromTo(
      this.error,
      0.5,
      {
        autoAlpha: 0,
        y: 20,
      },
      {
        autoAlpha: 1,
        y: 0,
        ease: Sine.easeInOut,
        onComplete: () =>
          TweenMax.to(this.error, 0.5, {
            autoAlpha: 0,
            y: 20,
            ease: Sine.easeInOut,
            delay: 3,
          }),
      }
    )
  }

  shakeButton() {
    TweenMax.fromTo(
      this.button,
      0.05,
      {
        x: -20,
      },
      {
        x: 20,
        repeat: 8,
        yoyo: true,
        ease: Sine.easeInOut,
        onComplete: () =>
          TweenMax.to(this.button, 1, {
            x: 0,
            ease: Elastic.easeOut,
          }),
      }
    )
  }

  resetSuccess() {
    this.setState({
      error: `We've just sent you an email to reset your password.`,
      username: '',
    })

    this.showMessage()
  }

  resetFailed() {
    this.setState({
      error: `There was a problem resetting your password.`,
      username: '',
    })

    this.shakeButton()
    this.showMessage()
  }

  render() {
    const { username, error } = this.state

    return (
      <Page>
        <Content>
          <Wrapper>
            <InputWrapper>
              <Icon icon="envelope" />
              <Input
                placeholder="Username"
                onChange={event =>
                  this.setState({ username: event.target.value })
                }
              />
            </InputWrapper>
            <ResetPasswordButton
              ref={this.handleButtonRef}
              disabled={!username}
              onClick={this.handleResetPassword}
            >
              Reset Password
            </ResetPasswordButton>
            <Error ref={this.handleErrorRef}>
              <ErrorMessage>{error}</ErrorMessage>
            </Error>
          </Wrapper>
        </Content>
      </Page>
    )
  }
}
