import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { AuthActions, AuthSelectors } from '../Redux/AuthRedux'
import ResetPassword from '../Components/ResetPassword'

const ResetPasswordContainer = props => (
  <>
    <Helmet title="Reset Password" />
    <ResetPassword {...props} />
  </>
)

ResetPasswordContainer.propTypes = {
  resetPasswordError: PropTypes.string,
  failedResetPassword: PropTypes.bool,
  resetPasswordComplete: PropTypes.bool,
  resetPassword: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  resetPasswordError: AuthSelectors.resetPasswordError(state),
  failedResetPassword: AuthSelectors.resetPasswordFailed(state),
  resetPasswordComplete: AuthSelectors.resetPasswordComplete(state),
})

const mapDispatchToProps = dispatch => ({
  resetPassword: username =>
    dispatch(AuthActions.resetPasswordRequest(username)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer)
