import React from 'react'

function RightChevron(passedProps) {
  const props = {
    viewBox: '0 0 24 24',
    xmlns: 'http://www.w3.org/2000/svg',
    ...passedProps,
  }
  return (
    <svg {...props}>
      <path
        d="M9.062 19.025l.223-.26 5.582-6.533a.79.79 0 0 0-.023-1.032L9.025 4.975"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default RightChevron
