import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Header from '../Components/Header'
import { AuthActions } from '../Redux/AuthRedux'
import { actions as AppActions } from '../Redux/app'

class HeaderContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props.fetchSections()
  }

  render() {
    return (
      <Header
        logout={this.props.logout}
        user={this.props.user}
        sections={this.props.sections}
      />
    )
  }
}

HeaderContainer.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['admin', 'basic', 'projectManager', 'engineerManager']),
  }),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      shortName: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
}

export default connect(
  function mapStateToProps(state, ownProps) {
    const sections = state.app.navigation.sections
    return {
      user: state.auth.user,
      sections: Object.keys(sections)
        .sort()
        .map(id => sections[id]),
    }
  },
  {
    fetchSections: AppActions.fetchNavigation,
    logout: AuthActions.logoutRequest,
  }
)(HeaderContainer)
