import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'

import Section from '../Section'
import Spinner from '../Spinner'
import RosterTable from '../RosterTable'
import PaginateControl from '../PaginateControl'
import { Colors, Metrics } from '../../Themes'

import { getSectionColor } from '../../Utils'

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 2rem auto;
  padding: 0px 40px;
  width: 100%;
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => vertical && 'column'};
  margin-bottom: 10px;
  min-width: 280px;
  overflow: auto;
`

const Sidebar = styled.div`
  align-items: center;
  box-sizing: border-box;
  padding-right: 6px;
  display: flex;
  min-height: 24px;
  min-width: calc(${Metrics.sidebar} - 18px);
`

const RosterText = styled.span`
  color: ${Colors.dark};
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
`

const Roster = ({
  loading,
  nextMonth,
  previousMonth,
  areas,
  month,
  year,
  section,
  dateString,
  modalShow,
  sections,
  ...props
}) => (
  <Wrapper opaque reverse {...props}>
    <SectionWrapper>
      <Sidebar>
        <Section
          colour={getSectionColor(section)}
          large
          title={sections.length > 0 && sections[parseInt(section) - 1].specialName !== '' ? sections[parseInt(section) - 1].specialName : `S${section}`}
        />
        <RosterText>Roster</RosterText>
      </Sidebar>
      <PaginateControl
        minWidth={200}
        current={moment(dateString).format('MMMM YYYY')}
        onPrevious={previousMonth}
        onNext={nextMonth}
      />
    </SectionWrapper>
    {loading ? (
      <Spinner reverse />
    ) : (
      areas.length > 0 && (
        <RosterTable
          {...{ areas, month, year, section, dateString, modalShow }}
        />
      )
    )}
  </Wrapper>
)

export default Roster
