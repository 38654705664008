import _ from './Colors'

const palette = {
  bodyBackground: _.offWhite,
  error: _.red,
  success: _.cyan,
  shortDay: _.navyBlue,
  day: _.lightBlue,
  night: _.darkBlue,
  text: _.black,
  inputText: _.black,
  inputBackground: _.white,
  inputBorder: _.silver,
  labelText: _.silver,
  buttonText: _.white,
  buttonBackground: _.red,
  buttonPrimaryBackground: _.purple,
  buttonDisabledBackground: _.lightGrey,
  textWithSectionColor: _.white,
  cellBorder: _.lightGrey,
  contentDivider: _.lightGrey,
  interfaceText: _.darkGrey,

  // Section colours
  section1: _.cyan,
  section2: _.green,
  section3: _.magenta,
  section4: _.red,
  section5: _.orange,
  sectionDayOnly: _.lightBlue,
  sectionApprentices: _.darkBlue,
  noSectionColor: _.black,
}

export default palette
