import styled from 'styled-components'

const Logout = styled.button`
  border: 0;
  font-size: 12px;
  padding: 1em 2.4em;
  background-color: white;
  text-decoration: none;
  font-weight: bolder;
  display: inline-block;
  line-height: 1;
`

export default Logout
