import React from 'react'

function Caret(passedProps) {
  const props = {
    viewBox: '0 0 24 24',
    xmlns: 'http://www.w3.org/2000/svg',
    ...passedProps,
  }
  return (
    <svg {...props}>
      <path
        d="M16.814 10.135l-4.375 4.667A.583.583 0 0 1 12 15a.583.583 0 0 1-.44-.198l-4.374-4.667A.662.662 0 0 1 7 9.667c0-.18.062-.337.186-.469A.583.583 0 0 1 7.625 9h8.75c.17 0 .316.066.44.198a.662.662 0 0 1 .185.469c0 .18-.062.336-.186.468z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Caret
