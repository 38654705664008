// Clean up first with https://jakearchibald.github.io/svgomg/ then
// use https://svg2jsx.herokuapp.com/ for the initial conversion
//
// Avoid setting width and height on <svg>
// Remove any default fill/stroke color
export { default as Caret } from './Caret'
export { default as Cog } from './Cog'
export { default as Dots } from './Dots'
export { default as Face } from './Face'
export { default as LeftChevron } from './LeftChevron'
export { default as List } from './List'
export { default as Pencil } from './Pencil'
export { default as RightChevron } from './RightChevron'
