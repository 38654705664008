import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'

import { Button, LabelGroup, LabelName, Select, Text } from '../Forms'
import { Colors, Palette } from '../../Themes'

const Row = styled.div`
  display: flex;
`

const FullWidth = styled.div`
  margin-top: 1.5rem;
  margin-left: 2rem;
  width: calc(100% - 64px);
`

const Form = styled.form`
  font-size: 0.9em;
  min-height: 280px;
`

const SubmitButtonWrapper = styled.div`
  width: 210px;
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const ErrorMessage = styled.p`
  color: ${Palette.error};
  width: 210px;
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const holidayTypeLookup = {
  'Annual Leave': 1,
  'Banked Hours': 2,
  TOIL: 3,
  'Union TOIL': 4,
  'Accrued Hours': 5,
  'Accrued Banked Hours': 6,
}

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.resetFormData()

    this.processCallback = this.processCallback.bind(this)
    this.resetFormData = this.resetFormData.bind(this)
  }

  componentWillMount() {
    if (this.props.cover) {
      this.setState({ resourceName: { name:this.props.cover.name, shiftId: this.props.cover.shiftId || null}, overtimeType: this.props.cover.overtimeType });
    }
  }

  updateState(key, value = 'value') {
    return ev => this.setState({
      [key]: ev.target[value],
    })
  }

  updateResourceName(name, shiftId) {
    this.setState({
      resourceName: { shiftId, name },
      showReason: this.props.cover && name !== this.props.cover.name ? true : false
    })
  }
  
  handleSave(date) {
    const {
      offDayId,
      type,
      coverRequired,
      shiftId,
      holidayType,
      startTime,
      endTime,
      updateDashboard
     } = this.props
    const overtime = {
      shiftId: parseInt(this.state.resourceName.shiftId),
      overtimeType: this.state.overtimeType || 'COMMS',
      reason: this.state.reason
    }
    const holidayTypeId = holidayTypeLookup[holidayType] || 1
    return ev => {
      updateDashboard(offDayId, date, type, coverRequired, shiftId, holidayTypeId, startTime, endTime, overtime, (data) => this.props.onClose())
      ev.preventDefault()
    }
  }

  processCallback(error) {
    if (error) {
      this.setState({ error })
    } else {
      typeof this.props.next === 'function' && this.props.next()
    }
  }

  resetFormData() {
    const { cover } = this.props;
    return {
      resourceName: { name: cover && cover.name || null, shiftId: cover && cover.shiftId || null },
      overtimeType: cover && cover.overtimeType || null,
    }
  }

  render() {
    const { shift, name, day, cover } = this.props
    const { error, overtimeType, resourceName } = this.state

    return (
        <>
        <Form
          onSubmit={this.handleSave()}
        >
          <Row>
            <LabelGroup>
              <LabelName>Resource name (optional)</LabelName>
              <Select
                onChange={(e) => {
                  const selectedIndex = e.target.options.selectedIndex
                  this.updateResourceName(e.target.value, e.target.options[selectedIndex].getAttribute('data-shiftId'))
                }}
                value={this.state.resourceName && this.state.resourceName.name}
              >
                
                {[{id: 0, name: this.props.cover && this.props.cover.name ? this.props.cover.name : 'Select cover'}, ...this.props.resourceNames].map(type => (
                  <option key={type.id} data-shiftId={type.shiftId}>{type.name}</option>
                ))}
              </Select>
            </LabelGroup>
            <LabelGroup>
              <LabelName>Day</LabelName>
              <Text disabled value={moment(shift && shift.date).format('DD MMM YYYY')} />
            </LabelGroup>
          </Row>
          <Row>
            <LabelGroup>
              <LabelName>Overtime type</LabelName>
              <Select
                onChange={this.updateState('overtimeType')}
                value={overtimeType}
                disabled={!this.state.resourceName}
              >
                
                {this.props.overtimeTypes.map(type => (
                  <option key={type}>{type}</option>
                ))}
              </Select>
            </LabelGroup>
            <LabelGroup>
                <LabelName>Covering for</LabelName>
                <Text disabled value={name}/>
              </LabelGroup>
          </Row>
          { this.state.showReason &&
            <Row>
              <FullWidth>
                <LabelName>Reason for cover swap (will be sent to previous cover)</LabelName>
                <Text value={this.state.reason} onChange={this.updateState('reason')}/>
              </FullWidth>
            </Row>
          }
          <Row>
            <SubmitButtonWrapper>
              <Button
                primary
                disabled={compare(this.resetFormData(), {
                  resourceName: this.state.resourceName,
                  overtimeType: this.state.overtimeType
                })}
              >
                Save
              </Button>
            </SubmitButtonWrapper>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </Row>
        </Form>
        </>
    )
  }
}

const keysToUpdate = ['resourceName','overtimeType']

function compare(o, n) {
  return keysToUpdate.every(key => {
    return JSON.stringify(o[key]) === JSON.stringify(n[key])
  })
}

Edit.propTypes = {
  name: PropTypes.string,
}


Edit.defaultProps = {
  overtimeTypes: [
    'COMMS',
    'PAYG',
    'TOIL'
  ],
}

export default Edit
