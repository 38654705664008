import { css } from 'styled-components'
import {
  headerHeight,
  navLinkSize,
  navLinkUnderbarHeight,
  navLinkUnderbarOverhang,
} from './measures'

export const commonLinkStyles = css`
  display: inline-block;
  float: left;
  font-size: 12px;
  height: ${navLinkSize}px;
  line-height: ${navLinkSize}px;
  margin-right: 2px;
  ${() => {
    const remaining = (headerHeight - navLinkSize) / 2
    return css`
      padding: ${remaining}px ${navLinkUnderbarOverhang}px ${remaining}px
        ${navLinkUnderbarOverhang}px;
    `
  }}
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  width: ${navLinkSize}px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    transition: 400ms height ease-in-out;
  }

  &.active::after {
    height: ${navLinkUnderbarHeight}px;
  }
`

export default commonLinkStyles
