import styled from 'styled-components'
import Username from '../Username'

const UsernamePadding = styled(Username)`
  margin-right: 25px;
  display: block;
  float: left;
`

export default UsernamePadding
