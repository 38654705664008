import { mergeWith, isArray } from 'lodash'

const ENTITIES_GENERIC = 'entities generic'

// Reducer
const initialState = {
  areas: {},
  offDays: {},
  overtimes: {},
  profiles: {},
  sections: {},
  specialDays: {},
}

export default function reducer(state = initialState, action) {
  if (action && action.payload && action.payload.entities) {
    return mergeWith({}, state, action.payload.entities, function(
      objValue,
      srcValue
    ) {
      if (isArray(objValue)) {
        return srcValue
      }
    })
  }
  return state
}

// Action creators
function entities(payload) {
  return {
    type: ENTITIES_GENERIC,
    payload,
  }
}

export const actions = {
  entities,
}
