import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { TweenMax, Sine, Elastic } from 'gsap'

import { Colors } from '../../Themes'
import Button from '../Button'
import Icon from '../Icon'
import Spinner from '../Spinner'

const Page = styled.div`
  background-color: ${Colors.purple};
  box-sizing: border-box;
  color: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

const Content = styled.div`
  box-sizing: border-box;
  max-width: 320px;
  width: 100%;
`

const Wrapper = styled.div`
  background-color: ${Colors.purple};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

const InputWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colors.purpleOpacity};
  display: flex;
  margin: 12px 0px;
`

const Input = styled.input`
  background: transparent;
  border: 0;
  color: ${Colors.white};
  font-size: 14px;
  letter-spacing: 1px;
  outline: 0;
  padding: 12px;
  width: 100%;

  ::placeholder {
    color: ${Colors.purpleOpacity};
  }
`

const LoginButton = styled(Button)`
  position: relative;
  margin-top: 48px;
  width: 100%;
`

const SpinnerWrapper = styled.div`
  width: 1.2em;
  height: 1.2em;
`

const ForgotButton = styled(Button)`
  justify-content: flex-start;
`

const Error = styled.div`
  bottom: -40px;
  opacity: 0;
  position: absolute;
  width: 100%;
`

const ErrorMessage = styled.div`
  color: ${Colors.purpleOpacity};
  text-align: center;
`

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      error: '',
    }
    this.buttonRef = React.createRef()
    this.errorRef = React.createRef()

    this.handleLogin = this.handleLogin.bind(this)
    this.handleReturn = this.handleReturn.bind(this)
    this.handleUsername = this.handleInput('username')
    this.handlePassword = this.handleInput('password')
  }

  handleInput(key) {
    return ev => {
      this.setState({ [key]: ev.target.value })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginError !== prevProps.loginError) {
      if (this.props.loginError) {
        const error = get(this.props, 'loginError.message')
        this.setState({ error, password: '' })
        this.failedLogin()
      }
    }
  }

  failedLogin() {
    TweenMax.fromTo(
      this.buttonRef.current,
      0.05,
      {
        x: -20,
      },
      {
        x: 20,
        repeat: 8,
        yoyo: true,
        ease: Sine.easeInOut,
        onComplete: () =>
          TweenMax.to(this.buttonRef.current, 1, {
            x: 0,
            ease: Elastic.easeOut,
          }),
      }
    )

    TweenMax.fromTo(
      this.errorRef.current,
      0.5,
      {
        autoAlpha: 0,
        y: 20,
      },
      {
        autoAlpha: 1,
        y: 0,
        ease: Sine.easeInOut,
        onComplete: () =>
          TweenMax.to(this.errorRef.current, 0.5, {
            autoAlpha: 0,
            y: 20,
            ease: Sine.easeInOut,
            delay: 3,
          }),
      }
    )
  }

  handleLogin() {
    const { login } = this.props
    const { username, password } = this.state
    login(username, password)
  }

  handleReturn(event) {
    const { username, password } = this.state
    if (username && password && event.key === 'Enter') {
      this.handleLogin()
    }
  }

  render() {
    const { loginFetching } = this.props
    const { username, password, error } = this.state

    // Initial refresh login
    if (loginFetching && !username) {
      return (
        <Page>
          <Spinner />
        </Page>
      )
    }

    return (
      <Page>
        <Content>
          <Wrapper>
            <InputWrapper>
              <Icon icon="envelope" />
              <Input
                placeholder="Username"
                value={username}
                onChange={this.handleUsername}
                onKeyDown={this.handleReturn}
              />
            </InputWrapper>
            <InputWrapper>
              <Icon icon="lock" />
              <Input
                placeholder="Password"
                type="password"
                value={password}
                onChange={this.handlePassword}
                onKeyDown={this.handleReturn}
              />
            </InputWrapper>
            {/* simplifies by not having to use findDOMNode */}
            <div ref={this.buttonRef}>
              <LoginButton
                disabled={!username || !password}
                onClick={this.handleLogin}
              >
                {loginFetching ? (
                  <SpinnerWrapper>
                    {/* opposite of above to reverse colours */}
                    <Spinner reverse={username || password} />
                  </SpinnerWrapper>
                ) : (
                  'Login'
                )}
              </LoginButton>
            </div>
            <ForgotButton to="/reset-password" link="true">
              Forgotten password?
            </ForgotButton>
            <Error ref={this.errorRef}>
              <ErrorMessage>{error}</ErrorMessage>
            </Error>
          </Wrapper>
        </Content>
      </Page>
    )
  }
}

Login.propTypes = {
  loginFetching: PropTypes.bool,
  loginError: PropTypes.shape({
    message: PropTypes.string,
  }),
  login: PropTypes.func.isRequired,
}

export default Login
