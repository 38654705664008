import { call, put } from 'redux-saga/effects'
import { RosterActions } from '../Redux/RosterRedux'
import { actions as EntitiesActions } from '../Redux/entities'

/**
 * Roster saga.
 *
 * @export
 * @param {any} api
 * @param {any} action
 */
export function* roster(api, action) {
  try {
    const { section, year, month } = action
    const response = yield call([api, api.roster], section, year, month)
    if (response.ok) {
      yield put(RosterActions.rosterSuccess())
      yield put(
        EntitiesActions.entities(
          normalizeResponse(response.data, { section, year, month })
        )
      )
    } else {
      throw new Error('Bad response')
    }
  } catch (error) {
    yield put(RosterActions.rosterFailure(error))
  }
}

function normalizeResponse(res, params) {
  const cleaned = res.reduce(
    (acc, area) => {
      const { profiles, ...rest } = area
      acc.result.areas.push(area.id)
      let profilesMap
      if (profiles) {
        profilesMap = profiles.map(profile => {
          const key = `${profile.id}-${area.id}`;
          acc.entities.profiles[key] = profile
          return key;
        })
        acc.result.profiles = acc.result.profiles.concat(profilesMap)
      } else {
        profilesMap = []
      }
      acc.entities.areas[area.id] = { ...rest, profiles: profilesMap }
      return acc
    },
    {
      entities: { areas: {}, profiles: {} },
      result: { areas: [], profiles: [] },
    }
  )
  return {
    ...cleaned,
    entities: {
      ...cleaned.entities,
      sections: {
        [params.section]: {
          id: params.section,
          areas: cleaned.result.areas,
        },
      },
    },
  }
}
