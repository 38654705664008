import React from 'react'
import { hot } from 'react-hot-loader'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import Helmet from 'react-helmet'

import Page from '../Components/Page'
import AppConfig from '../Config/AppConfig'
import { AuthSelectors } from '../Redux/AuthRedux'
import BasicDashboardContainer from './BasicDashboardContainer'
import DashboardContainer from './DashboardContainer'
import HeaderContainer from './HeaderContainer'
import LoginContainer from './LoginContainer'
import ResetPasswordContainer from './ResetPasswordContainer'
import RosterContainer from './RosterContainer'
import SettingsContainer from './SettingsContainer'

function App(props) {
  const isAuthenticated = props.role != null
  return (
    <Router>
      <>
        <Helmet
          defaultTitle={AppConfig.appName}
          titleTemplate={'%s | ' + AppConfig.appName}
        />
        <Page>
          {/* Header reacts to changes in route so render it with Route */}
          {isAuthenticated && <Route component={HeaderContainer} />}
          <Switch>
            <PrivateRoute
              authenticated={isAuthenticated}
              role={props.role}
              path="/"
              exact
              component={Index}
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              path="/section/:sectionID"
              exact
              component={RosterContainer}
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              path="/settings"
              component={SettingsContainer}
            />
            <Route path="/login" component={LoginContainer} exact />
            <Route
              path="/reset-password"
              component={ResetPasswordContainer}
              exact
            />
            <Route component={NoMatch} />
          </Switch>
        </Page>
      </>
    </Router>
  )
}

function Index(props) {
  const { role, ...restProps } = props
  return role === 'admin' || role === 'projectManager' || role === 'engineerManager' ? (
    <DashboardContainer {...restProps} />
  ) : (
    <BasicDashboardContainer {...restProps} />
  )
}

function NoMatch() {
  return <p>Page not found</p>
}

function PrivateRoute({ component: Component, authenticated, role, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Component role={role} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default hot(module)(
  connect(function mapStateToProps(state) {
    return { role: AuthSelectors.role(state) }
  })(App)
)
