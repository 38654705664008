import React from 'react'
import Helmet from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import Page from '../Components/Page'
import SecondaryNavigation from '../Components/SecondaryNavigation'
import { Palette } from '../Themes'
import SpecialDaysContainer from './SpecialDaysContainer'

function SettingsContainer() {
  return (
    <Page backgroundColor={Palette.bodyBackground}>
      <Helmet title="Settings" />
      <Route component={SecondaryNavigation} />
      <Switch>
        <Route exact path="/settings">
          <h1>Employees</h1>
        </Route>
        <Route
          exact
          path="/settings/special-days"
          component={SpecialDaysContainer}
        />
      </Switch>
    </Page>
  )
}

export default SettingsContainer
