import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { get } from 'lodash'

// Types and action creators
const { Types, Creators } = createActions({
  rosterRequest: ['section', 'year', 'month'],
  rosterSuccess: null,
  rosterFailure: ['error'],
})

export const RosterTypes = Types
export const RosterActions = Creators

// Initial state
export const INITIAL_STATE = Immutable({
  error: null,
  fetching: false,
  sections: null,
})

// Selectors
export const RosterSelectors = {
  error: state => get(state, 'roster.error'),
  fetching: state => get(state, 'roster.fetching'),
}

// Reducers
export const request = state => state.merge({ fetching: true })
export const success = state => state.merge({ fetching: false, error: null })
export const failure = state => state.merge({ fetching: false, error: true })

// Hookup reducers to types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ROSTER_REQUEST]: request,
  [Types.ROSTER_SUCCESS]: success,
  [Types.ROSTER_FAILURE]: failure,
})
