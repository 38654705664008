import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { get } from 'lodash'
import moment from 'moment-timezone'

// Types and action creators
const { Types, Creators } = createActions({
  dashboardRequest: ['date'],
  dashboardSuccess: ['data', 'date'],
  dashboardFailure: ['error'],
})

export const DashboardTypes = Types
export const DashboardActions = Creators

// Initial state
export const INITIAL_STATE = Immutable({
  data: [],
  error: null,
  fetching: false,
  date: moment().format('YYYY-MM-DD'),
})

// Selectors
export const DashboardSelectors = {
  data: state => get(state, 'dashboard.data'),
  error: state => get(state, 'dashboard.error'),
  fetching: state => get(state, 'dashboard.fetching'),
  date: state => get(state, 'dashboard.date'),
}

// Reducers
export const request = state => state.merge({ fetching: true })
export const success = (state, { data, date }) =>
  state.merge({ fetching: false, error: null, data, date })
export const failure = state =>
  state.merge({ fetching: false, error: true, data: null })

// Hookup reducers to types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.DASHBOARD_REQUEST]: request,
  [Types.DASHBOARD_SUCCESS]: success,
  [Types.DASHBOARD_FAILURE]: failure,
})
