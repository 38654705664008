import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import { Colors } from '../../Themes'
import { pad } from '../../Utils'
import { daysInMonth, dayOfWeek, isToday } from '../../Utils/time'
import TableBase from '../Table'

export const metrics = {
  cellSize: 38,
}

const Table = styled(TableBase)`
  border-collapse: separate;
  border-spacing: 0;
`

const DayOfWeek = styled.th`
  padding: 0;
  font-size: 9px;
  height: ${metrics.cellSize}px;
  min-width: ${metrics.cellSize}px;
  background-color: transparent;
  color: ${Colors.silver};
`

export const Day = styled.th`
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  font-weight: normal;
  border-top: 1px solid ${Colors.lightGrey};
  border-bottom: 0 solid transparent;
  font-size: 12px;
  height: ${metrics.cellSize / 12}em;
  min-width: ${metrics.cellSize / 12}em;

  &:first-child {
    border-left: 1px solid ${Colors.lightGrey};
    border-top-left-radius: 3px;
  }

  &:last-child {
    border-right: 1px solid ${Colors.lightGrey};
    border-top-right-radius: 3px;
  }
  ${props =>
    props.isToday &&
    css`
      border-width: 3px 0 3px 0;
    `}
`

function MonthTable(props) {
  const { date: yearMonth, rows } = props
  const amountOfDays = daysInMonth(yearMonth)
  const dowHeader = new Array(amountOfDays)
  const dayHeader = new Array(amountOfDays)
  const body = new Array(amountOfDays * rows.length)
  // bunch up all our row generation to happen in one loop
  for (let i = 0; i < amountOfDays; i++) {
    const indexDate = new Date(yearMonth)
    indexDate.setDate(i + 1)
    dowHeader[i] = (
      <DayOfWeek key={indexDate.getTime() + i}>
        {dayOfWeek(yearMonth, i + 1, true)}
      </DayOfWeek>
    )
    dayHeader[i] = (
      <Day key={i} isToday={isToday(indexDate)}>
        {pad(i + 1, 2)}
      </Day>
    )
    for (let j = 0; j < rows.length; j++) {
      body[j * amountOfDays + i] = props.dayFn({
        date: indexDate,
        row: rows[j],
      })
    }
  }

  return (
    <Table
      {...{
        className: props.className,
        head: (
          <>
            <tr>{dowHeader}</tr>
            <tr>{dayHeader}</tr>
          </>
        ),
        body: (
          <>
            {/* Note: that we are returning data from `body` and not directly
             * from `rows`, however, we need to derive a unique key based on
             * our data using `rowKey` */}
            {rows.map((x, i) => (
              <tr key={props.rowKey(x)}>
                {body.slice(i * amountOfDays, (i + 1) * amountOfDays)}
              </tr>
            ))}
          </>
        ),
      }}
    />
  )
}

MonthTable.defaultProps = {
  rows: [],
}

MonthTable.propTypes = {
  rows: PropTypes.array,
  rowKey: PropTypes.func.isRequired,
  dayFn: PropTypes.func.isRequired,
  date: PropTypes.any,
}

export default MonthTable
