import { NavLink as ReactRouterNavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Colors } from '../../../Themes'
import commonLinkStyles from './commonLinkStyles'

const NavLink = styled(ReactRouterNavLink)`
  ${commonLinkStyles}

  background-clip: content-box;
  color: ${Colors.white};

  &:last-child {
    margin-right: 0;
  }

  ${props => css`
    background-color: ${props.color};

    &::after {
      background-color: ${props.color};
    }
  `}
`

NavLink.defaultProps = {
  color: Colors.cyan,
}

export default NavLink
