import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { actions as EditDayActions } from '../../Redux/editDay'
// import ConfirmNoCover from './ConfirmNoCover'
import { DayType } from './types'
import Edit from './Edit'
import Overview from './Overview'
import { RemoveHeader } from '../Modal'
import {
  Button,
  LabelGroup,
  LabelName,
  Select,
  Text,
} from '../Forms'


const BackgroundWrapper = styled.div`
  background: #fff;
  transform: translate(0,0)!important;
`

const RemoveModalWrapper = styled.div`
  float:left;
  margin:20px;
  background: #fff;
  width:100%;
`

const RemoveForm = styled.form`
  font-size: 0.9em;
  padding-bottom: 2rem;
`

const Row = styled.div`
  display: flex;
`

class EditDayWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      screen: 'overview',
      offDayId: null,
      reasonType: 'Sick',
      reasonDesc: '',
      showReasonInput: false,
    }
    this.handleEditExit = () => this.setState({ screen: 'overview' })
    this.handleEdit = () => this.setState({ screen: 'edit' })

    this.handleRemoveCoverModal = this.handleRemoveCoverModal.bind(this)
  }

  handleRemoveCoverModal(offDayId) {
    this.setState({ screen: 'remove_cover', offDayId });
  }

  updateReasonInputState(e, key) {    
    return this.setState({ 
      [key]: e.target.value,
      showReasonInput: e.target.length - 1 === e.target.selectedIndex
    })
  }

  updateState(e, key) {    
    return this.setState({ 
      [key]: e.target.value,
    })
  }

  render() {
    const { profileName, profileKey, date, day, handleSave, handleSaveOvertime, removeCover, getRoster, dismiss, user, sections } = this.props
    const removeCallback = () => {
      getRoster();
      dismiss();
    }

    switch (this.state.screen) {
      case 'overview':
        return (
          <Overview name={profileName} profileKey={profileKey} day={day} date={date} onEdit={this.handleEdit} handleRemoveCoverModal={this.handleRemoveCoverModal} user={user} sections={sections}/>
        )
      case 'edit':
        return (
          <Edit
            name={profileName}
            profileKey={profileKey}
            date={date}
            day={day}
            onExit={this.handleEditExit}
            onSave={handleSave}
            onSaveOvertime={handleSaveOvertime}
            getResourceNames={this.props.getResourceNames}
            refreshRoster={getRoster}
          />
        )
      case 'remove_cover':
        return (
          <BackgroundWrapper>
            <RemoveHeader title="Are you sure you want to remove cover?" onDismiss={() => this.setState({screen: 'overview'})} />
            <RemoveForm>
              <Row>
                <RemoveModalWrapper>
                    <Text disabled value={'Select a reason for removing cover'} />
                </RemoveModalWrapper>
              </Row>
              <Row>
                <LabelGroup style={{marginTop:'0'}}>
                  <LabelName>Reason type</LabelName>
                  <Select
                    value={this.state.reasonType}
                    onChange={(e) => this.updateReasonInputState(e, 'reasonType')}
                  >
                    <option>Sick</option>
                    <option>Other</option>
                  </Select>
                </LabelGroup>
              </Row>
              {this.state.showReasonInput &&
                <Row>
                  <LabelGroup style={{width:'100%'}}>
                    <LabelName>Specify reason</LabelName>
                    <Text onChange={(e) => this.updateState(e, 'reasonDesc')} />
                  </LabelGroup>
                </Row>
              }
              <Row>
                <LabelGroup>
                  <Button style={{backgroundColor: '#949292'}} onClick={() => this.setState({screen: 'overview'})}>Cancel</Button>
                </LabelGroup>
                <LabelGroup>
                  <Button onClick={(e) => {
                    e.preventDefault();
                    let reason = this.state.reasonDesc.length > 0 ? this.state.reasonDesc : this.state.reasonType
                    removeCover(this.state.offDayId, reason, removeCallback)
                  }}>Remove</Button>
                </LabelGroup>
              </Row>
            </RemoveForm>
          </BackgroundWrapper>
        )



      default:
        return null
    }
  }
}

EditDayWizard.propTypes = {
  profileName: PropTypes.string.isRequired,
  profileKey: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  day: DayType,
}

export default connect(
  function mapStateToProps(state, ownProps) {
    const { profiles } = state.entities
    const { profileName, date, profileKey } = ownProps
    const profile = profiles[profileKey]
    if (profile == null) {
      throw new Error('Cannot find profile name ' + profileName)
    }
    const day = profile.days[date]
    // TODO: fix API
    // This conveniently coerces it to a true Boolean which is useful
    // for checking when the day has changed in the Edit component.
    if (day.hasOwnProperty('coverRequired')) {
      day.coverRequired = !!day.coverRequired
    }
    return { 
      day,
      user: state.auth.user
    }
  },

  function mapDispatchToProps(dispatch, ownProps) {
    return {
      handleSave: function(profileName, profileKey, date, day, startTime, endTime, overtime, cb) {
        dispatch(
          EditDayActions.handleSave(
            profileName,
            profileKey,
            date,
            day,
            startTime,
            endTime,
            overtime,
            ownProps.dismiss,
            cb
          )
        )
      },
      removeCover: function(offDayId, reason, callback) {
        dispatch(
          EditDayActions.removeCover(
            offDayId,
            reason,
            callback
          )
        )
      },
      handleSaveOvertime: function(profileName, profileKey, date, day, overtimeType, cb) {
        dispatch(
          EditDayActions.handleSaveOvertime(
            profileName,
            profileKey,
            date,
            day,
            overtimeType,
            ownProps.dismiss,
            cb
          )
        )
      },
      getResourceNames: (shiftId, date, callback) => dispatch(EditDayActions.getResourceNames(shiftId, date, callback)),
    }
  }
)(EditDayWizard)
