import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`

const Overflow = styled.div`
  overflow: auto;
  margin-left: ${props => props.sidebarWidth};
  width: calc(100% - ${props => props.sidebarWidth});
`

class FixedSidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = { scrolled: false }
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll(ev) {
    const scrolled = ev.target.scrollLeft > 0
    if (scrolled !== this.state.scrolled) this.setState({ scrolled })
  }

  render() {
    const { scrolled } = this.state
    const { children: elementsFn, className, sidebarWidth } = this.props
    if (typeof elementsFn === 'function') {
      const { sidebar, content } = elementsFn({ scrolled })
      return (
        <Wrapper className={className}>
          {sidebar}
          <Overflow sidebarWidth={sidebarWidth} onScroll={this.handleScroll}>
            {content}
          </Overflow>
        </Wrapper>
      )
    } else return null
  }
}

FixedSidebar.defaultProps = {
  sidebarWidth: '200px',
}

FixedSidebar.propTypes = {
  /**
   * children will be called as a function and expects its return value to be
   * an object that includes keys for `sidebar` and `content`
   *
   * sidebar should be positioned absolute and the rest is up to you
   **/
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  sidebarWidth: PropTypes.string,
}

export default FixedSidebar
