import styled from 'styled-components'

import { center } from '../../Utils'
import { Colors, Palette } from '../../Themes'

const AlertCircle = styled.div`
  ${center};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : Palette.success };
  border-radius: 100%;
  color: ${Colors.white};
  cursor: pointer;
  font-size: 10px;
  height: 20px;
  opacity: ${({ hidden }) => hidden && 0};
  width: 20px;
  min-width: 20px;
  min-height: 20px;
`

export default AlertCircle
