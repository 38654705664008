import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { ModalActions, ModalSelectors } from '../Redux/ModalRedux'
import RosterModal from '../Components/RosterModal'

const mapStateToProps = (state, ownProps) => {
  const isOpen = ownProps.isOpen || ModalSelectors.isOpen(state)
  const data = ModalSelectors.getData(state)
  if (data == null) {
    return { isOpen, data, tableRows: [] }
  }

  return {
    isOpen,
    data,
    tableRows: tableRowsSelector(data),
  }
}

// TODO: extract tableRows into a memoized selector
function tableRowsSelector(data) {
  switch (data.type) {
    case 'Holiday':
      return [
        tableRow(
          'cover',
          data.cover && data.cover.name ? data.cover.name : 'No cover'
        ),
        ...shiftDayHoursHeadings(data),
        tableRow('Holiday type', data.holidayType),
      ]
    case 'Overtime':
      return [
        tableRow('Covering', data.holidayTaker),
        ...shiftDayHoursHeadings(data),
        tableRow('Overtime type', data.overtimeType),
      ]
    case 'Regular':
      return [tableRow('Shift', data.shift), ...shiftDayHoursHeadings(data)]
    default:
      return [
        tableRow(
          'Cover',
          data.cover && data.cover.name ? data.cover.name : 'No cover'
        ),
        ...shiftDayHoursHeadings(data),
      ]
  }
}

function shiftDayHoursHeadings(data) {
  const startTime = data.startTime && moment.utc(data.startTime)
  const endTime = data.endTime && moment.utc(data.endTime)

  return [
    tableRow('Day', startTime ? startTime.format('DD MMM YYYY') : '-'),
    tableRow(
      'Hours',
      startTime && endTime
        ? startTime.format('HH:mm') + ' - ' + endTime.format('HH:mm')
        : '-'
    ),
  ]
}

function tableRow(heading, value) {
  return { heading, value: value || '-' }
}

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(ModalActions.modalHide()),
})

const RosterModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterModal)

RosterModalContainer.propTypes = {
  data: PropTypes.any,
  isOpen: PropTypes.bool,
}

export default RosterModalContainer
