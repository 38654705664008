import 'normalize.css'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import App from './Containers/App'
import configureStore from './configureStore'

const store = configureStore()

const rootEl = document.getElementById('root')

render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootEl
)
;(function() {
  // Service workers are a pain to debug without any
  // measurable benefits for our situation.
  //
  // Added 2018-12-13. Likely, needed for a while since
  // it was enabled in production...
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
    })
  }
})()
