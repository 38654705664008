import { combineReducers } from 'redux'

import { default as app } from './app'
import { reducer as auth } from './AuthRedux'
import { default as basicDashboard } from './BasicDashboardRedux'
import { reducer as dashboard } from './DashboardRedux'
import { default as entities } from './entities'
import { reducer as roster } from './RosterRedux'
import { reducer as modal } from './ModalRedux'

function createReducers() {
  const appReducers = combineReducers({
    app,
    auth,
    basicDashboard,
    dashboard,
    entities,
    roster,
    modal,
  })

  return rootReducer

  function rootReducer(state, action) {
    switch (action.type) {
      case 'LOGOUT_SUCCESS':
      case 'CLEAR_DATA':
        return appReducers(undefined, action)
      default:
        return appReducers(state, action)
    }
  }
}

export default createReducers
