import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment-timezone'

import DashboardModalContainer from '../../Containers/DashboardModalContainer'
import PaginateControl from '../PaginateControl'
import Spinner from '../Spinner'
import Section from '../Section'
import AlertCircle from '../AlertCircle'
import Icon from '../Icon'
import Legend from '../Legend'
import { Colors } from '../../Themes'
import {
  abbreviate,
  center,
  getSectionColor,
  dayOfWeek,
  dayColor,
  isToday,
} from '../../Utils'

const COLUMN_WIDTH = 140
const COLUMN_PADDING = 12
const COLUMN_HEIGHT = 36

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 2rem auto;
  padding: 0px 40px;
  width: 100%;
`

const WrapperSection = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => vertical && 'column'};
  margin-bottom: 10px;
  min-width: 106px;
  overflow-y: hidden;
`

const widthStyles = containerWidth => css`
  max-width: none;
  min-width: ${containerWidth}px;
`

const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${Colors.lightGrey};
  border: ${({ days }) => days && 0};
  max-width: 100%;
  min-height: 24px;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  ${({ shadow }) => shadow && 'box-shadow: 0 0 20px rgba(0, 0, 0, 0.05)'};
  ${({ containerWidth }) => containerWidth && widthStyles(containerWidth)};
`

const Row = styled.div`
  ${center};
  justify-content: flex-start;
  border-bottom: 1px solid ${Colors.lightGrey};
  box-sizing: border-box;
  height: 40px;
  height: ${({ days }) => days && 'auto'};
  height: ${({ rowHeight }) => rowHeight && rowHeight}px;

  &:last-child {
    border: 0;
  }

  ${({ spacer }) => spacer && 'border-top: 1px;'};
`

const BorderWrap = styled.div`
  border-bottom: 1px solid ${Colors.lightGrey};
  border: ${({ border }) => !border && 0};
`

const Sidebar = styled.div`
  align-items: center;
  box-sizing: border-box;
  padding-right: 6px;
  display: flex;
  min-height: 24px;
`

const RosterText = styled.span`
  color: ${Colors.dark};
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
`

const rightBorderStyles = rightBorder => css`
  height: 80%;
  width: 100%;
  &:last-child {
    border-right: 2px solid ${rightBorder};
  }
`

const SidebarColumn = styled.div`
  ${center};
  justify-content: flex-start;
  border-right: 1px solid ${Colors.lightGrey};
  box-sizing: border-box;
  height: 100%;
  flex: ${({ flex }) => flex && 1};
  &:last-child {
    border: 0;
  }
  height: ${({ columnHeight }) => columnHeight}px;
  ${({ padding }) => padding && 'padding: 8px;'};
  ${({ noBorder }) => noBorder && 'border: 0;'};
  ${({ rightBorder }) => rightBorder && rightBorderStyles(rightBorder)};
`

const dayHeaderStyles = css`
  color: ${Colors.dark};
  font-size: 12px;
  font-weight: 300;
  padding: ${COLUMN_PADDING}px;
  box-sizing: border-box;
`

const dayDateStyles = css`
  background: ${Colors.silver};
  color: ${Colors.white};
  font-size: 10px;
  letter-spacing: 1.5;
`

const daysStyles = css`
  color: ${Colors.silver};
  font-size: 9px;
  justify-content: flex-start;
  padding-left: ${COLUMN_PADDING}px;
  min-width: 320px;
  box-sizing: border-box;
`

const dayTodayStyle = css`
  border-top: 2px solid ${Colors.silver};
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
`

const dayUsersStyle = css`
  border-right: 1px solid ${Colors.lightGrey};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  line-height: 24px;

  &:last-child {
    border-right: 0;
  }
`

const Day = styled.div`
  ${center};
  box-sizing: border-box;
  justify-content: flex-start;
  height: 100%;
  margin-right: 2px;
  min-width: ${COLUMN_WIDTH}px;
  flex: 1;
  &:last-child {
    margin-right: 0;
  }
  ${({ header }) => header && dayHeaderStyles}
  ${({ date }) => date && dayDateStyles}
  ${({ days }) => days && daysStyles}
  ${({ today }) => today && dayTodayStyle}
  ${({ users }) => users && dayUsersStyle}
`

const MonthSelect = styled(PaginateControl).attrs({ minWidth: 280 })`
  margin-left: 55px;
  min-width: 370px;
`

const DownloadIconContainer = styled.div`
  flex: auto;
  display: block;
`

const DownloadIcon = styled(Icon)`
  border: ${Colors.purple} solid 1px;
  box-sizing: content-box;
  color: ${Colors.purple};
  cursor: pointer;
  float: right;
  padding: 12px;
`

const Worker = styled.div`
  ${center};
  justify-content: space-between;
`

const WorkerContainer = styled.div`
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
`

const biggestProfilesArray = data => {
  const shifts = data.shifts.map(a => a.profiles.length)
  const index = shifts.indexOf(Math.max.apply(Math, shifts))
  const length = data.shifts[index].profiles.length
  return length > 0 ? length : 1
}

// TODO: Refactor so that moment is not initialised a zillion times
const Dashboard = ({
  loading,
  nextWeek,
  previousWeek,
  areas,
  month,
  year,
  date,
  data,
  modalShow,
  getDashboard,
  ...props
}) => (
  <Wrapper opaque reverse {...props}>
    <WrapperSection>
      <Sidebar>
        <Section colour={Colors.purple} large>
          <Section colour={Colors.white} medium noMargin>
            <Icon
              icon="dashboard"
              height={18}
              style={{ color: Colors.purple }}
            />
          </Section>
        </Section>
        <RosterText>Dashboard</RosterText>
      </Sidebar>
      <MonthSelect
        onPrevious={() => previousWeek()}
        onNext={() => nextWeek()}
        current={
          moment(date).format('DD MMM YYYY') +
          ' - ' +
          moment(date)
            .add(6, 'days')
            .format('DD MMM YYYY')
        }
      />
      <Legend/>
      {data.csvURL && (
        <DownloadIconContainer>
          <a
            href={data.csvURL}
            type="text/csv"
            download={`dashboard-${moment(date).format('YYYYMMDD')}`}
          >
            <DownloadIcon icon="download" height={14} />
          </a>
        </DownloadIconContainer>
      )}
    </WrapperSection>
    {loading ? (
      <Spinner reverse />
    ) : (
      data.sections &&
      data.sections.length > 0 && (
        <WrapperSection>
          <WrapperSection vertical>
            <Sidebar />
            <Sidebar>
              <Container fullWidth shadow>
                <BorderWrap>
                  <Row padding>
                    <SidebarColumn padding flex noBorder />
                  </Row>
                  {data.sections &&
                    data.sections.map((section, i) => (
                      <Row
                        key={i}
                        rowHeight={
                          biggestProfilesArray(section) * COLUMN_HEIGHT
                        }
                      >
                        <SidebarColumn
                          padding
                          rightBorder={getSectionColor(section.section)}
                        >
                          <Section
                            colour={getSectionColor(section.section)}
                            title={!section.sectionShortName ? `S${abbreviate(section.section)}` : abbreviate(section.sectionShortName)}
                          />
                          <Section
                            colour={getSectionColor(section.section)}
                            outline
                            title={abbreviate(section.area)}
                          />
                        </SidebarColumn>
                      </Row>
                    ))}
                </BorderWrap>
              </Container>
            </Sidebar>
          </WrapperSection>
          <WrapperSection vertical style={{ flexGrow: 1, paddingRight: 2 }}>
            <Container days fullWidth>
              <Row days>
                {[...Array(7).keys()].map(number => (
                  <Day days key={number}>
                    {dayOfWeek(
                      ...moment(date)
                        .add(number, 'days')
                        .format('YYYY-MM-DD')
                        .split('-')
                    )}
                  </Day>
                ))}
              </Row>
            </Container>
            <Container
              fullWidth
              shadow
              containerWidth={14 * COLUMN_WIDTH + 14 * (COLUMN_PADDING * 2)}
            >
              <Row>
                {[...Array(7).keys()].map(number => (
                  <React.Fragment key={number}>
                    <Day
                      header
                      today={isToday(
                        ...moment(date)
                          .add(number, 'days')
                          .format('YYYY-MM-DD')
                          .split('-')
                      )}
                    >
                      {moment(date)
                        .add(number, 'days')
                        .format('DD / MM / YYYY')}{' '}
                      - Day
                    </Day>
                    <Day
                      header
                      today={isToday(
                        ...moment(date)
                          .add(number, 'days')
                          .format('YYYY-MM-DD')
                          .split('-')
                      )}
                    >
                      {moment(date)
                        .add(number, 'days')
                        .format('DD / MM / YYYY')}{' '}
                      - Night
                    </Day>
                  </React.Fragment>
                ))}
              </Row>
              <BorderWrap>
                {data.sections &&
                  data.sections.map((section, i) => (
                    <Row
                      key={i}
                      rowHeight={biggestProfilesArray(section) * COLUMN_HEIGHT}
                    >
                      {section.shifts.map((shift, i) => (
                        <Day key={i} users>
                          <WorkerContainer>
                            {shift.profiles.map((profile, i) => (
                              <Worker key={i}>
                                <div>{shiftName(profile)}</div>
                                {showAlert(profile) && (
                                  <AlertCircle
                                    backgroundColor={dayColor(profile)}
                                    onClick={() => modalShow(Object.assign({}, profile, {
                                      shift: shift
                                    }))}
                                  >
                                    !
                                  </AlertCircle>
                                )}
                                {profile.coverName && (
                                  <AlertCircle
                                    backgroundColor={dayColor(profile)}
                                    onClick={() => modalShow(Object.assign({}, profile, {
                                      shift: shift
                                    }))}
                                  >
                                    C
                                  </AlertCircle>
                                )}
                              </Worker>
                            ))}
                          </WorkerContainer>
                        </Day>
                      ))}
                    </Row>
                  ))}
              </BorderWrap>
            </Container>
          </WrapperSection>
        </WrapperSection>
      )
    )}
    <DashboardModalContainer getDashboard={getDashboard} date={date} />
  </Wrapper>
)

function shiftName(profile) {
  if (profile.coverRequired) {
    return profile.cover == null ? '-' : profile.cover.name
  }
  if (profile.hasOwnProperty('showName')) {
    return profile.showName ? profile.name : '-'
  }
  return profile.name
}

function showAlert(profile) {
  return profile.hasOwnProperty('cover') && profile.cover == null
}

export default Dashboard
