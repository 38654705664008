import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { get } from 'lodash'

// Types and action creators
const { Types, Creators } = createActions({
  loginRequest: ['username', 'password'],
  loginSuccess: ['user', 'accessToken', 'expiresIn'],
  loginFailure: ['error'],
  refreshRequest: ['accessToken'],
  refreshSuccess: ['user', 'accessToken', 'expiresIn'],
  refreshFailure: ['error'],
  logoutRequest: null,
  logoutSuccess: null,
  logoutFailure: ['error'],
  resetPasswordRequest: ['username'],
  resetPasswordSuccess: null,
  resetPasswordFailure: ['error'],
  clearData: null,
})

export const AuthTypes = Types
export const AuthActions = Creators

// Initial state
export const INITIAL_STATE = Immutable({
  expiresIn: null,
  isAuthenticated: false,
  loginError: null,
  loginFetching: false,
  logoutError: null,
  logoutFetching: false,
  resetPasswordComplete: false,
  resetPasswordError: null,
  resetPasswordFetching: false,
  accessToken: null,
  user: null,
})

// Selectors
export const AuthSelectors = {
  holidayTypes: state => get(state, 'auth.user.holidayTypes'),
  id: state => get(state, 'auth.user.id'),
  isAuthenticated: state => get(state, 'auth.isAuthenticated'),
  isLoggedIn: state => get(state, 'auth.accessToken') !== null,
  loginError: state => get(state, 'auth.loginError'),
  loginFailed: state => get(state, 'auth.loginError') !== null,
  loginFetching: state => get(state, 'auth.loginFetching'),
  logoutError: state => get(state, 'auth.logoutError'),
  logoutFailed: state => get(state, 'auth.logoutError') !== null,
  name: state => get(state, 'auth.user.name'),
  resetPasswordComplete: state => get(state, 'auth.resetPasswordComplete'),
  resetPasswordError: state => get(state, 'auth.resetPasswordError'),
  resetPasswordFailed: state => get(state, 'auth.resetPasswordError') !== null,
  role: state => get(state, 'auth.user.role'),
  team: state => get(state, 'auth.user.team'),
  accessToken: state => get(state, 'auth.accessToken'),
  user: state => get(state, 'auth.user'),
}

// Reducers
export const loginRequest = state => state.merge({ loginFetching: true })
export const loginSuccess = (state, { user, accessToken, expiresIn }) =>
  state.merge({
    isAuthenticated: true,
    loginFetching: false,
    loginError: null,
    user,
    accessToken,
    expiresIn,
  })
export const loginFailure = (state, { error }) =>
  state.merge({ loginFetching: false, loginError: error })
export const logoutRequest = state => state.merge({ logoutFetching: true })
export const logoutSuccess = state => state.merge(INITIAL_STATE)
export const logoutFailure = (state, { error }) =>
  state.merge({ logoutFetching: false, logoutError: error })
export const resetPasswordRequest = state =>
  state.merge({ resetPasswordFetching: true })
export const resetPasswordSuccess = state =>
  state.merge({ resetPasswordFetching: false, resetPasswordComplete: true })
export const resetPasswordFailure = (state, { error }) =>
  state.merge({ resetPasswordFetching: false, resetPasswordError: error })

// Hookup Reducers To Types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.REFRESH_REQUEST]: loginRequest,
  [Types.REFRESH_SUCCESS]: loginSuccess,
  [Types.REFRESH_FAILURE]: loginFailure,
  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,
  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
})
