import styled from 'styled-components'
import React from 'react'
import { Colors } from '../../Themes'
import AlertCircle from '../AlertCircle'

const LegendItem = styled.div`
  display: inline-flex;
  padding: 0 1em;
`
const LegendWrap = styled.div`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`

const LegendExplain = styled.div`
  padding-left: 0.5em;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

function Legend(props) {
  return (
    <LegendWrap>
      <LegendItem>
        <AlertCircle backgroundColor={Colors.red}>!</AlertCircle>
        <LegendExplain>Cover Required</LegendExplain>
      </LegendItem>
      <LegendItem>
        <AlertCircle backgroundColor={Colors.navyBlue}>!</AlertCircle>
        <LegendExplain>Partial Attendance</LegendExplain>
      </LegendItem>
      <LegendItem>
        <AlertCircle backgroundColor={Colors.cyan}>!</AlertCircle>
        <LegendExplain>Cover Not Required</LegendExplain>
      </LegendItem>
      <LegendItem>
        <AlertCircle backgroundColor={Colors.cyan}>C</AlertCircle>
        <LegendExplain>Cover in Place</LegendExplain>
      </LegendItem>
    </LegendWrap>
  )
}

export default Legend
