import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { get } from 'lodash'

// Types and action creators
const { Types, Creators } = createActions({
  modalShow: ['data'],
  modalHide: null,
})

export const ModalTypes = Types
export const ModalActions = Creators

// Initial state
export const INITIAL_STATE = Immutable({
  data: null,
  modalOpen: false,
})

// Selectors
export const ModalSelectors = {
  isOpen: state => get(state, 'modal.modalOpen'),
  getData: state => get(state, 'modal.data'),
}

// Reducers
export const show = (state, { data }) => state.merge({ modalOpen: true, data })
export const hide = state => state.merge({ modalOpen: false })

// Hookup reducers to types
export const reducer = createReducer(INITIAL_STATE, {
  [Types.MODAL_SHOW]: show,
  [Types.MODAL_HIDE]: hide,
})
