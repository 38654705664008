import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { utc as moment } from 'moment-timezone'

import { ModalActions, ModalSelectors } from '../Redux/ModalRedux'
import DashboardModal from '../Components/DashboardModal'

import { actions as EditDayActions } from '../Redux/editDay'
import Edit from '../Components/DashboardModal/Edit'

const mapStateToProps = (state, { isOpen }) => {
  const data = ModalSelectors.getData(state)
  if (data == null) {
    return { isOpen, data, tableRows: [] }
  }

  return {
    isOpen: isOpen || ModalSelectors.isOpen(state),
    data,
    tableRows: tableRowsSelector(data),
  }
}

// TODO: extract tableRows into a memoized selector
function tableRowsSelector(data) {
  const startTime = data.startTime && moment(data.startTime)
  const endTime = data.endTime && moment(data.endTime)
  const noCover = !data.coverName
  const coverStartTime = !noCover && data.cover.startTime && moment(data.cover.startTime)
  const coverEndTime = !noCover && data.cover.endTime && moment(data.cover.endTime)
  const dateString = data.shift && data.shift.date && moment(data.shift.date)

  return [
    { heading: 'Cover', value: data.coverName || 'No cover', offDayId: noCover ?  null : data.cover.overtimeId },
    { heading: 'Overtime Type', value: data.overtimeType || '-' },
    {
      heading: 'Day',
      value: dateString ? dateString.format('DD MMM YYYY') : '-',
    },
    {
      heading: 'Hours',
      value: noCover ? (
        startTime && endTime ? 
        startTime.format('HH:mm') + ' - ' + endTime.format('HH:mm') : '-'
      ) : (
        coverStartTime && coverEndTime ? 
        coverStartTime.format('HH:mm') + ' - ' + coverEndTime.format('HH:mm') : '-'
      )
    },
    { heading: 'Covering', value: data.name || '-' },
    { heading: 'Holiday Type', value: data.holidayType || '-' },
  ]
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => dispatch(ModalActions.modalHide()),
  getBidList: (offDayId, callback) => dispatch(EditDayActions.getBidList(offDayId, callback)),
  removeCover: function(offDayId, reason, callback) {
    dispatch(
      EditDayActions.removeCover(
        offDayId,
        reason,
        callback
      )
    )
  },
  getResourceNames: (shiftId, date, callback) => dispatch(EditDayActions.getResourceNames(shiftId, date, callback)),
  updateDashboard: function(offDayId, date, type, coverRequired, shiftId, holidayType, startTime, endTime, overtime, cb) {
    dispatch(
      EditDayActions.updateDashboard(
        offDayId,
        date,
        type,
        coverRequired,
        shiftId,
        holidayType,
        startTime,
        endTime,
        overtime,
        cb
      )
    )
  }
})

const DashboardModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardModal)

DashboardModalContainer.propTypes = {
  data: PropTypes.any,
  isOpen: PropTypes.bool,
}

export default DashboardModalContainer
