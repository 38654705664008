import React from 'react'
import styled from 'styled-components'
import { Button } from '../Forms'
import Icon from '../Icon'
import { clearFix } from '../../Utils'
import { Palette } from '../../Themes'

const Root = styled.header`
  border-bottom: 1px solid ${Palette.contentDivider};
  height: 70px;
  line-height: 70px;

  ${clearFix}
`

const Title = styled.h2`
  font-size: 20px;
  font-weight: normal;
  margin: 0 2rem;
  float: left;
  width: 210px;
`

const DismissWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 1rem;
`

function EditHeader(props) {
  const { onDismiss, title } = props
  return (
    <Root>
      <Title>{title}</Title>
      {typeof onDismiss === 'function' && (
        <DismissWrapper>
          <Button onClick={onDismiss} unstyled stretch={false}>
            <Icon icon="close" />
          </Button>
        </DismissWrapper>
      )}
    </Root>
  )
}

export default EditHeader
