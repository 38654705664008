import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Palette } from '../../Themes'

const Text = styled.input.attrs({
  type: 'text',
})`
  color: ${Palette.inputText};
  box-sizing: border-box;
  padding: 1em 0.5em;
  background-color: ${Palette.inputBackground};
  border: 1px solid ${Palette.inputBorder};
  border-radius: 2px;
  width: 100%;

  &:disabled {
    border-color: ${Palette.inputBackground};
  }
`

Text.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
}

export default Text
