import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled from 'styled-components'
import DayKey from './DayKey'
import { Content, OverviewHeader } from '../Modal'
import InlineButton from '../Forms/InlineButton'
import Icon from '../Icon'
import SimpleTable, { SimpleTableSpecial, Value } from '../SimpleTable'

const Center = styled.div`
  text-align: center;
`

function Overview(props) {
  const { name, day, onEdit, user, onBidList, handleRemoveModal, handleRemoveCoverModal, sections, date } = props

  return (
    <>
      <OverviewHeader
        title={day.type === 'Regular' ? day.shift + ' shift' : day.type}
        onEdit={editType(day.type) && onEdit}
        user={user}
        onBidList={onBidList ? onBidList : null}
        day={day}
      />
      <Center>
        <DayKey {...day} sections={sections} />
      </Center>
      <Content>
        <SimpleTableSpecial
          rows={createTableRows(name, day, user.role, handleRemoveModal, handleRemoveCoverModal, props.profileKey ? date : day.date)}
          valueDecorator={valueDecorator}
        />
      </Content>
    </>
  )
}

Overview.propTypes = {
  name: PropTypes.string,
  day: PropTypes.shape({
    area: PropTypes.string,
    team: PropTypes.string,
    type: PropTypes.string,
    section: PropTypes.string
  }).isRequired,
}

function editType(type) {
  return (
    type === 'Regular' ||
    type === 'Holiday' ||
    type === 'Training' ||
    type === 'Union' ||
    type === 'Sick' ||
    type === 'Maintenance' ||
    type === 'Special Cleaning' ||
    type === 'Changeover' ||
    type === 'Bereavement' ||
    type === 'Emergency' ||
    type === 'Paternity' ||
    type === 'Maternity' ||
    type === 'Phase Return' ||
    type === 'Medical Appointment' ||
    type === 'Jury Service' ||
    type === 'Parental Leave' ||
    type === 'Projects' ||
    type === 'Community Hours' ||
    type === 'Overtime' ||
    type === 'Other'
  )
}

function removeResource(name, overtimeId, handleRemoveModal) {
  return (
    <React.Fragment>
      {name}
      <InlineButton onClick={() => {
        handleRemoveModal(overtimeId)
      }} unstyled stretch={false}>
        <Icon icon="close" />
      </InlineButton>
    </React.Fragment>
  )
}

function removeCover(name, offDayId, handleRemoveCoverModal, userRole) {
  if (userRole === 'admin') {
    return (
      <React.Fragment>
        {name}
        <InlineButton onClick={() => {
          handleRemoveCoverModal(offDayId)
        }} unstyled stretch={false}>
          <Icon icon="close" />
        </InlineButton>
      </React.Fragment>
    )
  } else {
    return name
  }
}

function valueDecorator(row) {
  if (row.heading === 'Cover' && row.value === 'No cover') {
    return <Value red>NO COVER</Value>
  }
  return <Value>{row.value}</Value>
}

function createTableRows(profileName, day, userRole, handleRemoveModal, handleRemoveCoverModal, date) {
  const startTime = moment.utc(day.startTime)
  const endTime = moment.utc(day.endTime)

  const dateString = moment.utc(date)

  const overtimeResources = []
  if (day.hasOwnProperty('overtimes')) {
    for (let i = 0; i < day.techsRequired; i++) {
      const resourceNo = i + 1
      overtimeResources.push(tableRow('Resource ' + resourceNo, 'NO RESOURCE'))
      overtimeResources.push(tableRow('Overtime Type ' + resourceNo, '-'))
    }
    day.overtimes.reduce((acc, x, i) => {
      const resourceNo = i + 1
      const startI = i * 2
    acc[startI] = tableRow('Resource ' + resourceNo, userRole == 'admin' ? removeResource(x.name, x.id, handleRemoveModal) : x.name)
      acc[startI + 1] = tableRow('Overtime Type ' + resourceNo, userRole !== 'basic' ? x.overtimeType : '-')
      return acc
    }, overtimeResources)
  }

  return [
    profileName && tableRow('Name', day.hasOwnProperty('holidayTaker') && userRole !== 'basic' ? removeCover(profileName, day.id, handleRemoveCoverModal, userRole) : profileName),
    day.hasOwnProperty('holidayType') &&
      tableRow('Holiday type', day.holidayType),
    tableRow('Day', dateString.format('DD MMM YYYY')),
    tableRow(
      'Hours',
      startTime.format('HH:mm') + ' - ' + endTime.format('HH:mm')
    ),
    day.hasOwnProperty('holidayTaker') &&
      tableRow('Covering for', day.holidayTaker),
    day.hasOwnProperty('overtimeType') &&
      tableRow('Overtime type', day.overtimeType),
    day.hasOwnProperty('cover') &&
      day.coverRequired &&
      tableRow(
        'Cover',
        day.cover && day.cover.name && userRole !== 'basic'
          ? removeCover(day.cover.name, day.cover.overtimeId, handleRemoveCoverModal, userRole)
          : userRole === 'basic' ? '-' : 'No cover'
      ),
    ...overtimeResources,
  ].filter(Boolean)
}

function tableRow(heading, value) {
  return { heading, value }
}

export default Overview
