import React from 'react'
import styled from 'styled-components'

import Icon from '../Icon'
import { Button, Text } from '../Forms'
import { Colors } from '../../Themes'
import { clearFix } from '../../Utils'

// TODO: these are strictly mapped to the database values.
// BE CAREFUL WHEN THE DATABASE IS REMADE AS THIS WILL BREAK
// Needs to have the API tell the client what is available to choose

const Row = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 1fr 1fr;
  grid-column-gap: 0px;
`

const Header = styled.header`
  height: 64px;
  line-height: 64px;

  ${clearFix}
`

const ModalTitle = styled.h2`
  font-size: 20px;
  font-weight: normal;
  margin: 0 2rem;
  float: left;
  width: calc(100% - 4rem);
  text-align: center;
  padding: 12px 0;
`

const Form = styled.form`
  font-size: 0.9em;
  padding: 60px;
  padding-top:25px;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 24px;
`

const IconWrapper = styled.div`
  position:relative;
`

const acceptedBidColour = '#49D1B3'
const columnHeaderStyle = {
  padding: '20px auto',
  fontStyle: 'normal',
  lineHeight: '14px' 
}

function rowStyle(status) {
  return { border:'none', borderRadius:'0', padding: '20px auto', background: status.toLowerCase() === 'won' ? acceptedBidColour : 'none'}
}

const bidStatus = {
  'pending' : 'No bid',
  'accepted': 'Bid received',
  'declined': 'Declined',
  'won': 'Accepted'
}

const borderStyle = {
  borderTop: '1px solid #DCDFE4',
  margin: 0
}

class BidList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { bids, onClose } = this.props

    return (
      <>
        <Header>
          <CloseButtonWrapper>
            <Button onClick={onClose} unstyled stretch={false}>
              <Icon icon="left" />
            </Button>
          </CloseButtonWrapper>
          <ModalTitle style={{width: 'calc(100% - 4rem)', textAlign: 'center'}}>BID LIST</ModalTitle>
        </Header>
        <Form>
          <Row style={borderStyle}>
            <Text style={columnHeaderStyle} disabled value={`#`} />
            <Text style={columnHeaderStyle} disabled value={`NAME`} />
            <Text style={columnHeaderStyle} disabled value={`STATUS`} />
            <Text style={columnHeaderStyle} disabled value={`NOTIFICATION`} />
          </Row>
          {bids.map((item, index) => {
            return (
            <Row style={borderStyle}>
              <Text style={rowStyle(item.status)} disabled value={`${index + 1}`} />
              <Text style={rowStyle(item.status)} disabled value={`${item.name}`} />
              <Text style={rowStyle(item.status)} disabled value={`${bidStatus[item.status.toLowerCase()]}`} />
              {item.notificationSent && 
                <IconWrapper style={{ background: item.status.toLowerCase() === 'won' ? acceptedBidColour : 'none' }}>
                  <Icon style={{ marginTop: '14px', marginLeft: '88px'}} icon="tick" />
                </IconWrapper>
              }
            </Row>
            )
          })}          
        </Form>
      </>
    )
  }
}

BidList.propTypes = {
}

export default BidList
