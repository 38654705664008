import styled, { keyframes } from 'styled-components'

import { Colors } from '../../Themes'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const borderWidth = 0.2
const size = 1

const Spinner = styled.div`
  border: ${borderWidth}em solid;
  border-color: ${({ reverse }) => (reverse ? Colors.purple : Colors.white)};
  border-bottom-color: transparent;
  border-radius: 50%;
  margin: 0 auto;
  width: ${size}em;
  height: ${size}em;
  animation: ${spin} 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -${borderWidth + size / 2}em;
  margin-left: -${borderWidth + size / 2}em;
`

export default Spinner
