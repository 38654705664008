import styled from 'styled-components'
import { Palette } from '../../Themes'

const MonthTableDay = styled.td.attrs({
  borderColor: Palette.cellBorder,
})`
  box-sizing: border-box;
  font-size: 10px;
  height: 38px;
  min-width: 38px;
  border-color: ${props => props.borderColor};
  border-style: solid;
  border-width: 0 1px 1px 0;
  text-align: center;
  background-color: white;

  /* top row */
  tr:first-child & {
    border-width: 1px 1px 1px 0;
  }

  /* first cell in top row */
  tr:first-child &:first-child {
    border-width: 1px 1px 1px 1px;
  }

  /* first column */
  &:first-child {
    border-width: 0 1px 1px 1px;
  }

  /* first cell in last row */
  tr:last-child &:first-child {
    border-bottom-left-radius: 4px;
  }

  /* last cell in last row */
  tr:last-child &:last-child {
    border-bottom-right-radius: 4px;
  }
`

export default MonthTableDay
