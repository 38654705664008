import { call, put } from 'redux-saga/effects'
import { DashboardActions } from '../Redux/DashboardRedux'

/**
 * Dashboard saga.
 *
 * @export
 * @param {any} api
 * @param {any} action
 */
export function* dashboard(api, action) {
  try {
    const { date } = action
    const response = yield call([api, api.dashboard], date)
    if (response.ok) {
      yield put(DashboardActions.dashboardSuccess(response.data, date))
    } else {
      throw new Error('Bad response')
    }
  } catch (error) {
    yield put(DashboardActions.dashboardFailure(error))
  }
}
