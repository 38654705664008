import { NavLink as ReactRouterNavLink } from 'react-router-dom'
import styled from 'styled-components'
import { commonLinkStyles } from './commonLinkStyles'
import { navLinkUnderbarOverhang } from './measures'

const SettingsLink = styled(ReactRouterNavLink)`
  ${commonLinkStyles}

  margin-right: ${40 - navLinkUnderbarOverhang}px;

  &::after {
    background-color: #fff;
  }
`

export default SettingsLink
