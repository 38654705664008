import * as React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import RosterModalContainer from './RosterModalContainer'
import BasicDashboard from '../Components/BasicDashboard'
import Spinner from '../Components/Spinner'
import { actions } from '../Redux/BasicDashboardRedux'
import { ModalActions } from '../Redux/ModalRedux'
import { getUTCYearMonthIndex, utcDate } from '../Utils/time'

class BasicDashboardContainer extends React.Component {
  constructor(props) {
    super(props)

    this.previousMonth = this.monthRelative(-1)
    this.nextMonth = this.monthRelative(1)
  }

  componentDidMount() {
    const { year, monthIndex } = this.props.date
    this.props.fetchSections(year, monthIndex)
  }

  monthRelative(offset) {
    return () => {
      const { year, monthIndex } = this.props.date
      const nextDate = utcDate(year, monthIndex + offset)

      const {
        year: nextYear,
        monthIndex: nextMonthIndex,
      } = getUTCYearMonthIndex(nextDate)
      this.props.fetchSections(nextYear, nextMonthIndex)
    }
  }

  render() {
    if (this.props.isFetching && this.props.sections == null) {
      return (
        <>
          <Helmet title="Dashboard" />
          <Spinner reverse />
        </>
      )
    }

    if (this.props.error == null) {
      return (
        <>
          <Helmet title="Dashboard" />
          <BasicDashboard
            previousMonth={this.previousMonth}
            nextMonth={this.nextMonth}
            date={utcDate(this.props.date.year, this.props.date.monthIndex)}
            sections={this.props.sections}
            modalShowHandler={this.props.modalShow}
          />
          <RosterModalContainer />
        </>
      )
    }

    return <p>There was a problem fetching the section data</p>
  }
}

export default connect(
  function mapStateToProps(state, ownProps) {
    const { isFetching, error, sections, date } = state.basicDashboard
    return { isFetching, error, sections, date }
  },
  {
    fetchSections: actions.fetchSections,
    modalShow: data => ModalActions.modalShow(data.day),
  }
)(BasicDashboardContainer)
