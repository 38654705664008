import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import AlertCircle from '../AlertCircle'
import Section from '../Section'
import Edit from './Edit'
import { abbreviate, getSectionColor, dayColor } from '../../Utils'
import { StyledReactModal, Content, Title, Row, SectionWrap } from '../Modal'
import SimpleTable, { Value } from '../SimpleTable'
import { List, Pencil } from '../Icons'
import { Palette } from '../../Themes'
import { RemoveHeader } from '../Modal'
import {
  Button,
  LabelGroup,
  LabelName,
  Select,
  Text,
} from '../Forms'

import InlineButton from '../Forms/InlineButton'
import Icon from '../Icon'

import BidList from './BidList'

const iconSize = 22

const BidListIcon = styled(List)`
  fill: ${Palette.interfaceText};
  height: 22px;
  width: 22px;
  cursor: pointer;
  margin-right: 4px;
`

const RemoveModalWrapper = styled.div`
  float:left;
  margin:20px;
  background: #fff;
  width:100%;
`

const RemoveForm = styled.form`
  font-size: 0.9em;
  padding-bottom: 2rem;
`

const RemoveCoverRow = styled.div`
  display: flex;
`

const EditIcon = styled(Pencil)`
  fill: ${Palette.interfaceText};
  height: ${iconSize}px;
  width: ${iconSize}px;
  cursor: pointer;
`

class DashboardModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      screen: 'overview',
      bidList: [],
      resourceNames: [],
      offDayId: null,
      reasonType: 'Sick',
      reasonDesc: '',
      showReasonInput: false,
    }
    
  }

  showBidList = () => {
    this.setState({ screen: 'bidList' })
  }

  showEdit = () => {
    this.setState({ screen: 'edit' })
  }

  handleBidListClose = () => {
    this.setState({ screen: 'overview' })
  }

  showRemoveCover = (offDayId) => {
    this.setState({ screen: 'removeCover', offDayId })
  }

  handleRemoveCoverClose = () => {
    const { getDashboard, date, onClose } = this.props
    onClose()
    this.setState({ screen: 'overview', reasonType: 'Sick', showReasonInput: false })
    getDashboard(date)
  }

  handleCloseEdit = () => {
    const { getDashboard, date, onClose } = this.props
    onClose()
    this.setState({ screen: 'overview' })
    getDashboard(date)
  }

  setNewBidList = (bidList) => {
    this.setState({ bidList })
  }
  
  setNewResourceNames = (resourceNames) => {
    this.setState({ resourceNames })
  }

  updateReasonInputState(e, key) {    
    return this.setState({ 
      [key]: e.target.value,
      showReasonInput: e.target.length - 1 === e.target.selectedIndex
    })
  }

  updateState(e, key) {    
    return this.setState({ 
      [key]: e.target.value,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      if (nextProps.isOpen) {
        this.props.getBidList(nextProps.data.offDayId, this.setNewBidList)
        this.props.getResourceNames({shiftId: nextProps.data.shiftId, date: nextProps.data.shift.date}, this.setNewResourceNames)
      }
    }
  }

  removeCoverAction(name, offDayId, handleRemoveCoverModal, userRole) {
    return (
      <React.Fragment>
        {name}
        <InlineButton onClick={() => {
          handleRemoveCoverModal(offDayId)
        }} unstyled stretch={false}>
          <Icon icon="close" />
        </InlineButton>
      </React.Fragment>
    )
  }

  render() {
    const { data, tableRows, onClose, removeCover, date } = this.props

    switch (this.state.screen) {
      case 'overview':
        return (
          <StyledReactModal onRequestClose={onClose} ariaHideApp={false} {...this.props}>
            {data && (
              <Content>
                <Row right>
                  <EditIcon onClick={this.showEdit} />
                  { this.state.bidList.length > 0 &&
                    <BidListIcon onClick={this.showBidList} />
                  }
                  {!data.coverName &&
                    <AlertCircle backgroundColor={dayColor(data)} hidden={data.coverName}>
                      !
                    </AlertCircle>
                  }
                </Row>
                <Row>
                  <Title>Cover</Title>
                </Row>
                <SectionWrap>
                  <Section
                    medium
                    colour={getSectionColor(data.section)}
                    title={`S${data.section}`}
                  />
                  <Section
                    medium
                    outline
                    colour={getSectionColor(data.section)}
                    title={abbreviate(data.area)}
                  />
                  <Section
                    medium
                    outline
                    colour={getSectionColor(data.section)}
                    title={data.team}
                  />
                </SectionWrap>
                <SimpleTable showRemoveCover={this.showRemoveCover} removeCoverAction={this.removeCoverAction} rows={tableRows}/>
              </Content>
            )}
          </StyledReactModal>
        )
      case 'bidList':
        return (
          <StyledReactModal onRequestClose={() => { onClose(); this.handleBidListClose()}} ariaHideApp={false} {...this.props}>
            <BidList onClose={this.handleBidListClose} bids={data && this.state.bidList} />
          </StyledReactModal>
        )
      case 'removeCover':
        return (
          <StyledReactModal onRequestClose={() => { onClose(); this.setState({ screen: 'overview' })}} ariaHideApp={false} {...this.props}>
            <RemoveHeader title="Are you sure you want to remove cover?" onDismiss={() => { this.setState({ screen: 'overview' })}} />
            <RemoveForm>
              <RemoveCoverRow>
                <RemoveModalWrapper>
                    <Text disabled value={'Select a reason for removing cover'} />
                </RemoveModalWrapper>
              </RemoveCoverRow>
              <RemoveCoverRow>
                <LabelGroup style={{marginTop:'0'}}>
                  <LabelName>Reason type</LabelName>
                  <Select
                    value={this.state.reasonType}
                    onChange={(e) => this.updateReasonInputState(e, 'reasonType')}
                  >
                    <option>Sick</option>
                    <option>Other</option>
                  </Select>
                </LabelGroup>
              </RemoveCoverRow>
              {this.state.showReasonInput &&
                <RemoveCoverRow>
                  <LabelGroup style={{width:'100%'}}>
                    <LabelName>Specify reason</LabelName>
                    <Text onChange={(e) => this.updateState(e, 'reasonDesc')} />
                  </LabelGroup>
                </RemoveCoverRow>
              }
              <RemoveCoverRow>
                <LabelGroup>
                  <Button style={{backgroundColor: '#949292'}} onClick={() => this.setState({screen: 'overview'})}>Cancel</Button>
                </LabelGroup>
                <LabelGroup>
                  <Button onClick={(e) => {
                    e.preventDefault();
                    let reason = this.state.reasonDesc.length > 0 ? this.state.reasonDesc : this.state.reasonType
                    removeCover(this.state.offDayId, reason, this.handleRemoveCoverClose)
                  }}>Remove</Button>
                </LabelGroup>
              </RemoveCoverRow>
            </RemoveForm>
          </StyledReactModal>
        )
      case 'edit':
        return(
          <StyledReactModal onRequestClose={() => { onClose(); this.handleBidListClose()}} ariaHideApp={false} {...this.props}>
             <RemoveHeader title="Edit cover" onDismiss={() => { this.setState({ screen: 'overview' })}} />
             <Edit onClose={this.handleCloseEdit.bind(this)} resourceNames={data && this.state.resourceNames} {...data}  tableRows={tableRows} updateDashboard={this.props.updateDashboard} />
          </StyledReactModal>
        )
      default:
        return null
    }
  }
}

DashboardModal.propTypes = {
  data: PropTypes.any,
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      heading: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ),
  onClose: PropTypes.func.isRequired,
  getBidList: PropTypes.func.isRequired,
  getResourceNames: PropTypes.func.isRequired,
  removeCover: PropTypes.func.isRequired
}

export default DashboardModal
