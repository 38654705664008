import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import { StartupActions } from './Redux/StartupRedux'
import createRootReducer from './Redux'
import rootSaga from './Sagas'
import API from './Services/Api'

const api = API.create()

function configureStore(initialState) {
  const composeEnhancer = composeWithDevTools({
    serialize: {
      options: true,
    },
    actionSanitizer: action => {
      if (action.error) {
        return {
          ...action,
          type: '[ERROR] ' + action.type,
        }
      }
      return action
    },
  })
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    createRootReducer(),
    initialState,
    composeEnhancer(
      applyMiddleware(thunkMiddleware.withExtraArgument(api), sagaMiddleware)
    )
  )

  sagaMiddleware.run(rootSaga(api))
  store.dispatch(StartupActions.startup())

  return store
}

export default configureStore
