import React from 'react'
import styled from 'styled-components'
import { Button, LabelGroup, LabelName, Select, Text } from '../Forms'
import { EditHeader } from '../Modal'
import { Colors } from '../../Themes'
import moment from 'moment-timezone'
//import { threadId } from 'worker_threads'

const Row = styled.div`
  display: flex;
`

const Form = styled.form`
  font-size: 0.9em;
  min-height: 280px;
  padding-bottom: 3rem;
`

const Rule = styled.hr`
  width: calc(100% - 4rem);
  border: 1px solid ${Colors.lightGrey};
  border-width: 1px 0 0 0;
  margin-top: 2rem;
`

const OvertimesContainer = styled.div`
  position: relative;
`

const OvertimesDisabled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: -2rem;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  height: 150px;
`

const OvertimesDisabledMessage = styled.p`
  margin: 2rem 3rem;
  font-size: 1.2em;
  line-height: 1.5;
`

const FullWidth = styled.div`
  margin-top: 1.5rem;
  margin-left: 2rem;
  width: calc(100% - 64px);
`

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.resetFormData()

    this.updateResourceName = this.updateResourceName.bind(this)
    this.updateOvertimeType = this.updateOvertimeType.bind(this)
    this.updateReason = this.updateReason.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.day !== prevProps.day) {
      this.setState(this.resetFormData())
    }
  }

  componentWillMount() {
    this.props.getResourceNames({shiftId: this.props.day.shiftId, date: this.props.day.date}, this.setNewResourceNames.bind(this))
    let resourceNameList = new Array(this.state.overtimes.length).fill('')
    this.state.overtimes.map((ot, index) => {
      resourceNameList[index] = {shiftId: ot.shiftId, name: ot.name}
    })
    let overtimeTypeList = new Array(this.state.overtimes.length).fill('')
    this.state.overtimes.map((ot, index) => {
      overtimeTypeList[index] = ot.overtimeType
    })
    let reasonList = new Array(this.state.overtimes.length).fill('')
    this.setState({ resourceNameList, overtimeTypeList, reasonList })
  }

  setNewResourceNames(resourceNames) {
    this.setState({resourceNames})
  }

  updateResourceName(index, name, shiftId) {
    const { overtimes } = this.state;
    let resourceNameList = this.state.resourceNameList;
    resourceNameList[index] = {shiftId: parseInt(shiftId), name};
    // add in default COMMS overtime type
    let overtimeTypeList = this.state.overtimeTypeList;
    overtimeTypeList[index] = 'COMMS';
    this.setState({ resourceNameList, overtimeTypeList })
    // update reason field
    let reasonList = this.state.reasonList;
    if (overtimes[index] && name !== overtimes[index].name) {
      reasonList[index] = { show: true, reason: '' }
    } else {
      reasonList[index] = { show: false, reason: '' }
    }
  }

  updateOvertimeType(index, type) {
    let overtimeTypeList = this.state.overtimeTypeList;
    overtimeTypeList[index] = type;
    this.setState({ overtimeTypeList })
  }

  updateReason(index, type) {
    let reasonList = this.state.reasonList;
    reasonList[index] = type;
    this.setState({ reasonList })
  }

  resetFormData() {
    const { day } = this.props
    return {
      overtimes: day.overtimes,
      techsRequired: '' + day.techsRequired,
      resourceNames: [],
      resourceNameList: [],
      overtimeTypeList: [],
      reasonList: []
    }
  }

  updateState(key, value = 'value') {
    return ev => this.setState({ [key]: ev.target[value] })
  }

  render() {
    const { day, onClose, onRemove, onSave, overtimeTypes } = this.props
    const { overtimes, techsRequired, overtimeType } = this.state
    const resNameListFiltered = this.state.resourceNames.filter( res => !this.state.resourceNameList.filter( list => list.shiftId === res.shiftId).length)

    return (
      <>
        <EditHeader title="Edit day" onDismiss={onClose} />
        <Form onSubmit={ev => onSave(ev, { ...day, ...this.state })}>
          
          <Row>
            <LabelGroup>
              <LabelName>Day</LabelName>
              <Text
                disabled
                defaultValue={moment(day.date).format('DD MMM YYYY')}
              />
            </LabelGroup>
            <LabelGroup>
              <LabelName>Shift</LabelName>
              <Text disabled defaultValue={day.shift} />
            </LabelGroup>
          </Row>
          <Row>
            <LabelGroup>
              <LabelName>Resources required</LabelName>
              <Select
                onChange={this.updateState('techsRequired')}
                value={techsRequired}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Select>
            </LabelGroup>
            <LabelGroup>
              <LabelName>Day type</LabelName>
              <Text disabled defaultValue={day.type} />
            </LabelGroup>
          </Row>
          <Rule />
          <Overtimes disabled={overtimes.length > techsRequired} />
         {[...Array(parseInt(this.state.techsRequired))].map((tech, index) => (
           <>
            <Row>
              <LabelGroup>
                <LabelName>Resource {index+1} name (optional)</LabelName>
                <Select
                  onChange={(e) => {
                    const selectedIndex = e.target.options.selectedIndex
                    this.updateResourceName(index, e.target.value, e.target.options[selectedIndex].getAttribute('data-shiftId'))
                  }}
                  value={this.state.resourceNameList && this.state.resourceNameList[index] && this.state.resourceNameList[index].name}
                >
                  
                  {[{id: 0, name: this.state.resourceNameList && this.state.resourceNameList[index] ? this.state.resourceNameList[index].name : 'Select cover'}, ...resNameListFiltered].map(type => (
                    <option key={type.id} data-shiftId={type.shiftId}>{type.name}</option>
                  ))}
              </Select>
              </LabelGroup>
              <LabelGroup>
                <LabelName>Resource {index+1} overtime type</LabelName>
                <Select
                  onChange={(e) => this.updateOvertimeType(index, e.target.value)}
                  value={this.state.overtimeTypeList && this.state.overtimeTypeList[index]}
                  disabled={!this.state.resourceNameList && !this.state.resourceNameList[index]}
                >
                  
                  {overtimeTypes.map(type => (
                    <option key={type}>{type}</option>
                  ))}
                </Select>
              </LabelGroup>
            </Row>
            { this.state.reasonList[index] && this.state.reasonList[index].show &&
              <Row>
                <FullWidth>
                  <LabelName>Reason for cover swap (will be sent to previous cover)</LabelName>
                  <Text value={this.state.reasonList[index].reason} onChange={(e) => this.updateReason(index, { show: true, reason: e.target.value })}/>
                </FullWidth>
              </Row>
            }
          </>
          ))}
          <Rule />
          <Row>
            <LabelGroup>
              <Button onClick={onRemove}>Delete</Button>
            </LabelGroup>
            <LabelGroup>
              <Button primary>Save</Button>
            </LabelGroup>
          </Row>
        </Form>
      </>
    )
  }
}

function Overtimes(props) {
  const { disabled } = props;
  return (
    <OvertimesContainer>
      {disabled && (
        <OvertimesDisabled>
          <OvertimesDisabledMessage>
            Resources required have dropped below what is currently allocated.
            If you save, a random person(s) will lose their overtime.
          </OvertimesDisabledMessage>
        </OvertimesDisabled>
      )}
    </OvertimesContainer>
  )
}

Edit.defaultProps = {
  overtimeTypes: [
    'COMMS',
    'PAYG',
    'TOIL'
  ],
}

export default Edit
