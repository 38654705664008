import React from 'react'
import styled from 'styled-components'
import { Palette } from '../../Themes'
import { Button } from '../Forms'
import { Pencil, List } from '../Icons'
const buttonPadding = 10
const iconSize = 22

const Title = styled.h2`
  margin: 0;
  color: ${Palette.interfaceText};
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
`

const EditWrapper = styled.div`
  position: absolute;
  right: -${buttonPadding}px;
  top: -${buttonPadding}px;
`

const BidListWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: -${buttonPadding}px;
`

const EditIcon = styled(Pencil)`
  fill: ${Palette.interfaceText};
  height: ${iconSize}px;
  width: ${iconSize}px;
`

const BidListIcon = styled(List)`
  fill: ${Palette.interfaceText};
  height: ${iconSize}px;
  width: ${iconSize}px;
`

const Root = styled.div`
  position: relative;
  margin: 2rem 2rem 1rem;
  /*padding-left: ${iconSize * 2}px;
  padding-right: ${iconSize * 2}px;*/
  text-align: center;
`

const PrimaryButton = styled(Button)`
  padding: ${buttonPadding}px;
`

function OverviewHeader(props) {
  const { title, onEdit, user, onBidList, day } = props
  return (
    <Root>
      {typeof onEdit === 'function' && (
        user.role === 'admin' ||
        (user.role === 'projectManager' && user.shiftIds.indexOf(day.shiftId) > -1) ||
        (user.role === 'engineerManager' && day.restricted === 0) ||
        (user.role === 'engineerManager' && day.restricted === 1 && user.shiftIds.indexOf(day.shiftId) > -1) ||
        (user.role === 'engineerManager' && !day.restricted && 
          (day.type === 'Maintenance' || day.type === 'Special Cleaning' || day.type === 'Changeover'))
      )
      
      && (
        <EditWrapper>
          <PrimaryButton unstyled stretch={false} onClick={onEdit}>
            <EditIcon />
          </PrimaryButton>
        </EditWrapper>
      )}
      {typeof onBidList === 'function' && user.role !== 'basic' && (
        <BidListWrapper>
          <PrimaryButton unstyled stretch={false} onClick={onBidList}>
            <BidListIcon />
          </PrimaryButton>
        </BidListWrapper>
      )}
      <Title>{title}</Title>
    </Root>
  )
}

export default OverviewHeader
