import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Table = styled.table`
  position: relative;
  table-layout: fixed;
`

const LoadingWrapper = styled.caption`
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Loading = styled.div`
  height: 1em;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

function NextTable(props) {
  const { className, head, body, foot, loading } = props
  return (
    <Table className={className}>
      {renderSimple('thead', head)}
      {renderMaybeArray('tbody', body)}
      {renderSimple('tfoot', foot)}
      {loading && (
        <LoadingWrapper>
          <Loading>loading...</Loading>
        </LoadingWrapper>
      )}
    </Table>
  )
}

NextTable.propTypes = {
  head: PropTypes.node,
  body: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  foot: PropTypes.node,
  loading: PropTypes.bool,
}

function renderSimple(Tag, children) {
  return children == null ? null : <Tag>{children}</Tag>
}

function renderMaybeArray(Tag, children) {
  return children == null ? null : Array.isArray(children) ? (
    children.map((b, i) => <Tag key={i}>{b}</Tag>)
  ) : (
    <Tag>{children}</Tag>
  )
}

export default NextTable
