import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Colors } from '../../Themes'

const NavList = styled.nav`
  padding-left: 320px;
  margin-top: 0;
  margin-bottom: 30px;
  list-style: none;
  overflow: hidden;
  line-height: 4em;
  border-bottom: 1px solid ${Colors.lightGrey};
  text-transform: uppercase;
`

const NavItem = styled(NavLink)`
  float: left;
  margin-right: 20px;
  text-decoration: none;
  color: ${Colors.purple};

  &:last-child {
    margin-right: 0;
  }
`

function SecondaryNavigation() {
  return (
    <NavList>
      <NavItem to="/settings/special-days">Special Days</NavItem>
    </NavList>
  )
}

SecondaryNavigation.propTypes = {}

export default SecondaryNavigation
