import React from 'react'

function LeftChevron(passedProps) {
  const props = {
    viewBox: '0 0 24 24',
    xmlns: 'http://www.w3.org/2000/svg',
    ...passedProps,
  }
  return (
    <svg {...props}>
      <path
        d="M14.988 4.975l-.223.26-5.582 6.533a.79.79 0 0 0 .023 1.032l5.819 6.225"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default LeftChevron
