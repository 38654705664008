import React from 'react'
import PropTypes from 'prop-types'
import { Colors } from '../../Themes'
import AppConfig from '../../Config/AppConfig'
import {
  DashboardIcon,
  Logout,
  Nav,
  NavLink,
  Root,
  SlideRight,
  SettingsIcon,
  SettingsLink,
  Title,
  UsernamePadding,
} from './styles'

function Header(props) {
  return (
    <Root>
      <Title>{AppConfig.appName}</Title>
      <Nav>
        <NavLink color={Colors.white} to="/" exact>
          <DashboardIcon />
        </NavLink>
        {props.sections.map(section => (
          <NavLink key={section.id} color={section.color} to={section.url}>
            {section.specialName === '' ? section.shortName : section.specialName}
          </NavLink>
        ))}
      </Nav>
      <SlideRight>
        <UsernamePadding name={props.user.name} userRole={props.user.role} />
        {(props.user.role === 'admin' || props.user.role === 'basic' || props.user.role === 'projectManager' || props.user.role === 'engineerManager') && (
          <SettingsLink to="/settings/special-days" exact>
            <SettingsIcon />
          </SettingsLink>
        )}
        <Logout onClick={props.logout}>Logout</Logout>
      </SlideRight>
    </Root>
  )
}

Header.propTypes = {
  logout: PropTypes.func,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string
  }),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      shortName: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
}

export default Header
