import PropTypes from 'prop-types'

const OffDayType = PropTypes.oneOf([
  'Holiday',
  'Training',
  'Union',
  'Sick',
  'Maintenance',
  'Special Cleaning',
  'Changeover',
  'Bereavement',
  'Emergency',
  'Paternity',
  'Maternity',
  'Phase Return',
  'Medical Appointment',
  'Jury Service',
  'Parental Leave',
  'Projects',
  'Community Hours',
  'Other'

])
const ShiftType = PropTypes.oneOf(['Rest', 'Day', 'Night'])
const OverTimeType = PropTypes.oneOf(['PAYG', 'COMMS', 'TOIL'])

const CoverType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  overtimeType: OverTimeType.isRequired,
  overtimeId: PropTypes.number.isRequired,
})

const OffDayShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  shift: ShiftType,
  shiftId: PropTypes.number.isRequired,
  offDayId: PropTypes.number.isRequired,
  shift: ShiftType.isRequired,
  type: OffDayType.isRequired,
  team: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  zone: PropTypes.string.isRequired,
  coverRequired: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool])
    .isRequired,
  cover: CoverType,
  restricted: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool])
    .isRequired,
})

const RegularDayType = PropTypes.oneOf(['Regular'])

const RegularDayShape = PropTypes.shape({
  type: RegularDayType,
  shiftId: PropTypes.number.isRequired,
  shift: ShiftType.isRequired,
  team: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  restricted: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool])
    .isRequired,
  zone: PropTypes.string.isRequired,
})

export const DayType = PropTypes.oneOfType([RegularDayShape, OffDayShape])
