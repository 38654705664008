import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  abbreviate,
  clearFix,
  getSectionColor,
  dayNeedsCoverAttention,
  dayDuration,
} from '../../Utils'
import { Colors, Palette } from '../../Themes'

const BoxWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;

  ${clearFix}
`

const Box = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  line-height: ${props => props.size};
  border: 1px solid;
  box-sizing: border-box;
  float: left;
  margin-right: 3px;
  font-size: 10px;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }

  ${props =>
    props.outline
      ? css`
          color: ${props.accentColor};
          background-color: ${Colors.white};
          border-color: ${props.accentColor};
        `
      : css`
          color: ${Colors.white};
          background-color: ${props.accentColor};
          border-color: ${props.accentColor};
        `}
`

Box.defaultProps = {
  accentColor: Colors.cyan,
  outline: false,
  size: '38px',
}

function DayKey(props) {
  const { section, area, team, type, shift, sections, startTime, endTime } = props
  if (type === 'Regular' || type === 'Overtime') {
    return (
      <BoxWrapper>
        <Box accentColor={getSectionColor(section)}>{sections && sections.length > 0 && sections[parseInt(section) - 1].specialName !== '' ? sections[parseInt(section) - 1].specialName : `S${section}`}</Box>
        <Box outline accentColor={getSectionColor(section)}>
          {abbreviate(area)}
        </Box>
        <Box accentColor={getShiftColor(shift)}>{team}</Box>
      </BoxWrapper>
    )
  }

  return (
    <BoxWrapper>
      <Box
        accentColor={
          dayNeedsCoverAttention(props) ? Palette.error : dayDuration(props) >= 6 ? Palette.success : Palette.shortDay
        }
      >
        {abbreviate(type)}
      </Box>
    </BoxWrapper>
  )
}

function getShiftColor(shift) {
  switch (shift) {
    case 'Day':
      return Palette.day
    case 'Night':
      return Palette.night
    default:
      return Colors.silver
  }
}

DayKey.propTypes = {
  area: PropTypes.string,
  section: PropTypes.string,
  shift: PropTypes.string,
  team: PropTypes.string,
  type: PropTypes.string,
}

export default DayKey
