import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors, Palette } from '../../Themes'

const Table = styled.table`
  border-collapse: collapse;
  margin: 20px auto;
  width: 80%;
`

const TableSpecial = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  width: 100%;
`

const Heading = styled.th`
  border-top: 1px solid ${Colors.lightGrey};
  color: ${Colors.silver};
  font-size: 12px;
  font-weight: normal;
  padding: 10px 10px 10px 20px;
  text-align: left;
  text-transform: uppercase;
`

export const Value = styled.td`
  border-top: 1px solid ${Colors.lightGrey};
  color: ${props => (props.red ? Palette.error : Palette.darkGrey)};
  font-size: 12px;
  padding: 10px 20px 10px 10px;
  text-align: right;
`

function valueDecorator(row, props) {
  if (row.heading === 'Cover' && row.value !== 'No cover' && props.removeCoverAction) {
    return <Value>{props.removeCoverAction(row.value, row.offDayId, props.showRemoveCover)}</Value>
  } else if (row.heading === 'Cover' && row.value === 'No cover') {
    return <Value red>{row.value.toUpperCase()}</Value>
  }
  return <Value>{row.value}</Value> 
}

function SimpleTable(props) {
  return (
    <Table>
      <tbody>
        {props.rows.map(row => (
          <tr key={row.key || row.heading}>
            <Heading>{row.heading}</Heading>
            {valueDecorator(row, props)}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export function SimpleTableSpecial(props) {
  return (
    <TableSpecial>
      <tbody>
        {props.rows.map(row => (
          <tr key={row.key || row.heading}>
            <Heading>{row.heading}</Heading>
            {props.valueDecorator(row)}
          </tr>
        ))}
      </tbody>
    </TableSpecial>
  )
}

SimpleTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      heading: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
}

export default SimpleTable
