import qs from 'query-string'
import moment from 'moment-timezone'
import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import FixedSidebar from '../Components/FixedSidebar'
import { actions as SpecialDaysActions } from '../Redux/specialDays'
import { dateString } from '../Utils/time'
import { StyledReactModal as StyledModal, StyledReactSpecialModal as StyledSpecialModal } from '../Components/Modal'
import {
  AreaHeadings,
  Day,
  MonthSelect,
  MonthTable,
  Wrapper,
} from '../Components/SpecialDays'
import Spinner from '../Components/Spinner'
import SpecialDaysModalContainer from './SpecialDaysModalContainer'

class SpecialDays extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      areaId: null,
      date: null
    }

    this.previousMonth = this.changeMonth(-1)
    this.nextMonth = this.changeMonth(1)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.fetchDays = this.fetchDays.bind(this)
  }

  componentDidMount() {
    this.fetchDays()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateKey !== this.props.dateKey) {
      this.fetchDays()
    }
  }

  changeMonth(monthDiff) {
    return () => {
      const { month: prevMonth, year: prevYear } = this.props
      const nextSelectedMonth = parseInt(prevMonth, 10) - 1 + monthDiff
      const date = new Date(prevYear, nextSelectedMonth, 1)
      const dateStr = dateString(date)
      this.props.history.push({
        pathname: '/settings/special-days',
        search: '?date=' + dateStr.slice(0, 7),
      })
    }
  }

  openModal(areaId, date) {
    return () => {
      this.setState({ isOpen: true, areaId, date })
    }
  }

  closeModal() {
    this.setState({ isOpen: false })
    // TODO: works around properly merging state without an id
    setTimeout(() => {
      this.fetchDays()
    }, 250)
  }

  fetchDays() {
    this.props.basic
      ? this.props.fetchBasicSpecialDays(this.props.dateKey)
      : this.props.fetchSpecialDays(this.props.dateKey)
  }

  render() {
    const { isOpen } = this.state
    const date = new Date(
      parseInt(this.props.year, 10),
      parseInt(this.props.month, 10) - 1
    )
    if (this.props.areas.length === 0) {
      return (
        <Wrapper>
          <Helmet title="Special Days" />
          <MonthSelect
            current={moment(this.props.dateKey).format('MMMM YYYY')}
            onPrevious={this.previousMonth}
            onNext={this.nextMonth}
          />
          <Spinner reverse />
        </Wrapper>
      )
    }
    return (
      <Wrapper>
        <Helmet title="Special Days" />
        <MonthSelect
          current={moment(this.props.dateKey).format('MMMM YYYY')}
          onPrevious={this.previousMonth}
          onNext={this.nextMonth}
        />
        <FixedSidebar sidebarWidth="100px">
          {({ scrolled }) => ({
            sidebar: (
              <AreaHeadings shadow={scrolled} areas={this.props.areas} />
            ),
            content: (
              <MonthTable
                key={Math.random()}
                date={date}
                rows={this.props.specialDays}
                rowKey={row => row.id}
                dayFn={({ row: area, date }) => {
                  const dateKey = dateString(date)
                  return (
                    <Day
                      key={area.id + date}
                      onClick={this.openModal(area.id, dateKey)}
                      days={area.specialDays[dateKey]}
                      basic={this.props.basic}
                      projectManager={this.props.projectManager}
                    />
                  )
                }}
              />
            ),
          })}
        </FixedSidebar>
        <StyledSpecialModal isOpen={isOpen} onRequestClose={this.closeModal} >
          <SpecialDaysModalContainer
            isOpen={isOpen}
            onDismiss={this.closeModal}
            areaId={this.state.areaId}
            date={this.state.date}
          />
        </StyledSpecialModal>
        

      </Wrapper>
    )
  }
}

const areasSelector = state => state.entities.areas
const sectionsSelector = state => state.entities.sections
const orderSelector = state => state.entities.specialDays.order || []
const specialDaysSelector = state => state.entities.specialDays

const dateKeySelector = (_, props) => {
  const query = qs.parse(props.location.search.slice(1))
  let date
  if (query.date) {
    const [year, month] = query.date.split('-')
    date = new Date(parseInt(year, 10), parseInt(month, 10) - 1)
  } else {
    date = new Date()
  }
  return dateString(date).slice(0, 7)
}

const currentSpecialDaysSelector = createSelector(
  specialDaysSelector,
  dateKeySelector,
  (specialDays, dateKey) => specialDays[dateKey]
)

const orderedAreasSelector = createSelector(
  orderSelector,
  areasSelector,
  (order, areas) => order.map(x => areas[x])
)

const orderedAreasSectionsSelector = createSelector(
  orderedAreasSelector,
  sectionsSelector,
  (areas, sections) =>
    areas.map(x => ({
      ...omit(['sectionId'], x),
      section: sections[x.sectionId],
    }))
)

const orderedSpecialDays = createSelector(
  orderedAreasSectionsSelector,
  currentSpecialDaysSelector,
  (areas, specialDays) => {
    return areas.map(x => {
      const areaSpecialDays =
        (specialDays && specialDays.areas && specialDays.areas[x.id]) || {}
      return {
        ...x,
        specialDays: (areaSpecialDays.specialDays || []).reduce((acc, day) => {
          const date = day.date.slice(0, 10)
          acc[date] == null ? (acc[date] = [day]) : acc[date].push(day)
          return acc
        }, {}),
      }
    })
  }
)

const yearSelector = createSelector(
  dateKeySelector,
  x => x.slice(0, 4)
)
const monthSelector = createSelector(
  dateKeySelector,
  x => x.slice(5, 7)
)

function omit(bannedKeys, obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (bannedKeys.indexOf(key) === -1) {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}

export default connect(
  function mapStateToProps(state, ownProps) {
    return {
      year: yearSelector(state, ownProps),
      month: monthSelector(state, ownProps),
      dateKey: dateKeySelector(state, ownProps),
      areas: orderedAreasSectionsSelector(state, ownProps),
      specialDays: orderedSpecialDays(state, ownProps),
      user: state.auth.user,
      basic: state.auth.user.role === 'basic',
      projectManager: state.auth.user.role === 'projectManager'
    }
  },
  {
    fetchSpecialDays: SpecialDaysActions.fetchSpecialDays,
    fetchBasicSpecialDays: SpecialDaysActions.fetchBasicSpecialDays
   }
)(SpecialDays)
