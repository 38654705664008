const colors = {
  black: '#000',
  white: '#fff',
  whiteOpacity: 'rgba(255, 255, 255, 0.2)',
  whiteHalfOpacity: 'rgba(255, 255, 255, 0.5)',
  purple: '#482683',
  purpleDark: '#1d093f',
  purpleText: 'rgba(235, 237, 248, 0.5)',
  purpleOpacity: 'rgba(180, 186, 198, 0.45)',
  purpleHalfOpacity: 'rgba(180, 186, 198, 0.2)',
  cyan: '#50e3c2',
  green: '#7ed321',
  magenta: '#d25dea',
  red: '#ff5555',
  orange: '#ff7b1f',
  transparent: 'rgba(0, 0, 0, 0)',
  grey: '#4a4a4a',
  lightGrey: '#ebedf8',
  darkGrey: '#354052',
  silver: '#b4bac6',
  offWhite: '#fafbfc',
  lightBlue: '#56C8F6',
  darkBlue: '#4A7BB4',
  navyBlue: '#0C3C74',
}

export default colors
