import moment from 'moment-timezone'
import { css } from 'styled-components'

import { Colors, Palette } from '../Themes'

/**
 * Gets the section colour.
 *
 * @export
 * @param {string} section
 */
export const getSectionColor = section => {
  switch (section) {
    case '1':
      return Palette.section1
    case '2':
      return Palette.section2
    case '3':
      return Palette.section3
    case '4':
      return Palette.section4
    case '5':
      return Palette.section5
    case '6':
    case 'D':
      return Palette.sectionDayOnly
    case '7':
    case 'A':
      return Palette.sectionApprentices
    default:
      return Palette.noSectionColor
  }
}

/**
 * Gets the abbreviation of a string.
 *
 * @export
 * @param {string} string
 */
export const abbreviate = string => {
  const matches = string.match(/\b(\w)/g)
  const acronym = matches.join('')
  return acronym
}

/**
 * Adds leading zeros to a number.
 *
 * @param {number} number
 * @param {number} width
 */
export const pad = (number, width) => {
  const z = '0'
  const n = number + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

/**
 * Gets the month number.
 *
 * @param {number} year
 * @param {number} month
 * @param {number} day
 */
export const numberOfMonth = (year, month, day) => {
  return moment(`${year}-${pad(month, 2)}-${pad(day, 2)}`).format('DD')
}

/**
 * Gets the day off the week.
 *
 * @param {number} year
 * @param {number} month
 * @param {number} day
 */
export const dayOfWeek = (year, month, day) => {
  return abbreviate(
    moment(`${year}-${pad(month, 2)}-${pad(day, 2)}`).format('dd')
  )
}

/**
 * Gets the amount of days in the month.
 *
 * @param {number} year
 * @param {number} month
 */
export const daysInMonth = (year, month) => {
  return moment(`${year}-${pad(month, 2)}-01`).daysInMonth()
}

/**
 * Is the date today?
 *
 * @param {number} year
 * @param {number} month
 * @param {number} day
 */
export const isToday = (year, month, day) => {
  return moment(`${year}-${pad(month, 2)}-${pad(day, 2)}`).isSame(
    moment(),
    'day'
  )
}

/**
 * Centers an element.
 */
export const center = css`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const clearFix = css`
  &::before,
  &::after {
    display: table;
    content: ' ';
  }
  &::after {
    clear: both;
  }
`

export function dayNeedsCoverAttention(day) {
  return (day.coverRequired && day.cover == null)
}

export function dayDuration(day) {
  return moment(day.endTime).diff(moment(day.startTime), 'hours')
}

export function dayColor(data) {
  return dayNeedsCoverAttention(data) ? Palette.error : dayDuration(data) >= 6 ? 
    Palette.success : Colors.navyBlue
}