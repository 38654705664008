
const EDIT_DAY_SAVE_REQUEST = 'editDay/save request'
const EDIT_DAY_SAVE_RESPONSE = 'editDay/save response'
const EDIT_DAY_REMOVE_COVER_REQUEST = 'specialDays/remove_cover request'
const EDIT_DAY_REMOVE_COVER_RESPONSE = 'specialDays/remove_cover response'
const EDIT_GET_BID_LIST_REQUEST = 'edit/get_bid_list request'
const EDIT_GET_BID_LIST_RESPONSE = 'edit/get_bid_list response'
const EDIT_GET_RESOURCE_NAMES_REQUEST = 'edit/get_resource_names request'
const EDIT_GET_RESOURCE_NAMES_RESPONSE = 'edit/get_resource_names response'
// dashboard
const EDIT_DAY_UPDATE_DASHBOARD_REQUEST = 'editDay/update_dashboard request'
const EDIT_DAY_UPDATE_DASHBOARD_RESPONSE = 'editDay/update_dasboard response'

const editDaySave = asyncActionCreator(
  EDIT_DAY_SAVE_REQUEST,
  EDIT_DAY_SAVE_RESPONSE
)

const editDayUpdateDashboard = asyncActionCreator(
  EDIT_DAY_UPDATE_DASHBOARD_REQUEST,
  EDIT_DAY_UPDATE_DASHBOARD_RESPONSE
)

function editDayRemoveCoverRequest() {
  return { type: EDIT_DAY_REMOVE_COVER_REQUEST }
}

function editDayRemoveCoverResponse(err, payload) {
  if (err) {
    return {
      type: EDIT_DAY_REMOVE_COVER_RESPONSE,
      payload: err,
      error: true,
    }
  }

  return {
    type: EDIT_DAY_REMOVE_COVER_RESPONSE,
    payload,
  }
}

function editDayGetBidListRequest() {
  return { type: EDIT_GET_BID_LIST_REQUEST }
}

function editDayGetBidListResponse(err, payload) {
  if (err) {
    return {
      type: EDIT_GET_BID_LIST_RESPONSE,
      payload: err,
      error: true,
    }
  }

  return {
    type: EDIT_GET_BID_LIST_RESPONSE,
    payload,
  }
}

function editDayGetResourceNamesRequest() {
  return { type: EDIT_GET_RESOURCE_NAMES_REQUEST }
}

function editDayGetResourceNamesResponse(err, payload) {
  if (err) {
    return {
      type: EDIT_GET_RESOURCE_NAMES_RESPONSE,
      payload: err,
      error: true,
    }
  }

  return {
    type: EDIT_GET_RESOURCE_NAMES_RESPONSE,
    payload,
  }
}

function handleSaveOvertime(profileName, profileKey, date, day, overtimeType, dismiss, cb) {
  return function(dispatch, getState, api) {
    dispatch(editDaySave.request())
    api
      .updateOvertimeType(day.id, overtimeType, cb)
      .then(request => {
        if (request.ok === false) {
          const err = new Error(
            typeof request.data.message === 'string'
              ? request.data.message
              : 'There was an error'
          )
          cb(err)
          throw err
        }
        const state = getState()
        const profile = state.entities.profiles[profileKey]
        const payload = {
          entities: mergeDayObject(profileKey, profile, date, {
            id: day.id,
            type: 'Overtime',
            shiftId: day.shiftId,
            shift: day.shift,
            team: day.team,
            area: day.area,
            section: day.section,
            startTime: day.startTime,
            endTime: day.endTime,
            restricted: day.restricted,
            overtimeType: overtimeType
          }),
        }
        dispatch(editDaySave.response(null, payload))
        dismiss()
      })
      .catch(err => {
          dispatch(editDaySave.response(err))
          return err
        })
  }
}

// TODO: this is not very isolated atm
function handleSave(profileName, profileKey, date, day, startTime, endTime, overtime, dismiss, cb) {
  return function(dispatch, getState, api) {
    dispatch(editDaySave.request())
    if (day.type === 'Regular' && day.offDayId != null) {
      // we are deleting an existing off day
      api
        .updateOffDay(day.offDayId, undefined, 'Shift')
        .then(request => {
          if (request.ok === false) {
            const err = new Error(
              typeof request.data.message === 'string'
                ? request.data.message
                : 'There was an error'
            )
            cb(err)
            throw err
          }
          const state = getState()
          const profile = state.entities.profiles[profileKey]
          const payload = {
            entities: mergeDayObject(profileKey, profile, date, {
              id: day.id,
              type: 'Regular',
              shiftId: day.shiftId,
              shift: day.shift,
              team: day.team,
              area: day.area,
              section: day.section,
              startTime: day.startTime,
              endTime: day.endTime,
              restricted: day.restricted
            }),
          }
          dispatch(editDaySave.response(null, payload))
          dismiss()
        })
        .catch(err => {
          dispatch(editDaySave.response(err))
          return err
        })
    } else if (day.offDayId == null) {
      api
        .newOffDay(
          date,
          day.type,
          day.coverRequired,
          day.shiftId,
          day.holidayType,
          day.startTime,
          day.endTime,
          overtime
        )
        .then(
          offDayCallback(profileName, profileKey, date, dispatch, getState, dismiss, cb)
        )
        .catch(err => {
          editDaySave.response(err)
          return err
        })
    } else {
      api
        .updateOffDay(
          day.offDayId,
          date,
          day.type,
          day.coverRequired,
          day.shiftId,
          day.holidayType,
          day.startTime,
          day.endTime,
          overtime
        )
        .then(
          offDayCallback(profileName, profileKey, date, dispatch, getState, dismiss, cb)
        )
        .catch(err => {
          editDaySave.response(err)
          return err
        })
    }
  }
}

function offDayCallback(profileName, profileKey, date, dispatch, getState, dismiss, cb) {
  return function(request) {
    if (request.ok) {
      const state = getState()
      const profile = state.entities.profiles[profileKey]
      const { id: offDayId, shift, ...rest } = request.data
      const payload = {
        entities: mergeDayObject(profileKey, profile, date, {
          ...profile.days[date],
          offDayId,
          ...rest,
        }),
      }
      dispatch(editDaySave.response(null, payload))
      if (cb) cb()
      dismiss()
    } else {
      const err = new Error(
        (request.data && request.data.message) || 'There was an error'
      )
      if (cb) cb(err)
      throw err
    }
  }
}

function mergeDayObject(profileKey, profile, date, day) {
  return {
    profiles: {
      [profileKey]: {
        ...profile,
        days: {
          ...profile.days,
          [date]: day,
        },
      },
    },
  }
}

function asyncActionCreator(req, res) {
  return {
    request() {
      return { type: req }
    },
    response(err, payload) {
      if (err) return { type: res, payload: err, error: true }
      return { type: res, payload }
    },
  }
}

function removeCover(offDayId, reason, callback) {
  return function(dispatch, getState, api) {
    dispatch(editDayRemoveCoverRequest())
    return api
      .removeCover(offDayId, reason)
      .then(response => {
        if (response.ok) {
          dispatch(editDayRemoveCoverResponse(null, response.data))
          callback()
        } else if (response.data && response.data.message) {
          throw new Error(response.data.message)
        } else {
          throw new Error('There was an error removing the cover')
        }
      })
      .catch(err => {
        dispatch(editDayRemoveCoverResponse(err))
        return err
      })
  }
}

function getBidList(offDayId, callback) {
  return function(dispatch, getState, api) {
    dispatch(editDayGetBidListRequest())
    return api
      .getBidList(offDayId)
      .then(response => {
        if (response.ok) {
          dispatch(editDayGetBidListResponse(null, response.data))
          callback(response.data)
        } else if (response.data && response.data.message) {
          throw new Error(response.data.message)
        } else {
          throw new Error('There was an error fetching the bid list')
        }
      })
      .catch(err => {
        dispatch(editDayGetBidListResponse(err))
        return err
      })
  }
}

function getResourceNames(data, callback) {
  return function(dispatch, getState, api) {
    dispatch(editDayGetResourceNamesRequest())
    return api
      .getResourceNames(data)
      .then(response => {
        if (response.ok) {
          dispatch(editDayGetResourceNamesResponse(null, response.data))
          callback(response.data)
        } else if (response.data && response.data.message) {
          throw new Error(response.data.message)
        } else {
          throw new Error('There was an error fetching the resource names')
        }
      })
      .catch(err => {
        dispatch(editDayGetResourceNamesResponse(err))
        return err
      })
  }
}

function updateDashboard(offDayId, date, type, coverRequired, shiftId, holidayType, startTime, endTime, overtime, callback) {
  return function(dispatch, getState, api) {
    dispatch(editDayUpdateDashboard.request())
    return api
      .updateOffDay(
        offDayId,
        date,
        type,
        coverRequired,
        shiftId,
        holidayType,
        startTime,
        endTime,
        overtime
      )
      .then(response => {
        if (response.ok) {
          dispatch(editDayUpdateDashboard.response(null, response.data))
          callback(response.data)
        } else if (response.data && response.data.message) {
          throw new Error(response.data.message)
        } else {
          throw new Error('There was an error updating the dashboard')
        }
      })
      .catch(err => {
        dispatch(editDayUpdateDashboard.response(err))
        return err
      })
  }
}

export const actions = {
  handleSave,
  removeCover,
  getBidList,
  getResourceNames,
  handleSaveOvertime,
  updateDashboard
}
