import { all, takeLatest } from 'redux-saga/effects'

// Types
import { StartupTypes } from '../Redux/StartupRedux'
import { AuthTypes } from '../Redux/AuthRedux'
import { DashboardTypes } from '../Redux/DashboardRedux'
import { RosterTypes } from '../Redux/RosterRedux'

// Sagas
import { startup } from './StartupSagas'
import { login, refresh, logout, resetPassword } from './AuthSagas'
import { dashboard } from './DashboardSagas'
import { roster } from './RosterSagas'

// Connect types to sagas
export default function setupSagas(api) {
  return function* root() {
    yield all([
      takeLatest(StartupTypes.STARTUP, startup, api),
      takeLatest(AuthTypes.LOGIN_REQUEST, login, api),
      takeLatest(AuthTypes.REFRESH_REQUEST, refresh, api),
      takeLatest(AuthTypes.LOGOUT_REQUEST, logout, api),
      takeLatest(AuthTypes.RESET_PASSWORD_REQUEST, resetPassword, api),
      takeLatest(DashboardTypes.DASHBOARD_REQUEST, dashboard, api),
      takeLatest(RosterTypes.ROSTER_REQUEST, roster, api),
    ])
  }
}
