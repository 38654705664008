import styled from 'styled-components'
import { Colors } from '../../../Themes'
import { List } from '../../Icons'
import { navLinkIconSize } from './measures'

const DashboardIcon = styled(List)`
  width: ${navLinkIconSize}px;
  height: ${navLinkIconSize}px;
  fill: ${Colors.purple};
  vertical-align: middle;
`

export default DashboardIcon
