import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const fontSize = ({ width, height }) => {
  const size = width || height
  return size ? `${size / 16}rem` : '1.25em'
}

const Wrapper = styled.img`
  box-sizing: border-box;
  color: currentcolor;
  display: inline-block;
  font-size: ${fontSize};
  height: 1em;
  margin: 0.1em;
  width: 1em;
`

const Icon = ({ icon, ...props }) => {
  const src = require(`./icons/${icon}.svg`)
  return <Wrapper {...props} src={src} />
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default Icon
