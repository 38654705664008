import React from 'react'
import styled from 'styled-components'
import { Palette } from '../../Themes'
import { Button } from '../Forms'
import { LeftChevron, RightChevron } from '../Icons'

const StyledButton = styled(Button)`
  padding: 10px;
  color: currentColor;
  outline: 0;
`

const StyledIcon = styled.svg`
  stroke: currentColor;
  fill: transparent;
  width: 22px;
  height: 22px;
`

const Current = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
  text-transform: uppercase;
`

const Root = styled.nav`
  color: ${Palette.interfaceText};

  ${Current} {
    min-width: ${props => props.minWidth && `${props.minWidth}px`};
  }
`

function PaginateControl(props) {
  const { className, current, minWidth, onNext, onPrevious } = props
  return (
    <Root className={className} minWidth={minWidth}>
      <StyledButton unstyled stretch={false} onClick={onPrevious}>
        <StyledIcon as={LeftChevron} />
      </StyledButton>
      <Current>{current}</Current>
      <StyledButton unstyled stretch={false} onClick={onNext}>
        <StyledIcon as={RightChevron} />
      </StyledButton>
    </Root>
  )
}

export default PaginateControl
