import { getSectionColor } from '../Utils'

// Action types
const NAVIGATION_REQUEST = 'app/navigation request'
const NAVIGATION_RESPONSE = 'app/navigation response'

// Reducer
const initialState = {
  navigation: {
    isFetching: false,
    sections: {},
  },
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
    case NAVIGATION_REQUEST:
      return {
        ...state,
        navigation: { ...state.navigation, isFetching: true },
      }
    case NAVIGATION_RESPONSE:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          ...payload,
          isFetching: false,
        },
      }
    default:
      return state
  }
}

// Action creators
function navigationRequest() {
  return { type: NAVIGATION_REQUEST }
}

function navigationResponse(err, navigation) {
  if (err) {
    return {
      type: NAVIGATION_RESPONSE,
      payload: err,
      error: true,
    }
  }

  return {
    type: NAVIGATION_RESPONSE,
    payload: navigation,
  }
}

// Thunks
function fetchNavigation() {
  return function(dispatch, getState, api) {
    dispatch(navigationRequest())
    api
      .sections()
      .then(response => {
        if (response.ok) {
          dispatch(navigationResponse(null, fixup(response.data)))
        } else {
          dispatch(
            navigationResponse('Error fetching navigation: ' + response.status)
          )
        }
      })
      .catch(err => dispatch(navigationResponse(err)))
  }
}

function fixup(sections) {
  return {
    sections: sections.reduce((acc, section) => {
      acc[section.id] = {
        ...section,
        id: '' + section.id,
        shortName: 'S' + section.name,
        specialName: section.shortName || '',
        url: '/section/' + section.id,
        color: getSectionColor(section.name),
      }
      return acc
    }, {}),
  }
}

export const actions = {
  fetchNavigation,
}
