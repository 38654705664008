import styled, { css } from 'styled-components'
import { Colors, Palette } from '../../Themes'

const InlineButton = styled.button`
  box-sizing: border-box;
  border-width: 0;
  padding: 0 0 0 4px;
  text-transform: uppercase;
  cursor: pointer;
  vertical-align: middle;

  &:disabled {
    background-color: ${Colors.lightGrey};
  }

  ${props =>
    props.stretch &&
    css`
      width: 100%;
    `}

  ${props =>
    props.unstyled
      ? css`
          background-color: transparent;
          color: ${Palette.text};
        `
      : props.primary
      ? css`
          color: ${Palette.buttonText};
          background-color: ${Palette.buttonPrimaryBackground};
        `
      : css`
          color: ${Palette.buttonText};
          background-color: ${Palette.buttonBackground};
        `}
`

InlineButton.defaultProps = {
  stretch: true,
}

export default InlineButton
