import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import { AuthActions, AuthSelectors } from '../Redux/AuthRedux'
import Login from '../Components/Login'

function LoginContainer(props) {
  const { isAuthenticated, location } = props
  const { from = '/' } = location.state || {}

  return (
    <>
      <Helmet title="Login" />
      {isAuthenticated === true ? (
        <Redirect to={from} />
      ) : (
        <Login
          login={props.login}
          loginFetching={props.loginFetching}
          loginError={props.loginError}
        />
      )}
    </>
  )
}

LoginContainer.propTypes = {
  isAuthenticated: PropTypes.bool,
  loginFetching: PropTypes.bool,
  loginError: PropTypes.shape({
    message: PropTypes.string,
  }),
  login: PropTypes.func.isRequired,
}

export default connect(
  function mapStateToProps(state) {
    return {
      isAuthenticated: AuthSelectors.isAuthenticated(state),
      loginFetching: AuthSelectors.loginFetching(state),
      loginError: AuthSelectors.loginError(state),
    }
  },
  { login: AuthActions.loginRequest }
)(LoginContainer)
