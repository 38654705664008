import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Palette } from '../../Themes'
import DayBase from '../MonthTableDay'
import { Dots } from '../Icons'

const iconWidth = 10
const iconHeight = (4 / 12) * iconWidth
const Icon = styled(Dots).attrs({ width: iconWidth })`
  fill: currentColor;
`

const Cell = styled(DayBase)`
  position: relative;
  cursor: pointer;

  ${Icon} {
    position: absolute;
    top: 23%;
    left: 50%;
    margin-top: -${iconHeight / 2}px;
    margin-left: -${iconWidth / 2}px;
  }

  ${props =>
    props.empty &&
    css`
      color: ${Colors.purple};
      font-size: 16px;
      width: 2.37em;
      height: 2.37em;
    `}

  ${props => {
    if (props.empty) {
      return css`
        background-color: ${Palette.textWithSectionColor};
      `
    } else {
      return css`
        background-color: ${props.needsAttention
          ? Palette.error
          : Palette.success};
        color: white;
      `
    }
  }}
`

function Day(props) {
  const { days } = props

  return days == null ? (
    props.basic || props.projectManager
      ? <Cell empty></Cell>
      : <Cell onClick={props.onClick} empty>
          {'+'}
        </Cell>
  ) : (
    <Cell
      onClick={props.onClick}
      needsAttention={specialDayNeedsAttention(days)}
    >
      <Icon />
      {summary(days)}
    </Cell>
  )
}

export default Day

function summary(days) {
  return String(days.length > 1 ? days.length : days[0].type[0])
}

function specialDayNeedsAttention(days) {
  return days.findIndex(x => x.overtimes.length < x.techsRequired) !== -1
}
