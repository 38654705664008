import React, { ReactNode } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Colors from '../Themes/Colors'
import { headerHeight } from './Header/styles/measures'

interface Props {
  backgroundColor: string
  children: ReactNode
}

const RootStyles = createGlobalStyle`
  html,
  body,
  #root {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`

const Content = styled.section<Props>`
  background-color: ${props => props.backgroundColor || Colors.white};
  height: calc(100% - ${headerHeight}px);
  width: 100%;
`

function Page(props: Props) {
  return (
    <>
      <RootStyles />
      <Content {...props} />
    </>
  )
}

export default Page
