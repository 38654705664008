import styled from 'styled-components'
import { Cog } from '../../Icons'
import { navLinkIconSize } from './measures'

const SettingsIcon = styled(Cog)`
  width: ${navLinkIconSize}px;
  height: ${navLinkIconSize}px;
  fill: white;
  vertical-align: middle;
`

export default SettingsIcon
