import styled from 'styled-components'
import { clearFix } from '../../../Utils'
import { headerHeight, navLinkUnderbarOverhang } from './measures'

const Nav = styled.nav`
  ${clearFix}

  margin-left: -${navLinkUnderbarOverhang}px;
  float: left;
  line-height: ${headerHeight}px;
`

export default Nav
