import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { Colors } from '../../Themes'

const disabledStyles = css`
  background: transparent;
  border-color: ${Colors.purpleOpacity};
  color: ${Colors.white};
  cursor: default;
  pointer-events: none;
`

const linkStyles = css`
  background: transparent;
  border: 0;
  color: ${Colors.purpleOpacity};
  font-size: 12px;
  padding: 0;
`

const styles = css`
  align-items: center;
  appearance: none;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.white};
  box-sizing: border-box;
  cursor: pointer;
  color: ${Colors.purple};
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 1px;
  margin: 12px 0;
  padding: 20px;
  pointer-events: auto;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  ${({ disabled }) => disabled && disabledStyles}
  ${({ link }) => link && linkStyles}
`

const StyledLink = styled(
  ({ disabled, transparent, reverse, palette, height, theme, ...props }) => (
    <Link {...props} />
  )
)`
  ${styles}
`

const Anchor = styled.a`
  ${styles}
`
const StyledButton = styled.button`
  ${styles}
`

const Button = ({ type, ...props }) => {
  if (props.to) {
    return <StyledLink link {...props} />
  } else if (props.href) {
    return <Anchor link {...props} />
  }
  return <StyledButton {...props} type={type} />
}

Button.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
}

export default Button
