import styled from 'styled-components'
import { Palette } from '../../Themes'

export const LabelGroup = styled('label')`
  width: 210px;
  margin-top: 1.5rem;
  margin-left: 2rem;

  &:last-child {
    margin-right: 2rem;
  }
`

export const LabelName = styled.span`
  color: ${Palette.labelText};
  display: block;
  margin-bottom: 0.3em;
`
