import { put } from 'redux-saga/effects'

import { AuthActions } from '../Redux/AuthRedux'

/**
 * Startup saga.
 * Refreshes user data if state is rehydrated.
 *
 * @export
 */
export function* startup() {
  const accessToken = localStorage.getItem('accessToken')

  if (accessToken) {
    yield put(AuthActions.refreshRequest(accessToken))
  }
}
