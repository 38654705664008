import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { DayType } from './types'
import DayKey from './DayKey'
import Icon from '../Icon'
import { Button, LabelGroup, LabelName, Select, Text, TextArea } from '../Forms'
import { Colors, Palette } from '../../Themes'
import { clearFix } from '../../Utils'

// TODO: these are strictly mapped to the database values.
// BE CAREFUL WHEN THE DATABASE IS REMADE AS THIS WILL BREAK
// Needs to have the API tell the client what is available to choose
const holidayTypeLookup = {
  'Annual Leave': 1,
  'Banked Hours': 2,
  TOIL: 3,
  'Union TOIL': 4,
  'Accrued Hours': 5,
  'Accrued Banked Hours': 6,
}

const Row = styled.div`
  display: flex;
`

const Header = styled.header`
  border-bottom: 1px solid ${Colors.lightGrey};
  height: 70px;
  line-height: 70px;

  ${clearFix}
`

const ModalTitle = styled.h2`
  font-size: 20px;
  font-weight: normal;
  margin: 0 2rem;
  float: left;
  width: 210px;
`

const Form = styled.form`
  font-size: 0.9em;
  min-height: 280px;
`

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 1rem;
`

const SubmitButtonWrapper = styled.div`
  width: 210px;
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const ErrorMessage = styled.p`
  color: ${Palette.error};
  width: 210px;
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = resetFormData(props.day)

    this.processCallback = this.processCallback.bind(this)
    this.updateStartTime = this.updateStartTime.bind(this)
    this.updateEndTime = this.updateEndTime.bind(this)
  }

  componentWillMount(){ 
    this.props.getResourceNames({shiftId: this.props.day.shiftId, date: this.props.date}, this.setNewResourceNames.bind(this))

    if (this.props.day.cover) {
      this.setState({ resourceName: { name:this.props.day.cover.name, shiftId: this.props.day.cover.shiftId}, overtimeType: this.props.day.cover.overtimeType || 'COMMS' });
    }
  }

  setNewResourceNames(resourceNames) {
    this.setState({ resourceNames })
  }

  componentDidUpdate(prevProps) {
    if (this.props.day !== prevProps.day) {
      this.setState(resetFormData(this.props.day))
    }
  }

  updateState(key, value = 'value') {
    return ev => this.setState({
      [key]: ev.target[value]
    })
  }

  updateStartTime(time) {
    const { date, day } = this.props
    const calculatedDate = day.shift === 'Day' || time >= '19:00'
      ? date
      : moment(date).add(1, 'day').format('YYYY-MM-DD')

    return this.setState({
      startTime: moment.utc(`${calculatedDate} ${time}`).toISOString()
    })
  }

  updateEndTime(time) {
    const { date, day } = this.props
    const calculatedDate = day.shift === 'Day' || time > '19:00'
      ? date
      : moment(date).add(1, 'day').format('YYYY-MM-DD')

    return this.setState({
      endTime: moment.utc(`${calculatedDate} ${time}`).toISOString()
    })
  }
  
  handleSave(day) {
    const { onSave, name, profileKey, date } = this.props
    const { startTime, endTime } = this.state
    const overtime = {
      shiftId: parseInt(this.state.resourceName.shiftId),
      overtimeType: this.state.overtimeType || 'COMMS',
      reason: this.state.reason
    }
    return ev => {
      onSave(name, profileKey, date, day, startTime, endTime, overtime, this.processCallback)
      ev.preventDefault()
    }
  }

  handleSaveOvertime(day) {
    const { onSaveOvertime, name, profileKey, date } = this.props
    const { overtimeType } = this.state
    return ev => {
      onSaveOvertime(name, profileKey, date, day, overtimeType, this.processCallback)
      ev.preventDefault()
    }
  }

  processCallback(error) {
    if (error) {
      this.setState({ error })
    } else {
      typeof this.props.next === 'function' && this.props.next()
    }

    this.props.refreshRoster()
  }

  populateHours(night) {
    let hoursList = [];
    const minTime = 7;
    const maxTime = 19;


    if (night) {
      for (var i = 19; i <= 23; i++) {
        hoursList.push(`${i > 9 ? '' : '0'}${i}:00`)
        hoursList.push(`${i > 9 ? '' : '0'}${i}:30`)
      }
      for (var i = 0; i <= 7; i++) {
        hoursList.push(`${i > 9 ? '' : '0'}${i}:00`)
        if (i < 7) hoursList.push(`${i > 9 ? '' : '0'}${i}:30`)
      }
    } else {
      for (var i = minTime; i <= maxTime; i++) {
        hoursList.push(`${i > 9 ? '' : '0'}${i}:00`)
        if (i < maxTime) hoursList.push(`${i > 9 ? '' : '0'}${i}:30`)
      }
    }

    return hoursList
  }

  updateResourceName(name, shiftId) {
    this.setState({
      resourceName: { shiftId, name },
      showReason: this.props.day.cover && name !== this.props.day.cover.name ? true : false
    })
  }

  render() {
    const { date, name, day } = this.props
    const { coverRequired, error, holidayType, overtimeType, type, startTime, endTime } = this.state
    const calculatedHours =
      day.shift === 'Night'
        ? this.populateHours(true)
        : this.populateHours()

    return type === 'Overtime' ? 
      (
        <>
        <Header>
          <ModalTitle>Edit overtime type</ModalTitle>
          <DayKey {...this.props.day} />
          <CloseButtonWrapper>
            <Button onClick={this.props.onExit} unstyled stretch={false}>
              <Icon icon="close" />
            </Button>
          </CloseButtonWrapper>
        </Header>
        <Form
          onSubmit={this.handleSaveOvertime({
            ...this.props.day,
            overtimeType
          })}
        >
          <Row>
            <LabelGroup>
              <LabelName>Name</LabelName>
              <Text disabled value={name} />
            </LabelGroup>
            <LabelGroup>
              <LabelName>Day</LabelName>
              <Text disabled value={moment(date).format('DD MMM YYYY')} />
            </LabelGroup>
          </Row>
          <Row>
            <LabelGroup>
              <LabelName>Overtime type</LabelName>
              <Select
                onChange={this.updateState('overtimeType')}
                value={overtimeType}
              >
                
                {this.props.overtimeTypes.map(type => (
                  <option key={type}>{type}</option>
                ))}
              </Select>
            </LabelGroup>
            <LabelGroup>
                <LabelName>Covering for</LabelName>
                <Text disabled value={day.holidayTaker} />
              </LabelGroup>
          </Row>
          <Row>
            <SubmitButtonWrapper>
              <Button
                primary
                disabled={compare(resetFormData(this.props.day), this.state)}
              >
                Save
              </Button>
            </SubmitButtonWrapper>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </Row>
        </Form>
        </>
      )
    : (
      <>
        <Header>
          <ModalTitle>Edit day</ModalTitle>
          <DayKey {...this.props.day} />
          <CloseButtonWrapper>
            <Button onClick={this.props.onExit} unstyled stretch={false}>
              <Icon icon="close" />
            </Button>
          </CloseButtonWrapper>
        </Header>
        <Form
          onSubmit={this.handleSave({
            ...this.props.day,
            holidayType: holidayTypeLookup[holidayType] || 1,
            type,
            coverRequired,
            startTime,
            endTime
          })}
        >
          <Row>
            <LabelGroup>
              <LabelName>Name</LabelName>
              <Text disabled value={name} />
            </LabelGroup>
            <LabelGroup>
              <LabelName>Day</LabelName>
              <Text disabled value={moment(date).format('DD MMM YYYY')} />
            </LabelGroup>
          </Row>
          <Row>
            <LabelGroup>
              <LabelName>Day type</LabelName>
              <Select onChange={this.updateState('type')} value={type}>
                {this.props.dayTypes.map(type => (
                  <option key={type}>{type}</option>
                ))}
              </Select>
            </LabelGroup>
            {type !== 'Regular' && (
              <LabelGroup>
                <LabelName>Cover required</LabelName>
                <input
                  type="checkbox"
                  onChange={this.updateState('coverRequired', 'checked')}
                  checked={!!coverRequired}
                />
              </LabelGroup>
            )}
          </Row>
          <Row>
            <LabelGroup>
              {type === 'Holiday' && (
                <>
                  <LabelName>Holiday type</LabelName>
                  <Select
                    onChange={this.updateState('holidayType')}
                    value={holidayType}
                  >
                    {this.props.holidayTypes.map(type => (
                      <option key={type}>{type}</option>
                    ))}
                  </Select>
                </>
              )}
            </LabelGroup>
            {coverRequired && type !== 'Regular' && 
              <LabelGroup>
                <LabelName>Resource name (optional)</LabelName>
                <Select
                  onChange={(e) => {
                    const selectedIndex = e.target.options.selectedIndex
                    this.updateResourceName(e.target.value, e.target.options[selectedIndex].getAttribute('data-shiftId'))
                  }}
                  value={this.state.resourceName && this.state.resourceName.name}
                >
                  {[{id: 0, name: this.props.day.cover && this.props.day.cover.name ? this.props.day.cover.name : 'Select cover'}, ...this.state.resourceNames].map(type => (
                    <option key={type.id} data-shiftId={type.shiftId}>{type.name}</option>
                  ))}
                </Select>
              </LabelGroup>
            }
          </Row>
          {type !== 'Regular' && (
            <Row>
              <LabelGroup>
                <LabelName>Start Time</LabelName>
                <Select
                  onChange={time => this.updateStartTime(time.target.value)}
                  value={moment.utc(startTime).format('HH:mm')}
                >
                {calculatedHours.map(time => (
                  <option key={`s-${time}`}>{time}</option>
                ))}
                </Select>
              </LabelGroup>
              { coverRequired && 
                <LabelGroup>
                  <LabelName>Overtime type</LabelName>
                  <Select
                    onChange={this.updateState('overtimeType')}
                    value={overtimeType}
                    disabled={!this.state.resourceName}
                  >
                    
                    {this.props.overtimeTypes.map(type => (
                      <option key={type}>{type}</option>
                    ))}
                  </Select>
              </LabelGroup>
              }
           </Row>
          )}
          {type !== 'Regular' && (
            <Row>
              <LabelGroup>
                <LabelName>End Time</LabelName>
                <Select
                  onChange={time => this.updateEndTime(time.target.value)}
                  value={moment.utc(endTime).format('HH:mm')}
                >
                {calculatedHours.map(time => (
                  <option key={`e-${time}`}>{time}</option>
                ))}
                </Select>
              </LabelGroup>
              { coverRequired && this.state.showReason &&
                <LabelGroup>
                  <LabelName>Reason for cover swap (will be sent to previous cover)</LabelName>
                  <TextArea value={this.state.reason} onChange={this.updateState('reason')} />
                </LabelGroup>
              }
            </Row>
          )}
          <Row>
            <SubmitButtonWrapper>
              <Button
                primary
                disabled={
                  compare(resetFormData(this.props.day),
                  {
                    coverRequired: this.state.coverRequired,
                    holidayType: this.state.holidayType,
                    overtimeType: this.state.overtimeType,
                    type: this.state.type,
                    startTime: this.state.startTime,
                    endTime: this.state.endTime,
                    resourceNames: [],
                    resourceName: this.state.resourceName
                  }
                )}
              >
                Save
              </Button>
            </SubmitButtonWrapper>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </Row>
        </Form>
      </>
    )
  }
}

function resetFormData(day) {
  return {
    coverRequired: !!day.coverRequired,
    holidayType: day.holidayType,
    overtimeType: day.cover && day.cover.overtimeType || null,
    type: day.type,
    startTime: day.startTime,
    endTime: day.endTime,
    resourceNames: [],
    resourceName: { name: day.cover && day.cover.name || null, shiftId: day.cover && day.cover.shiftId || null }
  }
}

const keysToUpdate = ['coverRequired', 'holidayType', 'type', 'startTime', 'endTime', 'overtimeType', 'resourceName', 'reason']

function compare(o, n) {
  return keysToUpdate.every(key => {
    return JSON.stringify(o[key]) === JSON.stringify(n[key])
  })
}

Edit.propTypes = {
  name: PropTypes.string,
  profileKey: PropTypes.string,
  day: DayType
}

Edit.defaultProps = {
  dayTypes: [
    'Regular',
    'Holiday',
    'Training',
    'Union',
    'Sick',
    'Bereavement',
    'Emergency',
    'Paternity',
    'Maternity',
    'Phase Return',
    'Medical Appointment',
    'Jury Service',
    'Parental Leave',
    'Projects',
    'Community Hours',
    'Other'
  ],
  holidayTypes: [
    'Annual Leave',
    'Banked Hours',
    'TOIL',
    'Union TOIL',
    'Accrued Hours',
    'Accrued Banked Hours',
  ],
  overtimeTypes: [
    'COMMS',
    'PAYG',
    'TOIL'
  ]
}

export default Edit
